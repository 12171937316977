import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import spinner from "../../assets/images/loader.gif";

const Loader = ({showLoader}) =>
	showLoader && (
        <div className="loader">
			<img src={spinner} className="img-responsive" alt="loading" />
		</div>
);

const mapStateToProps = (state) => {
	return {
		showLoader: state.loader.showLoader,
	};
};

Loader.propTypes = {
	showLoader: PropTypes.bool
};

export default connect(mapStateToProps)(Loader);
