import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AdminProfilePage from "../../components/Admin/Profile/AdminProfilePage";
import {
	logout,
	saveProfile,
	deleteProfilePicture,
	setEditAdminProfileStatus,
} from "../../actions/auth";
import { showErrorMessage } from "../../actions/toast";
import { USER_TYPE_BUILDING_MANAGER } from "../../constants";

const AdminProfile = ({
	auth,
	logout,
	showErrorMessage,
	saveProfile,
	deleteProfilePicture,
	setEditAdminProfileStatus,
}) => (
	auth.userType === USER_TYPE_BUILDING_MANAGER ? <AdminProfilePage
		auth={auth}
		logout={logout}
		showErrorMessage={showErrorMessage}
		setEditAdminProfileStatus={setEditAdminProfileStatus}
		saveProfile={saveProfile}
		deleteProfilePicture={deleteProfilePicture}
	/> : <Redirect to="/login" />
);

AdminProfile.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, {
	logout,
	saveProfile,
	deleteProfilePicture,
	showErrorMessage,
	setEditAdminProfileStatus,
})(AdminProfile);
