import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AdminIssuesPage from "../../../components/Admin/Issues/AdminIssuesPage";
import {
	fetchIssues,
	fetchIssueDetails,
	changeIssueStatus,
	setDefaultFilter,
} from "../../../actions/issues";
import { setForceUpdate } from "../../../actions/auth";

const AdminResidents = ({
	authData,
	issuesData,
	fetchIssues,
	fetchIssueDetails,
	changeIssueStatus,
	setForceUpdate,
	setDefaultFilter,
	...rest
}) => {
	return (
		<AdminIssuesPage
			authData={authData}
			issuesData={issuesData}
			fetchIssues={fetchIssues}
			fetchIssueDetails={fetchIssueDetails}
			changeIssueStatus={changeIssueStatus}
			setForceUpdate={setForceUpdate}
			setDefaultFilter={setDefaultFilter}
		/>
	);
};

AdminResidents.propTypes = {
	issuesData: PropTypes.object.isRequired,
	authData: PropTypes.object.isRequired,
	fetchIssues: PropTypes.func.isRequired,
	fetchIssueDetails: PropTypes.func.isRequired,
	changeIssueStatus: PropTypes.func.isRequired,
	setForceUpdate: PropTypes.func.isRequired,
	setDefaultFilter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	authData: state.auth,
	issuesData: state.issues,
});

export default connect(mapStateToProps, {
	fetchIssues,
	fetchIssueDetails,
	changeIssueStatus,
	setForceUpdate,
	setDefaultFilter,
})(AdminResidents);
