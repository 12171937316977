import { MONTHS_MM } from "../constants";

const clipTextWithEllipsis = (stringToClip, limit) => {
    return stringToClip.length > limit
		? `${stringToClip.substring(0, limit)}…`
		: stringToClip;
}

const debounceFunction = (callbackFunction, timeoutInMilliseconds) => {
	let queuedTimeout;
	return ({...params}) => {
		clearTimeout(queuedTimeout);
		queuedTimeout = setTimeout(
			() => callbackFunction({ ...params }),
			timeoutInMilliseconds
		);
	};
}

function isNumeric(string) {
	return !isNaN(parseFloat(string)) && isFinite(string);
}

const getFileNameFromUrl = (fileUrl) => {
	return (fileUrl.split("/") && fileUrl.split("/").pop()) || "";
};

const formatDateDDMMYYYY = (date, seperator) => {
	return `${("0" + date.getDate()).slice(-2)}${seperator ? seperator : "-"}${
		MONTHS_MM[date.getMonth()]
	}${seperator ? seperator : "-"}${date.getFullYear()}`;
};

const formatDateYYYYMMDD = (date) => {
	return `${date.getFullYear()}-${MONTHS_MM[date.getMonth()]}-${(
		"0" + date.getDate()
	).slice(-2)}`;
};

const getDateObjectFromDDMMYYYY = (dateString) => {
	const dateArray = dateString.split('/');
	// const date = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
	// return isNaN(date.getTime()) ? new Date() : date;
	return (dateArray[2] && dateArray[1] && dateArray[0]) ? new Date(dateArray[2], dateArray[1] - 1, dateArray[0]) : "";
};

const formatPhoneNumber = (phoneNumber) => {
	return `${phoneNumber.slice(0, 2)} ${phoneNumber.slice(2, 5)} ${phoneNumber.slice(5)}`;
};

export const utils = {
	clipTextWithEllipsis,
	debounceFunction,
	isNumeric,
	getFileNameFromUrl,
	formatDateDDMMYYYY,
	formatDateYYYYMMDD,
	getDateObjectFromDDMMYYYY,
	formatPhoneNumber,
};