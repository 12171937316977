import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AdminDashboardPage from "../../../components/Admin/Dashboard/AdminDashboardPage";
import { fetchLatestPosts, fetchIssuesGraphData } from "../../../actions/dashboard";
import { fetchResidents } from "../../../actions/residents";
import { setForceUpdate } from "../../../actions/auth";
import { USER_TYPE_ADMIN } from "../../../constants";
import {
	savePost,
	deletePost,
	deletePostImage,
} from "../../../actions/noticeboard";
import { mountModal, unmountModal } from "../../../actions/modal";
import { showErrorMessage } from "../../../actions/toast";
import { setDefaultFilter } from "../../../actions/issues";

const AdminDashboard = ({
	auth,
	dashboardData,
	fetchLatestPosts,
	fetchIssuesGraphData,
	residentsData,
	fetchResidents,
	setForceUpdate,
	savePost,
	deletePost,
	deletePostImage,
	mountModal,
	unmountModal,
	showErrorMessage,
	setDefaultFilter,
	...rest
}) => (
	<AdminDashboardPage
		dashboardData={dashboardData}
		auth={auth}
		isAdmin={auth.userType === USER_TYPE_ADMIN ? true : false}
		residentsData={residentsData}
		fetchLatestPosts={fetchLatestPosts}
		fetchIssuesGraphData={fetchIssuesGraphData}
		fetchResidents={fetchResidents}
		setForceUpdate={setForceUpdate}
		savePost={savePost}
		deletePost={deletePost}
		deletePostImage={deletePostImage}
		mountModal={mountModal}
		unmountModal={unmountModal}
		showErrorMessage={showErrorMessage}
		setDefaultFilter={setDefaultFilter}
	/>
);

AdminDashboard.propTypes = {
	auth: PropTypes.object.isRequired,
	dashboardData: PropTypes.object.isRequired,
	fetchLatestPosts: PropTypes.func.isRequired,
	fetchIssuesGraphData: PropTypes.func.isRequired,
	residentsData: PropTypes.object.isRequired,
	fetchResidents: PropTypes.func.isRequired,
	setForceUpdate: PropTypes.func.isRequired,
	savePost: PropTypes.func.isRequired,
	deletePost: PropTypes.func.isRequired,
	deletePostImage: PropTypes.func.isRequired,
	mountModal: PropTypes.func.isRequired,
	unmountModal: PropTypes.func.isRequired,
	showErrorMessage: PropTypes.func.isRequired,
	setDefaultFilter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	dashboardData: state.dashboard,
	residentsData: state.residents,
});

export default connect(mapStateToProps, {
	fetchLatestPosts,
	fetchIssuesGraphData,
	fetchResidents,
	setForceUpdate,
	savePost,
	deletePost,
	deletePostImage,
	mountModal,
	unmountModal,
	showErrorMessage,
	setDefaultFilter,
})(AdminDashboard);
