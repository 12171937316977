import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AuthLayout from "./../../containers/Layout/Auth/AuthLayout";
import {
	USER_TYPE_ADMIN,
	USER_TYPE_BUILDING_MANAGER,
	USER_TYPE_RESIDENT,
} from "../../constants";
import { setLoggingOut } from "../../actions/auth";

const AuthRoute = ({
	component: Component,
	auth: { token, userType, loginPath, isLoggingOut },
	setLoggingOut,
	...rest
}) => {
	if (token) {
		// console.log("Redirecting from auth route to corresponding dashboard as per user");
		switch (userType) {
			case USER_TYPE_ADMIN:
				return (
					<Redirect to={loginPath ? loginPath : "/admin/dashboard"} />
				);
			case USER_TYPE_BUILDING_MANAGER:
				return (
					<Redirect to={loginPath ? loginPath : "/admin/dashboard"} />
				);
			case USER_TYPE_RESIDENT:
				return (
					<Redirect
						to={loginPath ? loginPath : "/resident/dashboard"}
					/>
				);
			default:
				return <Redirect to="/" />;
		}
	} else {
		// console.log("Not redirecting as !token");
		if (isLoggingOut) {
			setLoggingOut(false);
		}
	}

	return (
		<Route
			{...rest}
			render={(props) => (
				<AuthLayout>
					<Component {...props} />
				</AuthLayout>
			)}
		/>
	);
};

AuthRoute.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { setLoggingOut })(AuthRoute);
