import {
	FETCH_ISSUES_PENDING,
	FETCH_ISSUES_SUCCESSFUL,
	FETCH_ISSUE_DETAILS_PENDING,
	FETCH_ISSUE_DETAILS_SUCCESSFUL,
	CHANGE_ISSUE_STATUS_SUCCESSFUL,
	SAVE_ISSUE_PENDING,
	SET_SAVE_ISSUE_STATUS,
	FETCH_RESIDENT_ISSUES_PENDING,
	FETCH_RESIDENT_ISSUES_SUCCESSFUL,
	SET_ADMIN_ISSUES_DEFAULT_FILTER,
	// SET_ISSUES_BADGE_COUNT,
} from "../actions/types";

const initialState = {
	isFetchIssuesPending: false,
	issues: [],
	meta: {},
	isFetchIssueDetailsPending: false,
	selectedIssueDetails: {},
	hasStatusChanged: false,
	isSaveIssuePending: false,
	saveIssueStatus: false,
	defaultFilter: null,
	// unreadIssuesCount: 0,
};

export default function residentsReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_ISSUES_PENDING:
			return Object.assign({}, state, {
				isFetchIssuesPending: action.payload,
			});
		case FETCH_ISSUES_SUCCESSFUL:
			return Object.assign({}, state, {
				issues: action.payload.result,
				meta: action.payload.meta,
			});
		case FETCH_ISSUE_DETAILS_PENDING:
			return Object.assign({}, state, {
				isFetchIssueDetailsPending: action.payload,
			});
		case FETCH_ISSUE_DETAILS_SUCCESSFUL:
			return Object.assign({}, state, {
				selectedIssueDetails: action.payload,
			});
		case CHANGE_ISSUE_STATUS_SUCCESSFUL:
			return Object.assign({}, state, {
				hasStatusChanged: action.payload,
			});
		case SAVE_ISSUE_PENDING:
			return Object.assign({}, state, {
				isSaveIssuePending: action.payload,
			});
		case SET_SAVE_ISSUE_STATUS:
			return Object.assign({}, state, {
				saveIssueStatus: action.payload,
			});
		case FETCH_RESIDENT_ISSUES_PENDING:
			return Object.assign({}, state, {
				isFetchIssuesPending: action.payload,
			});
		case FETCH_RESIDENT_ISSUES_SUCCESSFUL:
			return Object.assign({}, state, {
				issues: action.payload.result,
				meta: action.payload.meta,
			});
		case SET_ADMIN_ISSUES_DEFAULT_FILTER:
			return Object.assign({}, state, {
				defaultFilter: action.payload
			});
		// case SET_ISSUES_BADGE_COUNT:
		// 	return Object.assign({}, state, {
		// 		unreadIssuesCount: action.payload,
		// 	});
		default:
			return state;
	}
}
