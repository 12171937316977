import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ResidentLayout from "../../containers/Layout/Resident/ResidentLayout";
import { USER_TYPE_RESIDENT } from "../../constants";
import { setLoginPath, logout } from "../../actions/auth";

const ResidentPrivateRoute = ({
	component: Component,
	setLoginPath,
	logout,
	auth: { token, userType, loginPath, isLoggingOut },
	...rest
}) => {
	const isValidUser = userType === USER_TYPE_RESIDENT;

	if (!token && !isValidUser) {
		// console.log(isLoggingOut);
		if (isLoggingOut) {
			// console.log("setting empty");
			setLoginPath("");
		} else {
			// console.log("setting path");
			setLoginPath(rest.location.pathname ? rest.location.pathname : "");
		}
		return <Redirect to="/login" />;
	} else if (token && !isValidUser) {
		logout();
		return <Redirect to="/login" />;
	} else {
		// console.log("is valid");
		if (loginPath) {
			setLoginPath("");
		}
	}

	return (
		<Route
			{...rest}
			render={
				(props) => (
					// (token && userType) === USER_TYPE_RESIDENT ? (
					<div>
						<ResidentLayout {...props}>
							<Component {...props} />
						</ResidentLayout>
					</div>
				)
				// ) : (
				// 	<Redirect to="/login" />
				// )
			}
		/>
	);
};

ResidentPrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setLoginPath, logout })(
	ResidentPrivateRoute
);
