import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Interweave from "interweave";

// import residentGraph from "../../../assets/images/tenant-graph.svg";
import startEndImage from "../../../assets/images/start-end.svg";
import dollarImage from "../../../assets/images/fortnightly-rent.svg";
// import phone from "../../../assets/images/tenant-phone.svg";
import wifi from "../../../assets/images/tenant-wifi.svg";
import avatarBackground from "../../../assets/images/white-avatar-bg.svg";
import avatar from "../../../assets/images/avatar.jpg";
import LeaseDetailsLoader from "../../Ui/LeaseDetailsLoader";
import WifiDetailsLoader from "../../Ui/WifiDetailsLoader";
import RowLoader from "../../Ui/RowLoader";
import PostLoader from "../../Ui/PostLoader";
import PieChartLoader from "../../Ui/PieChartLoader";
import Post from "../../AdminNoticeboard/Post";
import { ResidentIssueRow } from "../Issues/IssueHistoryPage";
import ViewPostPage from "../Noticeboard/ViewPostPage";
import "./residentDashboard.scss";
import { utils } from "../../../utils";
import bankDetailsIcon from "../../../assets/images/bank_details_icon.svg";

const ResidentDashboardPage = ({
	dashboardData: {
		isFetchResidentDashboardLeaseDetailsPending,
		residentDashboardLeaseDetails,
		isFetchResidentDashboardWifiDetailsPending,
		residentDashboardWifiDetails,
		isFetchResidentDashboardBuildingManagerDetailsPending,
		residentDashboardBuildingManagerDetails,
		isFetchResidentDashboardLuxpodBankDetailsPending,
		residentDashboardLuxpodBankDetails,
	},
	noticeboardData: { isResidentFetchPostsPending, residentPosts },
	issuesData: { isFetchIssuesPending, issues },
	authData: { user, total_notifications, shouldUpdateForcefully },
	...rest
}) => {
	const fetchLatestPosts = () => {
		let pageSize = 4;
		let currentPageNumber = 1;
		rest.fetchResidentPosts(pageSize, currentPageNumber);
	};

	const fetchLatestIssues = () => {
		let isFiltering = false;
		let searchFilterValue = "";
		let pageSize = 3;
		let currentPageNumber = 1;
		rest.fetchResidentIssues(
			isFiltering,
			searchFilterValue,
			pageSize,
			currentPageNumber
		);
	};

	useEffect(() => {
		if (shouldUpdateForcefully) {
			fetchLatestIssues();
			rest.setForceUpdate(false);
		}
	}, [shouldUpdateForcefully]);

	useEffect(() => {
		if (total_notifications > 0) {
			fetchLatestIssues();
		}
	}, [total_notifications]);

	useEffect(() => {
		rest.fetchLeaseDetails();
		rest.fetchWifiDetails(user.resident_id);
		rest.fetchBuildingManagerDetails();
		rest.fetchLuxpodBankDetails();
		fetchLatestPosts();
		fetchLatestIssues();
	}, []);

	const handlePostClick = (e, post) => {
		// TODO: Check if e can be removed from everywhere postClick is used.
		e.preventDefault();
		rest.mountModal(
			<ViewPostPage post={post} closePopup={rest.unmountModal} />
		);
	};

	return (
		<Fragment>
			<div className="dashboard tenant-dashboard">
				<div className="container-fluid">
					<div className="title-cta d-flex justify-content-between align-items-center">
						<div className="d-bold dblue font-28">Noticeboard</div>
					</div>

					<div className="recently-added mt-4">
						{isResidentFetchPostsPending ? (
							<PostLoader />
						) : (
							<div className="row">
								{residentPosts &&
									residentPosts.length > 0 &&
									residentPosts.map((post) => (
										<Post
											handleClick={handlePostClick}
											post={post}
											parentCSSClass="col-md-3 col-sm-12"
											titleCSSClass="post_title font-14 bold mb-1"
										/>
									))}
							</div>
						)}
					</div>
				</div>

				<div className=" dashboard-jist tenant-dashboard-jist pt-40">
					<div className="container-fluid">
						<div className="row">
							{isFetchResidentDashboardLeaseDetailsPending ? (
								<PieChartLoader />
							) : (
								// <div className="col-md-7 col-sm-12 account-jist account-jist-disabled">
								// 	<div className="issues__jist dashboard-div">
								// 		<div className="dashboard-title d-flex justify-content-between">
								// 			<div className="font-24 d-bold blue">
								// 				Account
								// 			</div>
								// 			{/* <div className="font-13 light">
								// 			This month{" "}
								// 			<img
								// 				src={toggleArrowIcon}
								// 				alt="toggle_arrow_icon"
								// 			/>
								// 		</div> */}
								// 		</div>
								// 		<div className="dashboard_inner d-flex align-items-center">
								// 			<div className="rent-collected">
								// 				<img
								// 					src={residentGraph}
								// 					alt="graph_image"
								// 				/>
								// 			</div>
								// 			<div className="next-payment">
								// 				<div className="purple bold">
								// 					Next Payment
								// 				</div>
								// 				<div className="purple d-bold font-45">
								// 					$600.00 July 01, 2020
								// 				</div>
								// 				<div className="ctas d-flex">
								// 					<a
								// 						href=""
								// 						className="curved-btn white bold purple-bg"
								// 					>
								// 						Make A Payment
								// 					</a>
								// 					<a
								// 						href=""
								// 						className="curved-btn white bold lblue-bg"
								// 					>
								// 						View All Invoices
								// 					</a>
								// 				</div>
								// 			</div>
								// 		</div>
								// 	</div>
								// </div>
								residentPosts &&
								residentPosts.length > 0 && (
									<div className="col-md-7 col-sm-12 account-jist">
										<div className="issues__jist dashboard-div big-post">
											<div class="font-45 dblue d-bold mb-1">
												{residentPosts[0].title}
											</div>
											<p>{residentPosts[0].time_ago}</p>
											<div class="post-content d-flex mt-20">
												{residentPosts[0].images && residentPosts[0].images[0] && (
													<div class="post-content-image">
														<div class="postcontentimage">
															<img
																src={residentPosts[0].images[0].image}
																alt="post_image"
															/>
														</div>
													</div>
												)}
												<div
													class={`post-content-content ${
														residentPosts[0].images &&
														residentPosts[0].images[0] &&
														residentPosts[0].images[0].image
															? "post-content-content-half-width"
															: ""
													} light`}
												>
													<Interweave
														content={utils.clipTextWithEllipsis(
															residentPosts[0].details,
															320
														)}
													/>
												</div>
											</div>
										</div>
									</div>
								)
							)}
							{isFetchResidentDashboardLeaseDetailsPending ? (
								<LeaseDetailsLoader />
							) : (
								<div className="col-md-5 col-sm-12 lease-jist">
									<div className="lease__jist dashboard-div">
										<div className="dashboard-title d-flex justify-content-between">
											<div className="font-24 d-bold blue">My Lease</div>
										</div>
										<div className="dashboard_inner">
											<div className="start-end d-flex align-items-center flex-wrap">
												<div className="icon">
													<img src={startEndImage} alt="start_end_image" />
												</div>
												<div className="startdate font-14">
													<div className="bold">Start</div>
													<div className="regular">
														{residentDashboardLeaseDetails.startdate}
													</div>
												</div>
												<div className="startdate font-14">
													<div className="bold">End</div>
													<div className="regular">
														{residentDashboardLeaseDetails.end_date}
													</div>
												</div>
												{/* </div>
											<div className="start-end d-flex align-items-center"> */}
												<div className="fortnightly-rent d-flex align-items-center">
													<div className="icon">
														<img src={dollarImage} alt="start_end_image" />
													</div>
													<div className="startdate font-14">
														<div className="bold">Fortnightly rent</div>
														<div className="regular">
															{residentDashboardLeaseDetails.fortnightlyrent}
														</div>
													</div>
												</div>
											</div>
											<div className="ctas mt-3">
												<Link
													to="/resident/profile/details"
													className="curved-btn white bold orange-bg"
												>
													View Lease Details
												</Link>
											</div>
										</div>
									</div>
								</div>
							)}
							<div className="col-md-12 d-flex justify-content-between align-items-center">
								{isFetchResidentDashboardBuildingManagerDetailsPending ? (
									// TODO: Change Wifi Loader to 2 separate loaders for BM and Wifi Details
									<WifiDetailsLoader />
								) : (
									// <div className="bm-details">
									<div className="telephone-div purple-bg white d-flex align-items-center">
										<div className="left-content d-flex align-items-center">
											<div className="avatar__bg position-absolute">
												<img src={avatarBackground} alt="avatarBackground" />
											</div>
											<div className="avatar_image position-relative">
												<img
													src={
														residentDashboardBuildingManagerDetails.profile_picture ||
														avatar
													}
													alt="manager_profile_image"
												/>
											</div>
										</div>
										<div className="right-content d-flex justify-content-between align-items-center">
											<div>
												<div>Building Manager</div>
												<div className="contact-details">
													<div className="white bold font-45">
														{residentDashboardBuildingManagerDetails.name}
													</div>
												</div>
											</div>
											<div className="flex-column justify-content-between">
												<a
													href={`mailto:${residentDashboardBuildingManagerDetails.email}`}
													className="white text-underline"
												>
													{residentDashboardBuildingManagerDetails.email}
												</a>
												<div>
													<span className="bold">Mobile. </span>
													<a
														href={`tel:${residentDashboardBuildingManagerDetails.phone}`}
														className="white"
													>
														{residentDashboardBuildingManagerDetails.phone}
													</a>
												</div>
												<div>
													<span className="bold">Tel. </span>
													<a
														href={`tel:${residentDashboardBuildingManagerDetails.telephone}`}
														className="white"
													>
														{residentDashboardBuildingManagerDetails.telephone}
													</a>
												</div>
											</div>
										</div>
									</div>
									// </div>
								)}
								{isFetchResidentDashboardWifiDetailsPending ? (
									<WifiDetailsLoader />
								) : (
									// <div className="wifi-details">
									<div className="wifi-div pink-bg white d-flex align-items-center justify-content-between">
										<div>
											<img src={wifi} alt="wifi_icon" />
										</div>
										<div className="middle-content">
											<div className="bold">Wifi Name</div>
											<div className="d-bold font-40">
												{residentDashboardWifiDetails.name}
											</div>
										</div>
										<div className="middle-content">
											<div className="bold">Wifi Password</div>
											<div className="d-bold font-40">
												{residentDashboardWifiDetails.password}
											</div>
										</div>
									</div>
									// </div>
								)}

								{isFetchResidentDashboardWifiDetailsPending ? (
									<WifiDetailsLoader />
								) : (
									// <div className="bank-details">
									<div className="bank-details-div green-bg white d-flex align-items-center">
										<div>
											<img src={bankDetailsIcon} alt="dollar_icon" />
										</div>
										<div className="col-md-9 flex-column justify-content-between">
											<div>
												<div className="middle-content">
													<div className="bold">Account Name</div>
													<div className="d-bold font-40">
														{console.log(residentDashboardLuxpodBankDetails)}
														{residentDashboardLuxpodBankDetails.account_name}
													</div>
												</div>
											</div>
											<div className="d-flex align-items-center justify-content-between">
												<div className="middle-content">
													<div className="bold">BSB</div>
													<div className="d-bold font-40">
														{residentDashboardLuxpodBankDetails.bsb}
													</div>
												</div>
												<div className="middle-content">
													<div className="bold">Account Number</div>
													<div className="d-bold font-40">
														{residentDashboardLuxpodBankDetails.account_number}
													</div>
												</div>
											</div>
										</div>
									</div>
									// </div>
								)}
							</div>

							<div className="col-md-12 account-jist pt-40">
								{isFetchIssuesPending ? (
									<RowLoader />
								) : (
									<div className="dashboard-div">
										<div className="dashboard-title d-flex justify-content-between">
											<div className="font-24 d-bold blue">
												<Link to="/resident/issues/history">Issues Lodged</Link>
											</div>
											{/* <div className="font-13 light">
											This month{" "}
											<img
												src={toggleArrowIcon}
												alt="toggle_arrow_icon"
											/>
										</div> */}
										</div>

										{issues &&
											issues.length > 0 &&
											issues.map((issue) => <ResidentIssueRow issue={issue} />)}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ResidentDashboardPage;
