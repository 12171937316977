import {
	FETCH_CURRENT_MONTH_POSTS_PENDING,
	FETCH_CURRENT_MONTH_POSTS_SUCCESSFUL,
	FETCH_PREVIOUS_POSTS_PENDING,
	FETCH_PREVIOUS_POSTS_SUCCESSFUL,
	SAVE_POST_PENDING,
	// SAVE_POST_SUCCESSFUL,
	FETCH_RESIDENT_POSTS_PENDING,
	FETCH_RESIDENT_POSTS_SUCCESSFULL,
} from "./types";
import { DEFAULT_ERROR_MESSAGE } from "../constants";
import { fetchLatestPosts } from "./dashboard";
import apiService from "../services/apiService";
import {
	showSuccessMessage,
	showErrorMessage,
	// showDefaultErrorMessage,
} from "./toast";

export const fetchAllPosts = () => (dispatch) => {
	dispatch(fetchCurrentMonthPosts());
	dispatch(fetchPreviousPosts());
};

const fetchCurrentMonthPostsPending = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_CURRENT_MONTH_POSTS_PENDING,
		payload,
	});
};

const fetchCurrentMonthPostsSuccessful = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_CURRENT_MONTH_POSTS_SUCCESSFUL,
		payload,
	});
};

export const fetchCurrentMonthPosts = () => (dispatch) => {
    dispatch(fetchCurrentMonthPostsPending(true));
	apiService.fetchCurrentMonthPosts().then(
		(res) => {
			dispatch(fetchCurrentMonthPostsPending(false));
			dispatch(fetchCurrentMonthPostsSuccessful(res.result));
			// dispatch(showSuccessMessage(res.message));
		},
		(err) => {
			dispatch(fetchCurrentMonthPostsPending(false));
			console.error(err);
			dispatch(
				showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
			);
		}
	);
};

const fetchPreviousPostsPending = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_PREVIOUS_POSTS_PENDING,
		payload,
	});
};

const fetchPreviousPostsSuccessful = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_PREVIOUS_POSTS_SUCCESSFUL,
		payload,
	});
};

export const fetchPreviousPosts = () => (dispatch) => {
	dispatch(fetchPreviousPostsPending(true));
	apiService
		.fetchPreviousPosts()
		.then((res) => {
			dispatch(fetchPreviousPostsPending(false));
			dispatch(fetchPreviousPostsSuccessful(res.result));
			// dispatch(showSuccessMessage(res.message));
		})
		.catch((err) => {
			dispatch(fetchPreviousPostsPending(false));
			// console.error(err);
			dispatch(
				showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
			);
		});
};

const savePostPending = (payload) => (dispatch) => {
	dispatch({
		type: SAVE_POST_PENDING,
		payload,
	});
};

// const savePostSuccessful = (payload) => (dispatch) => {
// 	dispatch({
// 		type: SAVE_POST_SUCCESSFUL,
// 		payload,
// 	});
// };

export const savePost = (path, post, fromDashboard) => (dispatch) => {
	dispatch(savePostPending(true));
	apiService.savePost(path, post).then(
		(res) => {
			dispatch(savePostPending(false));
			// dispatch(savePostSuccessful(true));
			// dispatch(showSuccessMessage(res.message));
			if (fromDashboard) {
				dispatch(fetchLatestPosts());
			} else {
				dispatch(fetchAllPosts());
			}
		},
		(err) => {
			dispatch(savePostPending(false));
			console.error(err);
			dispatch(
				showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
			);
		}
	);
};

export const deletePost = (id, fromDashboard) => dispatch => {
	apiService.deletePost(id).then(
		(res) => {
			// dispatch(savePostSuccessful(true));
			// dispatch(showSuccessMessage(res.message));
			if (fromDashboard) {
				dispatch(fetchLatestPosts());
			} else {
				dispatch(fetchAllPosts());
			}
		},
		(err) => {
			console.error(err);
			dispatch(
				showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
			);
		}
	);
}

export const deletePostImage = (imageId) => (dispatch) => {
	apiService.deleteImage(imageId).then(
		(res) => {
			// dispatch(savePostSuccessful(true));
			// dispatch(showSuccessMessage(res.message));
			dispatch(fetchAllPosts());
		},
		(err) => {
			console.error(err);
			dispatch(
				showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
			);
		}
	);
};

const fetchResidentPostsPending = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENT_POSTS_PENDING,
		payload,
	});
};

const fetchResidentPostsSuccessful = (result, meta) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENT_POSTS_SUCCESSFULL,
		payload: {
			result,
			meta,
		},
	});
};

export const fetchResidentPosts = (pageSize, pageNo) => (dispatch) => {
	dispatch(fetchResidentPostsPending(true));
	apiService.fetchResidentPosts(pageSize, pageNo).then(
		(res) => {
			dispatch(fetchResidentPostsPending(false));
			dispatch(fetchResidentPostsSuccessful(res.result, res.meta));
		},
		(err) => {
			dispatch(fetchResidentPostsPending(false));
			console.error(err);
			dispatch(
				showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
			);
		}
	);
};