import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import leftBlueArrow from "../../../assets/images/left-blue-arrow.svg";
import Pagination from "../../Ui/Pagination";
import RowLoader from "../../Ui/RowLoader";
import { RESIDENT_ISSUES_DEFAULT_PAGE_SIZE, ADMIN_ISSUES_FILTER_REQUEST_STATUSES } from "../../../constants";
import { utils } from "../../../utils";

const IssueHistoryPage = ({
	issuesData: {
		issues,
		isFetchIssuesPending,
		meta,
		// unreadIssuesCount
	},
	authData: { total_notifications, shouldUpdateForcefully },
	...rest
}) => {
	const [currentPageNumber, setCurrentPageNumber] = useState(
		// Add 1 to meta.currentpage once API is integrated as API returns currentpage starting with 0
		meta && meta.currentpage
			? meta.currentpage == 0
				? parseInt(meta.currentpage) + 1
				: parseInt(meta.currentpage)
			: 1
	);

	const [searchFilterValue, setSearchFilterValue] = useState("");
	const [isFiltering, setIsFiltering] = useState(false);

	const fetchResidentIssues = () => {
		rest.fetchResidentIssues(
			isFiltering,
			searchFilterValue,
			RESIDENT_ISSUES_DEFAULT_PAGE_SIZE,
			currentPageNumber
		);
	};

	useEffect(() => {
		fetchResidentIssues();
	}, [currentPageNumber]);

	const refreshList = () => {
		if (searchFilterValue) {
			setSearchFilterValue("");
		}
		if (currentPageNumber > 1) {
			setCurrentPageNumber(1);
		}
		if (!searchFilterValue && currentPageNumber == 1) {
			fetchResidentIssues();
		}
	};

	useEffect(() => {
		if (total_notifications > 0) {
			refreshList();
		}
	}, [total_notifications]);

	useEffect(() => {
		if (shouldUpdateForcefully) {
			refreshList();
			rest.setForceUpdate(false);
		}
	}, [shouldUpdateForcefully]);

	useEffect(() => {
		if (searchFilterValue) {
			if (isFiltering) {
				fetchResidentIssues();
			} else {
				setIsFiltering(true);
			}
		} else {
			setIsFiltering(false);
		}
	}, [searchFilterValue]);

	useEffect(() => {
		fetchResidentIssues();
	}, [isFiltering]);

	const handleSearchChange = (e) => {
		setSearchFilterValue(e.target.value);
	};

	return (
		<Fragment>
			<div className="container-fluid">
				<div className="title-bar d-flex justify-content-between align-items-center">
					<div className="font-45 dblue d-bold">
						<Link to="/resident/issues">
							<img src={leftBlueArrow} alt="left_blue_arrow" />
						</Link>
						Issues Lodged
					</div>

					<div className="issues-search">
						<div className="search-div resident-issues search-residents d-flex align-items-center">
							<form>
								<div className="search-field position-relative">
									<input
										placeholder="Search for an issue"
										type="search"
										value={searchFilterValue}
										onChange={handleSearchChange}
									/>
									<input type="submit" />
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			{isFetchIssuesPending ? (
				<RowLoader />
			) : (
				<Fragment>
					<div className="resident_issue_list pt-40">
						<div className="container-fluid">
							<div className="issue__list">
								{issues && issues.length > 0 ?
									issues.map((issue) => (
										<ResidentIssueRow issue={issue} />
									)) : <center>No issues found.</center>}
							</div>
						</div>
					</div>
					{meta && meta.pagecount > 1 && (
						<Pagination
							totalNumberOfPages={meta.pagecount}
							currentPageNumber={currentPageNumber}
							setCurrentPageNumber={setCurrentPageNumber}
						/>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default IssueHistoryPage;


export const ResidentIssueRow = ({ issue }) => (
			<div
				className={`issues-row d-flex justify-content-between align-items-center${
					issue && issue.is_read ? "" : " unread-bg"
				}`}
			>
				<div className="issue_name">
					<div className="font-20">
						<Link
							className="text-underline blue"
							to={`/resident/issues/${issue.id}`}
						>
							{utils.clipTextWithEllipsis(issue.title, 25)}
						</Link>
					</div>
				</div>
				<div className="case-number">
					<div className="font-18">Issue #{issue.id}</div>
				</div>
				<div className="issue-date">
					<div className="font-18">{issue.requestdate}</div>
				</div>
				<div className="issue-details">
					<div className="font-18">
						{utils.clipTextWithEllipsis(issue.description, 120)}
					</div>
				</div>
				<div className="issue-status">
					<div
						className={`font-18 status-issue ${issue.status} white btn-group justify-content-center`}
					>
						{ADMIN_ISSUES_FILTER_REQUEST_STATUSES.find(
							(status) => status.value == issue.status
						) &&
							ADMIN_ISSUES_FILTER_REQUEST_STATUSES.find(
								(status) => status.value == issue.status
							).label}
					</div>
				</div>
			</div>
		);