import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import popupClose from "../../assets/images/popup-close.svg";

const Modal = (props) => {
	useEffect(() => {
		if (props.shouldRenderModal) {
			document.body.classList.add("overflowHidden");

			return () => {
				document.body.classList.remove("overflowHidden");
			};
		}
	}, [props.shouldRenderModal]);

	return (
		<div>
			{props.shouldRenderModal ? (
				<Fragment>
					<div
						id="prevposts"
						className="modal fade show overflowScroll"
						role="dialog"
					>
						<div className="modal-dialog">
							<div className="modal-content">
								{props.children}
							</div>
						</div>
					</div>
					<div className="modal-backdrop fade show"></div>
				</Fragment>
			) : null}
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		shouldRenderModal: state.modal.shouldRenderModal,
		children: state.modal.children,
	};
};

Modal.propTypes = {
	shouldRenderModal: PropTypes.bool.isRequired,
	children: PropTypes.element.isRequired,
};

export default connect(mapStateToProps)(Modal);


const SampleModal = () => (
	<Fragment>
		<div className="modal-header">
			<h2 className="modal-title">
				Popup Title
			</h2>
		</div>
		<div className="modal-body">
			<p>Popup body</p>
		</div>
	</Fragment>
);





// <div>
// 				<div
// 					className="modal show"
// 					style={{
// 						display: "block",
// 					}}
// 					role="dialog"
// 				>
// 					<div
// 						className="display modal show"
// 						role="dialog"
// 						aria-hidden="true"
// 					>
// 						<div className="popup modal-dialog" role="document">
// 							<div className="modal-content">
// 								{props.children || <SampleModal />}
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 				<div className="modal-backdrop fade show"></div>
// 			</div>