import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ResidentProfilePage from "../../../components/Resident/Profile/ResidentProfilePage";
import {
	logout,
	fetchResidentProfileDetails,
	setSaveResidentProfileDetailsStatus,
	saveResidentProfileDetails,
	deleteProfilePicture,
} from "../../../actions/auth";
import { fetchCourseTypes } from "../../../actions/residents";

const ResidentProfile = ({
	auth,
	courseTypes,
	logout,
	fetchResidentProfileDetails,
	fetchCourseTypes,
	setSaveResidentProfileDetailsStatus,
	saveResidentProfileDetails,
	deleteProfilePicture,
}) => (
	<ResidentProfilePage
		auth={auth}
		courseTypes={courseTypes}
		logout={logout}
		fetchResidentProfileDetails={fetchResidentProfileDetails}
		fetchCourseTypes={fetchCourseTypes}
		setSaveResidentProfileDetailsStatus={
			setSaveResidentProfileDetailsStatus
		}
		saveResidentProfileDetails={saveResidentProfileDetails}
		deleteProfilePicture={deleteProfilePicture}
	/>
);

ResidentProfile.propTypes = {
	auth: PropTypes.object,
	logout: PropTypes.func,
	fetchResidentProfileDetails: PropTypes.func,
	setSaveResidentProfileDetailsStatus: PropTypes.func,
	saveResidentProfileDetails: PropTypes.func,
	fetchCourseTypes: PropTypes.func,
	deleteProfilePicture: PropTypes.func,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	courseTypes: state.residents.courseTypes
});

export default connect(mapStateToProps, {
	logout,
	fetchResidentProfileDetails,
	setSaveResidentProfileDetailsStatus,
	saveResidentProfileDetails,
	deleteProfilePicture,
	fetchCourseTypes,
})(ResidentProfile);
