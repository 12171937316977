import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './../../styles/app.scss';
import Routes from '../../components/Routes/Routes';
// import { loadUser } from '../../actions/auth';


//Redux
import { Provider } from 'react-redux';
import store from '../../store';

import setAuthToken from '../../utils/setAuthToken';


if(localStorage.token){
  setAuthToken(localStorage.token)
}

const  App = () => {
  return(    
    <Provider store={store}>
      <Fragment>
        <Router>
          <Switch>
            <Route component={Routes} />
          </Switch>
        </Router>
      </Fragment>
    </Provider>
    );
}

export default App;