import React from "react";
import ContentLoader from "react-content-loader";

const PieChartLoader = (props) => (
	<div className="row">
		<ContentLoader
			speed={2}
			width={700}
			height={200}
			viewBox="0 0 400 200"
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
			{...props}
		>
			<circle cx="116" cy="118" r="79" />
			<rect x="20" y="5" rx="5" ry="5" width="103" height="12" />
		</ContentLoader>
	</div>
);

export default PieChartLoader;