import {
	FETCH_CURRENT_MONTH_POSTS_PENDING,
	FETCH_CURRENT_MONTH_POSTS_SUCCESSFUL,
	FETCH_PREVIOUS_POSTS_PENDING,
	FETCH_PREVIOUS_POSTS_SUCCESSFUL,
	SAVE_POST_PENDING,
	// SAVE_POST_SUCCESSFUL,
	FETCH_RESIDENT_POSTS_PENDING,
	FETCH_RESIDENT_POSTS_SUCCESSFULL,
} from "../actions/types";

const initialState = {
	isFetchCurrentMonthPostsPending: false,
	currentMonthPosts: [],
	isFetchPreviousPostsPending: false,
	previousPosts: [],
	isSavePostPending: false,
	// isSavePostSuccessful: true,
	isResidentFetchPostsPending: false,
	residentPosts: [],
	meta: {},
};

export default function noticeboardReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_CURRENT_MONTH_POSTS_PENDING:
			return Object.assign({}, state, {
				isFetchCurrentMonthPostsPending: action.payload,
			});
		case FETCH_CURRENT_MONTH_POSTS_SUCCESSFUL:
			return Object.assign({}, state, {
				currentMonthPosts: action.payload,
			});
		case FETCH_PREVIOUS_POSTS_PENDING:
			return Object.assign({}, state, {
				isFetchPreviousPostsPending: action.payload,
			});
		case FETCH_PREVIOUS_POSTS_SUCCESSFUL:
			return Object.assign({}, state, {
				previousPosts: action.payload,
			});
		case SAVE_POST_PENDING:
			return Object.assign({}, state, {
				isSavePostPending: action.payload,
			});
		// case SAVE_POST_SUCCESSFUL:
		// 	return Object.assign({}, state, {
		// 		isSavePostSuccessful: action.payload,
		// 	});
		case FETCH_RESIDENT_POSTS_PENDING:
			return Object.assign({}, state, {
				isResidentFetchPostsPending: action.payload,
			});
		case FETCH_RESIDENT_POSTS_SUCCESSFULL:
			return Object.assign({}, state, {
				residentPosts: action.payload.result,
				meta: action.payload.meta,
			});
		default:
			return state;
	}
}
