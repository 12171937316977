import {
	FETCH_LATEST_POSTS_PENDING,
	FETCH_LATEST_POSTS_SUCCESSFUL,
	FETCH_ISSUES_GRAPH_DATA_PENDING,
	FETCH_ISSUES_GRAPH_DATA_SUCCESSFUL,
	FETCH_RESIDENT_DASHBOARD_LEASE_DETAILS_PENDING,
	FETCH_RESIDENT_DASHBOARD_LEASE_DETAILS_SUCCESSFUL,
	FETCH_RESIDENT_DASHBOARD_WIFI_DETAILS_PENDING,
	FETCH_RESIDENT_DASHBOARD_WIFI_DETAILS_SUCCESSFUL,
	FETCH_RESIDENT_DASHBOARD_BUILDING_MANAGER_DETAILS_PENDING,
	FETCH_RESIDENT_DASHBOARD_BUILDING_MANAGER_DETAILS_SUCCESSFUL,
	FETCH_RESIDENT_DASHBOARD_LUXPOD_BANK_DETAILS_PENDING,
	FETCH_RESIDENT_DASHBOARD_LUXPOD_BANK_DETAILS_SUCCESSFUL,
} from "./types";
import { DEFAULT_ERROR_MESSAGE } from "../constants";
import apiService from "../services/apiService";
import {
	// showSuccessMessage,
	showErrorMessage,
} from "./toast";

const fetchLatestPostsPending = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_LATEST_POSTS_PENDING,
		payload,
	});
};

const fetchLatestPostsSuccessful = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_LATEST_POSTS_SUCCESSFUL,
		payload,
	});
};

export const fetchLatestPosts = () => (dispatch) => {
	dispatch(fetchLatestPostsPending(true));
	apiService.fetchLatestPosts().then(
		(res) => {
			dispatch(fetchLatestPostsPending(false));
			dispatch(fetchLatestPostsSuccessful(res.result));
		},
		(err) => {
			dispatch(fetchLatestPostsPending(false));
			console.error(err);
			dispatch(
				showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
			);
		}
	);
};

const fetchIssuesGraphDataPending = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_ISSUES_GRAPH_DATA_PENDING,
		payload,
	});
};

const fetchIssuesGraphDataSuccessful = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_ISSUES_GRAPH_DATA_SUCCESSFUL,
		payload,
	});
};

export const fetchIssuesGraphData = () => (dispatch) => {
    dispatch(fetchIssuesGraphDataPending(true));
    apiService.fetchIssuesGraphData().then(
        (res) => {
            dispatch(fetchIssuesGraphDataPending(false));
            dispatch(fetchIssuesGraphDataSuccessful(res.result));
        },
        (err) => {
            dispatch(fetchIssuesGraphDataPending(false));
            console.error(err);
            dispatch(
                showErrorMessage(
                    err.message || DEFAULT_ERROR_MESSAGE
                )
            );
        }
    );
};


const fetchLeaseDetailsPending = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENT_DASHBOARD_LEASE_DETAILS_PENDING,
		payload,
	});
};

const fetchLeaseDetailsSuccessful = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENT_DASHBOARD_LEASE_DETAILS_SUCCESSFUL,
		payload,
	});
};

export const fetchLeaseDetails = () => dispatch => {
	dispatch(fetchLeaseDetailsPending(true));
	apiService.fetchLeaseDetails().then(
		(res) => {
			dispatch(fetchLeaseDetailsPending(false));
			dispatch(fetchLeaseDetailsSuccessful(res.result));
		},
		(err) => {
			dispatch(fetchLeaseDetailsPending(false));
			console.error(err);
			dispatch(
				showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
			);
		}
	);
};

const fetchWifiDetailsPending = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENT_DASHBOARD_WIFI_DETAILS_PENDING,
		payload,
	});
};

const fetchWifiDetailsSuccessful = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENT_DASHBOARD_WIFI_DETAILS_SUCCESSFUL,
		payload,
	});
};

export const fetchWifiDetails = (residentId) => dispatch => {
	dispatch(fetchWifiDetailsPending(true));
	apiService.fetchWifiDetails(residentId).then(
		(res) => {
			dispatch(fetchWifiDetailsPending(false));
			dispatch(fetchWifiDetailsSuccessful(res.result));
		},
		(err) => {
			dispatch(fetchWifiDetailsPending(false));
			console.error(err);
			dispatch(
				showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
			);
		}
	);
};

const fetchBuildingManagerDetailsPending = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENT_DASHBOARD_BUILDING_MANAGER_DETAILS_PENDING,
		payload,
	});
};

const fetchBuildingManagerDetailsSuccessful = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENT_DASHBOARD_BUILDING_MANAGER_DETAILS_SUCCESSFUL,
		payload,
	});
};

export const fetchBuildingManagerDetails = () => dispatch => {
	dispatch(fetchBuildingManagerDetailsPending(true));
	apiService.fetchBuildingManagerDetails().then(
		(res) => {
			dispatch(fetchBuildingManagerDetailsPending(false));
			dispatch(fetchBuildingManagerDetailsSuccessful(res.result));
		},
		(err) => {
			dispatch(fetchBuildingManagerDetailsPending(false));
			console.error(err);
			dispatch(
				showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
			);
		}
	);
};

const fetchLuxpodBankDetailsPending = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENT_DASHBOARD_LUXPOD_BANK_DETAILS_PENDING,
		payload,
	});
};

const fetchLuxpodBankDetailsSuccessful = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENT_DASHBOARD_LUXPOD_BANK_DETAILS_SUCCESSFUL,
		payload,
	});
};

export const fetchLuxpodBankDetails = () => dispatch => {
	dispatch(fetchLuxpodBankDetailsPending(true));
	apiService.fetchLuxpodBankDetails().then(
		(res) => {
			dispatch(fetchLuxpodBankDetailsPending(false));
			dispatch(fetchLuxpodBankDetailsSuccessful(res.result));
		},
		(err) => {
			dispatch(fetchLuxpodBankDetailsPending(false));
			console.error(err);
			dispatch(
				showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
			);
		}
	);
};
