import apiService from "../services/apiService";
import { showErrorMessage } from "./toast";
import { DEFAULT_ERROR_MESSAGE } from "../constants";
import {
	SET_SAVE_WIFI_DETAILS_STATUS,
	SET_FETCH_ADMIN_WIFI_DETAILS_PENDING,
	SET_FETCH_ADMIN_WIFI_DETAILS_SUCCESSFUL,
} from "../actions/types";

export const setEditWifiDetailsStatus = (payload) => (dispatch) => {
	dispatch({
		type: SET_SAVE_WIFI_DETAILS_STATUS,
		payload,
	});
};

export const editWifiDetails = (password) => (dispatch) => {
	apiService.editWifiDetails(password).then(
		(res) => {
			dispatch(setEditWifiDetailsStatus(true));
		},
		(err) => {
			dispatch(showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE));
		}
	);
};


export const setFetchAdminWifiDetailsPending = (payload) => (
	dispatch
) => {
	dispatch({
		type: SET_FETCH_ADMIN_WIFI_DETAILS_PENDING,
		payload,
	});
};

export const setFetchAdminWifiDetailsSuccessful = (payload) => (
	dispatch
) => {
	dispatch({
		type: SET_FETCH_ADMIN_WIFI_DETAILS_SUCCESSFUL,
		payload,
	});
};

export const fetchAdminWifiDetails = () => (dispatch) => {
	dispatch(setFetchAdminWifiDetailsPending(true));
	apiService.fetchAdminWifiDetails().then(
		(res) => {
			dispatch(setFetchAdminWifiDetailsPending(false));
			dispatch(setFetchAdminWifiDetailsSuccessful(res.result));
		},
		(err) => {
			dispatch(setFetchAdminWifiDetailsPending(false));
			dispatch(showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE));
		}
	);
};
