import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { USER_TYPE_BUILDING_MANAGER, USER_TYPE_ADMIN } from "../../constants";

const ProfileMenu = ({ setIsMenuOpen, userType, ...rest }) => {
	const handleMenuClick = () => {
		setIsMenuOpen(false);
	};

    const logout = (e) => {
        e.preventDefault();
		setIsMenuOpen(false);
		rest.logout();
	};
	return (
		<div
			className="profile-menu dropdown-menu dropdown-menu-right show"
			aria-labelledby="dropdownMenuButton"
		>
			{userType === USER_TYPE_ADMIN && (
				<Link
					to="/admin/edit-password"
					className="dropdown-item"
					onClick={handleMenuClick}
				>
					Edit Password
				</Link>
			)}
			{userType === USER_TYPE_BUILDING_MANAGER && (
				<Link
					to="/admin/profile"
					className="dropdown-item"
					onClick={handleMenuClick}
				>
					My Profile
				</Link>
			)}
			<Link className="dropdown-item" onClick={logout}>
				Logout
			</Link>
		</div>
	);
};

ProfileMenu.propTypes = {
	logout: PropTypes.func.isRequired,
};

export default ProfileMenu;
