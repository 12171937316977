import React from "react";
import { Route } from "react-router-dom";
import FrontLayout from "./../../containers/Layout/Front/FrontLayout";

const FrontRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <FrontLayout {...props}>
        <Component {...props} />
      </FrontLayout>
    )}
  />
);

export default FrontRoute;
