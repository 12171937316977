import React, { Fragment } from "react";

import lastPaymentImage from "../../../assets/images/last-payment.svg";
import overdueImage from "../../../assets/images/overdue.svg";
import downloadPdfImage from "../../../assets/images/download-pdf.svg";
import masterCardImage from "../../../assets/images/master-card.svg";
import "./residentRent.scss";

const ResidentRentPage = () => {
	return (
		<Fragment>
			<div className="dashboard tenant-dashboard resident-rent">
				<div className="container-fluid">
					<div className="title-bar justify-content-between align-items-center">
						<div className="d-bold dblue font-28">Rent</div>
					</div>
				</div>

				<div className=" dashboard-jist tenant-rent-jist pt-40">
					<div className="container-fluid">
						<div className="row">
							<div className="col-md-12 account-jist pb-40">
								<div className="dashboard-div">
									<div className="dashboard-title justify-content-between">
										<div className="font-24 d-bold blue">
											Your balance
										</div>
									</div>
									<div className="rent-progress-next d-flex justify-content-between align-items-center">
										<div className="rent-progress">
											<div className="font-13 bold">
												Your next payment is due on.
												July 01, 2020 for $500.00
											</div>
											<div className="rent_progress">
												<span
													style={{ width: "65%" }}
													className="progress_width"
												>
													<span className="dollar"></span>
												</span>
											</div>
											<div className="light font-13 pt-40 mt-3">
												You’ve pad $1000.00/$2000.00 of
												your total rent
											</div>
											<div className="d-flex payments mt-3">
												<div className="payment_div d-flex align-items-center">
													<div className="payment-icon">
														<img
															src={
																lastPaymentImage
															}
															alt="lastpayment_image"
														/>
													</div>
													<div className="font-13 light">
														Your last payment was on
														June 1,2020
													</div>
												</div>
												<div className="payment_div d-flex align-items-center">
													<div className="payment-icon">
														<img
															src={overdueImage}
															alt="overdue_image"
														/>
													</div>
													<div className="font-13 light">
														You have an overdue
														payment from September
														2020
													</div>
												</div>
											</div>
										</div>
										<div className="next-rent-payment">
											<div className="purple font-18 bold">
												Next Payment
											</div>
											<div className="purple font-45 d-bold">
												$600.00
											</div>
											<div className="mt-4">
												<a
													href=""
													data-toggle="modal"
													data-target="#payment"
													data-toggle="modal"
													className="red-bg white bold curved-btn"
												>
													<span className="d-bold">
														+
													</span>
													Make a payment
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-7 col-sm-12 account-jist">
								<div className="previous-trans dashboard-div">
									<div className="dashboard-title d-flex justify-content-between">
										<div className="font-24 d-bold blue">
											Previous transactions
										</div>
									</div>
									<div className="prev_transactions">
										<div className="prev__transactions d-flex justify-content-between align-items-center">
											<div className="trans-div">
												#84763545
											</div>
											<div className="trans-div">
												Mar 01, 2019
											</div>
											<div className="trans-div">
												$200.00
											</div>
											<div className="trans-div">
												<a
													className="black-color"
													href=""
												>
													Download PDF{" "}
													<img
														src={downloadPdfImage}
														alt="download_pdf_image"
													/>
												</a>
											</div>
										</div>
										<div className="prev__transactions d-flex justify-content-between align-items-center">
											<div className="trans-div">
												#84763545
											</div>
											<div className="trans-div">
												Mar 01, 2019
											</div>
											<div className="trans-div">
												$200.00
											</div>
											<div className="trans-div">
												<a
													className="black-color"
													href=""
												>
													Download PDF{" "}
													<img
														src={downloadPdfImage}
														alt="download_pdf_image"
													/>
												</a>
											</div>
										</div>
										<div className="prev__transactions d-flex justify-content-between align-items-center">
											<div className="trans-div">
												#84763545
											</div>
											<div className="trans-div">
												Mar 01, 2019
											</div>
											<div className="trans-div">
												$200.00
											</div>
											<div className="trans-div">
												<a
													className="black-color"
													href=""
												>
													Download PDF{" "}
													<img
														src={downloadPdfImage}
														alt="download_pdf_image"
													/>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-5 col-sm-12 lease-jist">
								<div className="how-pay dashboard-div">
									<div className="dashboard-title d-flex justify-content-between">
										<div className="font-24 d-bold blue">
											How you pay
										</div>
									</div>
									<div className="howtopay">
										<div className="how-to-pay d-flex align-items-center mb-5">
											<div className="card-image">
												<img src={masterCardImage} alt="mastercard_image" />
											</div>
											<div className="card-details">
												<div className="card-nos d-flex align-items-center">
													<span className="nos"></span>
													<span className="nos"></span>
													<span className="nos"></span>
													<span className="nos"></span>
													<span className="nos"></span>
													<span className="nos"></span>
													<span className="nos"></span>
													<span className="nos"></span>
													<span className="nos"></span>
													<span className="nos"></span>
													<span className="nos"></span>
													<span className="nos"></span>
													<span className="nos">
														1
													</span>
													<span className="nos">
														2
													</span>
													<span className="nos">
														3
													</span>
													<span className="nos">
														4
													</span>
												</div>
												<div className="mt-3">
													Expires 12/22
												</div>
											</div>
										</div>
										<a
											href=""
											className="font-18 curved-btn bold white purple-bg"
										>
											Manage payment method
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ResidentRentPage;
