import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import LoginPage from "../../components/Auth/LoginPage";
import { login } from "../../actions/auth";
// import { showSuccessMessage } from "../../actions/toast";

const Login = ({ login, ...rest }) => (
	<LoginPage
		login={login}
		// showSuccessMessage={rest.showSuccessMessage}
	/>
);

Login.propTypes = {
	login: PropTypes.func.isRequired,
	// isAuth: PropTypes.bool,
};

// const mapStateToProps = (state) => ({
// 	isAuth: state.auth.isAuth,
// });

// export default connect(mapStateToProps, { login, showSuccessMessage })(Login);
export default connect(null, { login })(Login);
