import React from "react";
import { Switch, Route } from "react-router-dom";
import AdminIssuesListPage from "./AdminIssuesListPage";
import AdminIssueDetailPage from "./AdminIssueDetailPage";

import "./adminIssues.scss";

const AdminIssuesPage = ({ issuesData, authData, ...rest }) => {
	return (
		<Switch>
			<Route
				exact
				path="/admin/issues/:id"
				render={(props) => (
					<AdminIssueDetailPage
						fetchIssueDetails={rest.fetchIssueDetails}
						changeIssueStatus={rest.changeIssueStatus}
						issuesData={issuesData}
						{...props}
					/>
				)}
			/>
			<Route
				path="/"
				render={(props) => (
					<AdminIssuesListPage
						issuesData={issuesData}
						authData={authData}
						fetchIssues={rest.fetchIssues}
						changeIssueStatus={rest.changeIssueStatus}
						setForceUpdate={rest.setForceUpdate}
						setDefaultFilter={rest.setDefaultFilter}
						{...props}
					/>
				)}
			/>
			<Route render={() => <h2>Page not found :(</h2>} />
		</Switch>
	);
};

export default AdminIssuesPage;