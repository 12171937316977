import { MOUNT_MODAL, UNMOUNT_MODAL } from "../actions/types";

const initialState = {
	shouldRenderModal: false,
	children: null,
};

export default function modalReducer(state = initialState, action) {
    switch (action.type) {
        case MOUNT_MODAL:
            return Object.assign({}, state, {
                shouldRenderModal: true,
                children: action.children,
            });
        case UNMOUNT_MODAL:
            return Object.assign({}, state, {
                shouldRenderModal: false,
            });
        default:
            return state;
    }
}