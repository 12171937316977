import {
	FETCH_LATEST_POSTS_PENDING,
	FETCH_LATEST_POSTS_SUCCESSFUL,
	FETCH_ISSUES_GRAPH_DATA_PENDING,
	FETCH_ISSUES_GRAPH_DATA_SUCCESSFUL,
	FETCH_RESIDENT_DASHBOARD_LEASE_DETAILS_PENDING,
	FETCH_RESIDENT_DASHBOARD_LEASE_DETAILS_SUCCESSFUL,
	FETCH_RESIDENT_DASHBOARD_WIFI_DETAILS_PENDING,
	FETCH_RESIDENT_DASHBOARD_WIFI_DETAILS_SUCCESSFUL,
	FETCH_RESIDENT_DASHBOARD_BUILDING_MANAGER_DETAILS_PENDING,
	FETCH_RESIDENT_DASHBOARD_BUILDING_MANAGER_DETAILS_SUCCESSFUL,
	FETCH_RESIDENT_DASHBOARD_LUXPOD_BANK_DETAILS_PENDING,
	FETCH_RESIDENT_DASHBOARD_LUXPOD_BANK_DETAILS_SUCCESSFUL,
} from "../actions/types";

const initialState = {
	isFetchLatestPostsPending: false,
	latestPosts: [],
	isFetchIssuesGraphDataPending: false,
	issuesGraphData: {},
	isFetchResidentDashboardLeaseDetailsPending: false,
	residentDashboardLeaseDetails: {},
	isFetchResidentDashboardWifiDetailsPending: false,
	residentDashboardWifiDetails: {},
	isFetchResidentDashboardBuildingManagerDetailsPending: false,
	residentDashboardBuildingManagerDetails: {},
	isFetchResidentDashboardLuxpodBankDetailsPending: false,
	residentDashboardLuxpodBankDetails: {},
};

export default function dashboardReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_LATEST_POSTS_PENDING:
			return Object.assign({}, state, {
				isFetchLatestPostsPending: action.payload,
			});
		case FETCH_LATEST_POSTS_SUCCESSFUL:
			return Object.assign({}, state, {
				latestPosts: action.payload,
			});
		case FETCH_ISSUES_GRAPH_DATA_PENDING:
			return Object.assign({}, state, {
				isFetchIssuesGraphDataPending: action.payload,
			});
		case FETCH_ISSUES_GRAPH_DATA_SUCCESSFUL:
			return Object.assign({}, state, {
				issuesGraphData: action.payload,
			});
		case FETCH_RESIDENT_DASHBOARD_LEASE_DETAILS_PENDING:
			return Object.assign({}, state, {
				isFetchResidentDashboardLeaseDetailsPending: action.payload,
			});
		case FETCH_RESIDENT_DASHBOARD_LEASE_DETAILS_SUCCESSFUL:
			return Object.assign({}, state, {
				residentDashboardLeaseDetails: action.payload,
			});
		case FETCH_RESIDENT_DASHBOARD_WIFI_DETAILS_PENDING:
			return Object.assign({}, state, {
				isFetchResidentDashboardWifiDetailsPending: action.payload,
			});
		case FETCH_RESIDENT_DASHBOARD_WIFI_DETAILS_SUCCESSFUL:
			return Object.assign({}, state, {
				residentDashboardWifiDetails: action.payload,
			});
		case FETCH_RESIDENT_DASHBOARD_BUILDING_MANAGER_DETAILS_PENDING:
			return Object.assign({}, state, {
				isFetchResidentDashboardBuildingManagerDetailsPending: action.payload,
			});
		case FETCH_RESIDENT_DASHBOARD_BUILDING_MANAGER_DETAILS_SUCCESSFUL:
			return Object.assign({}, state, {
				residentDashboardBuildingManagerDetails: action.payload,
			});
		case FETCH_RESIDENT_DASHBOARD_LUXPOD_BANK_DETAILS_PENDING:
			return Object.assign({}, state, {
				isFetchResidentDashboardLuxpodBankDetailsPending: action.payload,
			});
		case FETCH_RESIDENT_DASHBOARD_LUXPOD_BANK_DETAILS_SUCCESSFUL:
			return Object.assign({}, state, {
				residentDashboardLuxpodBankDetails: action.payload,
			});
		default:
			return state;
	}
}
