import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Pagination from "../../Ui/Pagination";
import RowLoader from "../../Ui/RowLoader";
import avatar from "../../../assets/images/no_image_user.jpg";
import enter from "../../../assets/images/enter.svg";
import csvIcon from "../../../assets/images/csv_icon.svg";
import { ADMIN_RESIDENTS_DEFAULT_PAGE_SIZE, ADMIN_RESIDENT_STATUSES } from "../../../constants";
import { utils } from "../../../utils";
import Dropdown from "../../Ui/Dropdown";

const AdminResidentsListPage = ({
	residentsData: { residents, isFetchResidentsPending, meta, csvLink },
	isAdmin,
	...rest
}) => {
	const [currentPageNumber, setCurrentPageNumber] = useState(
		meta && meta.currentpage ? parseInt(meta.currentpage) + 1 : 1
	);

	const [searchFilterValue, setSearchFilterValue] = useState("");

	const [residentStatusFilterValue, setResidentStatusFilterValue] = useState(
		ADMIN_RESIDENT_STATUSES[0].value
	);

	const handleResidentStatusDropdownChange = (e, status) => {
		setResidentStatusFilterValue(status.value);
	};

	const fetchResidents = (toFilter, pageNo) => {
		rest.fetchResidents(
			toFilter,
			searchFilterValue,
			residentStatusFilterValue,
			ADMIN_RESIDENTS_DEFAULT_PAGE_SIZE,
			pageNo
		);
	};

	// // fetchResidents was to be debounced so that the number of API calls made while typing can be reduced
	// (Instead of debouncing, api cancellation is done when same api is called again quickly)
	// const fetchResidentsDebounced = utils.debounceFunction(fetchResidents, 1000);

	useEffect(() => {
		fetchResidents(false, currentPageNumber);
	}, [currentPageNumber]);

	const filterResidents = () => {
		// fetchResidentsDebounced(true);
		fetchResidents(true);
	};

	useEffect(() => {
		if (searchFilterValue || residentStatusFilterValue) {
			filterResidents();
		} else {
			fetchResidents(false, currentPageNumber);
		}
	}, [searchFilterValue, residentStatusFilterValue]);

	const handleSearchChange = (e) => {
		setSearchFilterValue(e.target.value);
	};

	const exportToCSV = () => {
		console.log("Hello");
		rest.fetchCSVFileLink();
	};

	useEffect(() => {
		if (csvLink) {
			let downloadLink = document.createElement("a");
			downloadLink.href = csvLink;
			downloadLink.setAttribute("target","_blank");
			downloadLink.download = "residents.csv";

			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
			rest.resetCSVLink();
		}
	}, [csvLink]);

	return (
		<Fragment>
			<div className="container-fluid">
				<div className="title-bar d-flex justify-content-between align-items-center">
					<div className="font-45 dblue d-bold">Residents</div>

					<div className="create-post">
						<div className="search-div search-residents d-flex align-items-center">
							<form>
								<div className="search-field position-relative">
									<input
										type="search"
										value={searchFilterValue}
										onChange={handleSearchChange}
										placeholder="Search for a resident"
									/>
									<input type="submit" onClick={(e) => e.preventDefault()} />
								</div>
							</form>

							<Link
								to="/admin/residents/add"
								className="red-bg white bold curved-btn"
							>
								<span className="d-bold">+</span>Add new
							</Link>
						</div>
					</div>
				</div>
				<div className="d-flex justify-content-end align-items-center mt-20">
					<div className="lp-fields resident-filter">
						<Dropdown
							options={ADMIN_RESIDENT_STATUSES}
							defaultSelectedOption={ADMIN_RESIDENT_STATUSES[0]}
							handleChange={handleResidentStatusDropdownChange}
							parentCssClass="list-unstyled customselect"
						/>
					</div>
					<div onClick={exportToCSV}>
						<img className="mr-10" src={csvIcon} alt="csv_icon" />
						<Link className="bold dblue">Export to CSV</Link>
					</div>
				</div>
			</div>

			<div className="resident_list pt-40">
				<div className="container-fluid">
					<div className="single-resident">
						{!isFetchResidentsPending ? (
							residents && residents.length > 0 ? (
								residents.map((resident) => (
									<ResidentRow resident={resident} isAdmin={isAdmin} />
								))
							) : (
								<center>No residents found.</center>
							)
						) : (
							<RowLoader />
						)}
					</div>
				</div>
			</div>
			{meta && meta.pagecount > 1 && (
				<Pagination
					totalNumberOfPages={meta.pagecount}
					currentPageNumber={currentPageNumber}
					setCurrentPageNumber={setCurrentPageNumber}
				/>
			)}
		</Fragment>
	);
};

export default AdminResidentsListPage;

const ResidentRow = ({ resident, isAdmin }) => (
	<Fragment>
		{!!resident && (
			<div
				key={resident.id}
				className="resident d-flex justify-content-between align-items-center"
			>
				<div className="resident_image">
					<div className="resident__image">
						<img src={resident.image || avatar} alt="resident_image" />
					</div>
				</div>
				<div className="resident_name">
					<div className="font-20">{utils.clipTextWithEllipsis(resident.fullname,25)}</div>
				</div>
				<div className="resident_phone-email">
					<a href={"tel:+" + resident.phone} className="black-color font-18">
						{resident.phone && "+" + utils.formatPhoneNumber(resident.phone)}
					</a>
					<a href={"mailto:" + resident.email} className="black-color font-18">
						{resident.email}
					</a>
				</div>
				<div className="resident_room">
					<div className="bold text-uppercase">ROOM</div>
					<div className="regular">{resident.room}</div>
				</div>
				<div className="resident_start">
					<div className="bold text-uppercase">START</div>
					<div className="regular">{resident.startdate}</div>
				</div>
				<div className="resident_end">
					<div className="bold text-uppercase">END</div>
					<div className="regular">{resident.enddate}</div>
				</div>
				<div className="resident_status">
					<div className="bold">STATUS</div>
					<div className="regular">
						{ADMIN_RESIDENT_STATUSES.find(
							(status) => status.value == resident.status
						).label || resident.status}
					</div>
				</div>
				{isAdmin && (
					<div className="resident_rent">
						<div className="bold">FORTNIGHTLY RENT</div>
						<div className="regular">{resident.fortnightlyrent}</div>
					</div>
				)}
				<div className="resident_enter">
					<Link to={`/admin/residents/${resident.id}`}>
						<img src={enter} alt="enter" />
					</Link>
				</div>
			</div>
		)}
	</Fragment>
);