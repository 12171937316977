import {
	LOGIN_SUCCESS,
	SET_RESET_PASSWORD_STATUS,
	LOGOUT,
	USER_LOADED,
	SET_EDIT_ADMIN_PROFILE_STATUS,
	SET_EDIT_ADMIN_PASSWORD_STATUS,
	SET_FETCH_RESIDENT_PROFILE_DETAILS_PENDING,
	SET_FETCH_RESIDENT_PROFILE_DETAILS_SUCCESSFUL,
	SET_SAVE_RESIDENT_PROFILE_DETAILS_STATUS,
	SET_NOTIFICATIONS_COUNT,
	SET_FORCE_UPDATE,
	SET_LOGIN_PATH,
	SET_LOGGING_OUT,
	SET_FETCH_DEFAULT_POSTAL_ADDRESS_PENDING,
	SET_FETCH_DEFAULT_POSTAL_ADDRESS_SUCCESSFUL,
} from "./types";
// import { setIssuesBadgeCount } from "./issues";
import apiService from '../services/apiService';
import {
	showSuccessMessage,
	showErrorMessage,
	// showDefaultErrorMessage,
} from "./toast";
import { DEFAULT_ERROR_MESSAGE } from "../constants";

const loginSuccess = (payload) => (dispatch) => {
	dispatch({
		type: LOGIN_SUCCESS,
		payload,
	});
};

export const login = ({ email, password }) => (dispatch) => {
	apiService.login({ email, password }).then(
		(res) => {
			if (res && res.user && res.role) {
				dispatch(loginSuccess(res));
			} else {
				dispatch(showErrorMessage(res.error || DEFAULT_ERROR_MESSAGE));
			}
			// if (res && res.total_notifications > 0) {
			// 	dispatch(setIssuesBadgeCount(res.total_notifications));
			// }
			// dispatch(showSuccessMessage(res.message));
		},
		(err) => {
			dispatch(
				showErrorMessage(
					(err.error || err.message) || DEFAULT_ERROR_MESSAGE
				)
			);
		}
	);
};

export const setLoginPath = (path) => (dispatch) => {
	dispatch({
		type: SET_LOGIN_PATH,
		payload: path
	});
};

export const forgotPassword = (email) => (dispatch) => {
	apiService.forgotPassword(email).then(
		(res) => {
			dispatch(showSuccessMessage(res.message));
		},
		(err) => {
			dispatch(
				showErrorMessage(
					(err.error || err.message) || DEFAULT_ERROR_MESSAGE
				)
			);
		}
	);
};

export const setResetPasswordStatus = (payload) => (dispatch) => {
	dispatch({
		type: SET_RESET_PASSWORD_STATUS,
		payload,
	});
};

export const resetPassword = (password, token) => (dispatch) => {
	apiService
		.resetPassword({ password, token })
		.then(
		(res) => {
			// dispatch(showSuccessMessage(res.message));
			dispatch(setResetPasswordStatus(true));
		},
		(err) => {
			dispatch(
				showErrorMessage(
					(err.error || err.message) || DEFAULT_ERROR_MESSAGE
				)
			);
		}
	);
};

export const setEditAdminProfileStatus = (payload) => (dispatch) => {
	dispatch({
		type: SET_EDIT_ADMIN_PROFILE_STATUS,
		payload,
	});
};

export const userLoaded = (payload) => dispatch => {
	dispatch({
		type: USER_LOADED,
		payload
	});
};

export const setNotificationsCount = (notificationsCount) => (dispatch) => {
	dispatch({
		type: SET_NOTIFICATIONS_COUNT,
		payload: notificationsCount,
	});
};

export const setForceUpdate = (shouldUpdateForcefully) => (dispatch) => {
	dispatch({
		type: SET_FORCE_UPDATE,
		payload: shouldUpdateForcefully,
	});
};

export const saveProfile = (data) => (dispatch) => {
	apiService.saveAdminProfile(data).then(
		(res) => {
			dispatch(userLoaded(res.user));
			// dispatch(showSuccessMessage(res.message || "Profile updated successfully"));
			dispatch(setEditAdminProfileStatus(true));
		},
		(err) => {
			dispatch(
				showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
			);
		}
	);
};

export const deleteProfilePicture = () => (dispatch) => {
	apiService.deleteResidentProfilePicture().then(
		(res) => {
			if (res.user) {
				const user = { ...res.user, resident_id: res.tenant_id };
				dispatch(userLoaded(user));
			}
		},
		(err) => {
			dispatch(showErrorMessage(err.message || "Profile picture could not be deleted"));
		}
	);
};

export const setEditAdminPasswordStatus = (payload) => (dispatch) => {
	dispatch({
		type: SET_EDIT_ADMIN_PASSWORD_STATUS,
		payload,
	});
};

export const editAdminPassword = (password) => (dispatch) => {
	apiService.editAdminPassword(password).then(
		(res) => {
			// dispatch(showSuccessMessage(res.message));
			dispatch(setEditAdminPasswordStatus(true));
		},
		(err) => {
			dispatch(showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE));
		}
	);
};

export const setFetchResidentProfileDetailsPending = (payload) => (dispatch) => {
	dispatch({
		type: SET_FETCH_RESIDENT_PROFILE_DETAILS_PENDING,
		payload,
	});
};

export const setFetchResidentProfileDetailsSuccessful = (payload) => (dispatch) => {
	dispatch({
		type: SET_FETCH_RESIDENT_PROFILE_DETAILS_SUCCESSFUL,
		payload,
	});
};

export const fetchResidentProfileDetails = () => (dispatch) => {
	dispatch(setFetchResidentProfileDetailsPending(true));
	apiService.fetchResidentProfileDetails().then(
		(res) => {
			dispatch(setFetchResidentProfileDetailsPending(false));
			dispatch(setFetchResidentProfileDetailsSuccessful(res.result));
		},
		(err) => {
			dispatch(setFetchResidentProfileDetailsPending(false));
			dispatch(showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE));
		}
	);
};

export const setfetchDefaultPostalAddressPending = (payload) => (dispatch) => {
	dispatch({
		type: SET_FETCH_DEFAULT_POSTAL_ADDRESS_PENDING,
		payload,
	});
};

export const setfetchDefaultPostalAddressSuccessful = (payload) => (dispatch) => {
	dispatch({
		type: SET_FETCH_DEFAULT_POSTAL_ADDRESS_SUCCESSFUL,
		payload,
	});
};

export const fetchDefaultPostalAddress = () => (dispatch) => {
	dispatch(setfetchDefaultPostalAddressPending(true));
	apiService.fetchDefaultPostalAddress().then(
		(res) => {
			dispatch(setfetchDefaultPostalAddressPending(false));
			dispatch(setfetchDefaultPostalAddressSuccessful(res.result));
		},
		(err) => {
			dispatch(setfetchDefaultPostalAddressPending(false));
			dispatch(showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE));
		}
	);
};


export const setSaveResidentProfileDetailsStatus = (payload) => (dispatch) => {
	dispatch({
		type: SET_SAVE_RESIDENT_PROFILE_DETAILS_STATUS,
		payload,
	});
};

export const saveResidentProfileDetails = (path, residentData) => (dispatch) => {
	// dispatch(saveResidentProfileDetailsPending(true));
	apiService.saveResidentProfileDetails(path, residentData).then(
		(res) => {
			// dispatch(saveResidentProfileDetailsPending(false));
			dispatch(setSaveResidentProfileDetailsStatus(true));
			// dispatch(
			// 	showSuccessMessage(
			// 		res.message || "Resident saved successfully."
			// 	)
			// );
			if (res.result && res.result.user) {
				const user = { ...res.result.user, resident_id: res.result.tenant_id };
				dispatch(userLoaded(user));
			}
		},
		(err) => {
			console.error(err);
			// dispatch(saveResidentProfileDetailsPending(false));
			dispatch(showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE));
		}
	);
};

export const setLoggingOut = (isLoggingOut) => (dispatch) => {
	dispatch({
		type: SET_LOGGING_OUT,
		payload: isLoggingOut,
	});
};

export const logout = () => dispatch => {
	// dispatch(setLoggingOut(true));
    dispatch({ type: LOGOUT });
   // dispatch({ type: CLEAR_PROFILE });
};
