import React, { Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ProfileDetailsPage from "./ProfileDetailsPage";
import EditProfileDetailsPage from "./EditProfileDetailsPage";
// import { RESIDENT_POSTS_DEFAULT_PAGE_SIZE } from "../../../constants";
// import PostLoader from "../../Ui/PostLoader";
import "./residentProfile.scss";
import EditPassword from "../../../containers/Auth/EditPassword";

const ResidentProfilePage = ({
	auth,
	courseTypes,
	logout,
	...rest
}) => {
	return (
		<Fragment>
			<Switch>
				<Route
					path="/resident/profile/details"
					render={(props) => (
						<ProfileDetailsPage
							{...props}
							auth={auth}
							logout={logout}
							fetchResidentProfileDetails={
								rest.fetchResidentProfileDetails
							}
						/>
					)}
				/>
				<Route
					path="/resident/profile/edit-info"
					render={(props) => (
						<EditProfileDetailsPage
							{...props}
							auth={auth}
							courseTypes={courseTypes}
							{...rest}
						/>
					)}
				/>
				<Route
					path="/resident/profile/edit-password"
					render={(props) => (
						<EditPassword
							{...props}
							// auth={auth}
							// courseTypes={courseTypes}
							// {...rest}
						/>
					)}
				/>
				<Route
					path="/"
					render={() => <Redirect to="/resident/profile/details" />}
				/>
			</Switch>
		</Fragment>
	);
};

export default ResidentProfilePage;
