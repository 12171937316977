import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import leftBlueArrow from "../../../assets/images/left-blue-arrow.svg";
import avatar from "../../../assets/images/no_image_user.png";
import addMedia from "../../../assets/images/add-media.svg";
import Datepicker from "../../Ui/Datepicker";
import ValidationMessage from "../../Ui/ValidationMessage";
import LuxpodSuccessMessage from "../../Ui/LuxpodSuccessMessage";
import {
	OCCUPATIONS,
	EMAIL_PATTERN,
	PHONE_NUMBER_PATTERN,
	ALLOWED_IMAGE_FILE_EXTENSIONS,
	MAX_ALLOWED_PROFILE_IMAGE_SIZE,
	OCCUPATION_TYPE_STUDENT,
	OCCUPATION_TYPE_PROFESSIONAL,
	PHONE_COUNTRY_CODE_MAX_LENGTH,
	PHONE_AREA_CODE_MAX_LENGTH,
	PHONE_NUMBER_MAX_LENGTH,
} from "../../../constants";
import { utils } from "../../../utils";

const EditProfileDetailsPage = ({
	courseTypes,
	auth: { residentProfileDetails, isSaveResidentProfileDetailsSuccessful },
	...rest
}) => {
	useEffect(() => {
		rest.fetchCourseTypes();
	}, []);

	useEffect(() => {
		return () => {
			rest.setSaveResidentProfileDetailsStatus(false);
		};
	},[]);

	const goBack = (e) => {
		if (e) {
			e.preventDefault();
		}
		window.history.go(-1);
	};

	const history = useHistory();
	const goBackToProfileDetails = () => {
		rest.setSaveResidentProfileDetailsStatus(false);
		history.push("/resident/profile/details");
	};
	// useEffect(() => {
	// 	if (isSaveResidentProfileDetailsSuccessful) {
	// 		rest.setSaveResidentProfileDetailsStatus(false);
	// 		goBack();
	// 	}
	// }, [isSaveResidentProfileDetailsSuccessful]);

	const getCurrentDropDownObject = (options, value) => {
		return (
			options.find((occupation) => occupation.value == value) ||
			options[0]
		);
	};

	const initialOccupation =
		getCurrentDropDownObject(
			OCCUPATIONS,
			residentProfileDetails.occupation_type
		) || OCCUPATIONS[0];
	const [occupation, setOccupation] = useState(initialOccupation);

	const [initialCourseType, setInitialCourseType] = useState({});
	const [courseType, setCourseType] = useState({});

	useEffect(() => {
		if (courseTypes.length > 0) {
			if (
				residentProfileDetails.courses_type_id ||
				residentProfileDetails.courses_type_id === 0
			) {
				setInitialCourseType(
					courseTypes[
						residentProfileDetails.courses_type_id === 0
							? 0
							: residentProfileDetails.courses_type_id
					]
				);
				setCourseType(
					courseTypes[
						residentProfileDetails.courses_type_id === 0
							? 0
							: residentProfileDetails.courses_type_id
					]
				);
			} else {
				setInitialCourseType(courseTypes[0]);
				setCourseType(courseTypes[0]);
			}
		} else {
			setCourseType({});
		}
	}, [courseTypes]);

	const [isOccupationDropdownOpen, setIsOccupationDropdownOpen] = useState(
		false
	);
	const [isCourseTypeDropdownOpen, setIsCourseTypeDropdownOpen] = useState(
		false
	);

	const initialFormData = {
		firstName: residentProfileDetails.first_name,
		lastName: residentProfileDetails.last_name,
		preferredName: residentProfileDetails.preferred_name,
		email: residentProfileDetails.email,
		// phone: residentProfileDetails.phone,
		phoneCountryCode: residentProfileDetails.phone_country_code,
		phoneAreaCode: residentProfileDetails.phone_area_code,
		phoneNumber: residentProfileDetails.phone_number,
		dob: new Date(utils.getDateObjectFromDDMMYYYY(residentProfileDetails.dob)),
		educationalInstitution: residentProfileDetails.education,
		organizationName: residentProfileDetails.organizationname,
		postalAddress: residentProfileDetails.postal_address_string,
		emergencyContactName: residentProfileDetails.emergency_contact_name,
		emergencyContactRelationship: residentProfileDetails.emergency_contact_relationship,
		emergencyContactPhoneCountryCode: residentProfileDetails.emergency_contact_phone_country_code,
		emergencyContactPhoneAreaCode: residentProfileDetails.emergency_contact_phone_area_code,
		emergencyContactPhoneNumber: residentProfileDetails.emergency_contact_phone_number,
		emergencyContactAddress: residentProfileDetails.emergency_contact_address,
	};
	const initialformErrors = {
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		phoneCountryCode: "",
		phoneAreaCode: "",
		phoneNumber: "",
		dob: "",
		occupation: "",
		courseType: "",
		educationalInstitution: "",
		organizationName: "",
		// address: "",
		emergencyContactName: "",
		emergencyContactRelationship: "",
		emergencyContactPhone: "",
		emergencyContactPhoneCountryCode: "",
		emergencyContactPhoneAreaCode: "",
		emergencyContactPhoneNumber: "",
		emergencyContactAddress: "",
	};
	const [formData, setFormData] = useState(initialFormData);
	const [formErrors, setFormErrors] = useState(initialformErrors);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	const handleTextInputChange = (e) => {
		if (
			e.target.name == "phoneCountryCode" ||
			e.target.name == "phoneAreaCode" ||
			e.target.name == "phoneNumber" ||
			e.target.name == "emergencyContactPhoneCountryCode" ||
			e.target.name == "emergencyContactPhoneAreaCode" ||
			e.target.name == "emergencyContactPhoneNumber"
		) {
			if (/^\d*$/.test(e.target.value)) {
				setFormData({
					...formData,
					[e.target.name]: e.target.value,
				});
			}
		} else {
			setFormData({
				...formData,
				[e.target.name]: e.target.value,
			});
		}
	};

	const handleDatepickerChange = (date, field) => {
		setFormData({
			...formData,
			[field]: date,
		});
	};

	const toggleSelect = (e) => {
		switch (e.currentTarget.id) {
			case "occupation":
				setIsOccupationDropdownOpen(!isOccupationDropdownOpen);
				break;
			case "courseType":
				setIsCourseTypeDropdownOpen(!isCourseTypeDropdownOpen);
				break;
			default:
				break;
		}
	};

	const initialFileUploadData = {
		fileName: "",
		file: null,
		fileUrl: residentProfileDetails.image,
	};
	const [fileUploadData, setFileUploadData] = useState(initialFileUploadData);

	const handleFileInputChange = (e) => {
		const extension = e.target.files[0].name.split(".").pop();
		if (ALLOWED_IMAGE_FILE_EXTENSIONS.includes(extension)) {
			if (e.target.files[0].size > MAX_ALLOWED_PROFILE_IMAGE_SIZE) {
				// showErrorMessage("Maximum file size allowed is 12 MB.");
				onCloseImagePreview();
			} else {
				setFileUploadData({
					...fileUploadData,
					file: e.target.files[0],
					fileName: e.target.files[0].name,
				});
			}
		} else {
			// showErrorMessage("Please upload a valid file format.");
			onCloseImagePreview();
		}
	};
	
	const onCloseImagePreview = () => {
		setFileUploadData({
			...initialFileUploadData,
			fileUrl: null,
		});
	};

	const [isResidentProfileUpdated, setIsResidentProfileUpdated] = useState(false);

	const isObjectIdentical = (object1, object2) => {
		return JSON.stringify(object1) === JSON.stringify(object2);
	};

	const checkIfPostUpdated = () => {
		if (
			!isObjectIdentical(formData, initialFormData) ||
			!isObjectIdentical(occupation, initialOccupation) ||
			!isObjectIdentical(courseType, initialCourseType) ||
			!isObjectIdentical(fileUploadData, initialFileUploadData)
		) {
			setIsResidentProfileUpdated(true);
		} else {
			setIsResidentProfileUpdated(false);
		}
	};

	useEffect(() => {
		checkIfPostUpdated();
		if (isFormSubmitted) {
			isFormValid();
		}
	}, [formData, occupation, courseType, fileUploadData]);

	const isFormValid = () => {
		let updatedFormErrors = formErrors;
		let isFormValid = true;
		Object.keys(formErrors).forEach((field) => {
			switch (field) {
				case "firstName":
					if (!formData.firstName) {
						updatedFormErrors[field] = "Please enter your first name";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "lastName":
					if (!formData.lastName) {
						updatedFormErrors[field] = "Please enter your last name";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "email":
					if (!formData.email) {
						updatedFormErrors[field] = "Please enter your email";
						isFormValid = false;
					} else if (!formData.email.match(EMAIL_PATTERN)) {
						updatedFormErrors[field] = "Please enter a valid email address";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				// case "phone":
				// 	if (!formData.phone) {
				// 		updatedFormErrors[field] =
				// 			"Please enter your phone number";
				// 		isFormValid = false;
				// 	} else if (!formData.phone.match(PHONE_NUMBER_PATTERN)) {
				// 		updatedFormErrors[field] =
				// 			"Please enter a valid (10-digit numeric) phone number";
				// 		isFormValid = false;
				// 	} else {
				// 		updatedFormErrors[field] = "";
				// 	}
				// 	break;
				// Check data from split fields but error message will be singular for 'phone'
				case "phoneCountryCode":
				case "phoneAreaCode":
				case "phoneNumber":
					if (
						// !formData.phoneCountryCode &&
						// !formData.phoneAreaCode &&
						!formData.phoneNumber
					) {
						updatedFormErrors["phone"] = "Please enter the your phone number";
						isFormValid = false;
					} else if (
						!("" + formData.phoneCountryCode).match(/^[0-9]{1,3}$|^$/) // ||
						// !("" + formData.phoneAreaCode).match(/^[0-9]{1-3}$/) ||
						// !("" + formData.phoneNumber).match(/^[0-9]{8-10}$/)
					) {
						updatedFormErrors["phone"] =
							"Please enter a valid (1 to 3 digits long) country code";
						isFormValid = false;
					} else if (!("" + formData.phoneAreaCode).match(/^[0-9]{1,3}$|^$/)) {
						updatedFormErrors["phone"] =
							"Please enter a valid (1 to 3 digits long) area code";
						isFormValid = false;
					} else if (!("" + formData.phoneNumber).match(/^[0-9]{8,10}$/)) {
						updatedFormErrors["phone"] =
							"Please enter a valid (8 to 10 digits long) local number";
						isFormValid = false;
					} else {
						updatedFormErrors["phone"] = "";
					}
					break;
				case "occupation":
					if (!occupation.value) {
						updatedFormErrors["occupation"] = "Please select your occupation";
						isFormValid = false;
					} else {
						updatedFormErrors["occupation"] = "";
					}
					break;
				case "courseType":
					if (
						occupation.value == OCCUPATION_TYPE_STUDENT &&
						!courseType.value
					) {
						updatedFormErrors["courseType"] = "Please select your course type";
						isFormValid = false;
					} else {
						updatedFormErrors["courseType"] = "";
					}
					break;
				case "educationalInstitution":
					if (
						occupation.value == OCCUPATION_TYPE_STUDENT &&
						!formData.educationalInstitution
					) {
						updatedFormErrors[field] =
							"Please enter your educational institution";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "organizationName":
					if (
						occupation.value == OCCUPATION_TYPE_PROFESSIONAL &&
						!formData.organizationName
					) {
						updatedFormErrors[field] =
							"Please enter your company/business name";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				// case "address":
				// 	if (!formData.address) {
				// 		updatedFormErrors[field] = "Please enter your address";
				// 		isFormValid = false;
				// 	} else {
				// 		updatedFormErrors[field] = "";
				// 	}
				// 	break;
				case "dob":
					if (!formData.dob) {
						updatedFormErrors[field] = "Please enter your date of birth";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				default:
					break;
				case "emergencyContactName":
					if (!formData.emergencyContactName) {
						updatedFormErrors[field] =
							"Please enter the your emergency contact's name";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "emergencyContactRelationship":
					if (!formData.emergencyContactRelationship) {
						updatedFormErrors[field] =
							"Please enter the your relationship with their emergency contact";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "emergencyContactPhoneCountryCode":
				case "emergencyContactPhoneAreaCode":
				case "emergencyContactPhoneNumber":
					if (
						// !formData.emergencyContactPhoneCountryCode &&
						// !formData.emergencyContactPhoneAreaCode &&
						!formData.emergencyContactPhoneNumber
					) {
						updatedFormErrors["emergencyContactPhone"] =
							"Please enter the your emergency contact's phone number";
						isFormValid = false;
					} else if (
						!("" + formData.emergencyContactPhoneCountryCode).match(
							/^[0-9]{1,3}$|^$/
						)
					) {
						updatedFormErrors["emergencyContactPhone"] =
							"Please enter a valid (1 to 3 digits long) country code";
						isFormValid = false;
					} else if (
						!("" + formData.emergencyContactPhoneAreaCode).match(
							/^[0-9]{1,3}$|^$/
						)
					) {
						updatedFormErrors["emergencyContactPhone"] =
							"Please enter a valid (1 to 3 digits long) area code";
						isFormValid = false;
					} else if (
						!("" + formData.emergencyContactPhoneNumber).match(/^[0-9]{8,10}$/)
					) {
						updatedFormErrors["emergencyContactPhone"] =
							"Please enter a valid (8 to 10 digits long) local number";
						isFormValid = false;
					} else {
						updatedFormErrors["emergencyContactPhone"] = "";
					}
					break;
				case "emergencyContactAddress":
					if (!formData.emergencyContactAddress) {
						updatedFormErrors[field] =
							"Please enter the your emergency contact's address";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
			}
		});
		if (isFormValid) {
			updatedFormErrors = initialformErrors;
		}
		setFormErrors({ ...formErrors, ...updatedFormErrors });
		return isFormValid;
	};

	const createResidentData = () => {
		var data = new FormData();
		data.append("id", residentProfileDetails.id);
		data.append("first_name", formData.firstName);
		data.append("last_name", formData.lastName);
		data.append("preferred_name", formData.preferredName);
		// data.append("email", formData.email);
		data.append("phone_country_code", formData.phoneCountryCode);
		data.append("phone_area_code", formData.phoneAreaCode);
		data.append("phone_number", formData.phoneNumber);
		data.append("dob", utils.formatDateYYYYMMDD(formData.dob));
		data.append("occupation_type", occupation.value);
		if (occupation.value === OCCUPATION_TYPE_STUDENT) {
			data.append("course_types_id", courseType.id);
			data.append("education", formData.educationalInstitution);
		}
		if (occupation.value === OCCUPATION_TYPE_PROFESSIONAL) {
			data.append("organizationname", formData.organizationName);
		}
		// data.append("address", formData.address);
		data.append("emergency_contact_name", formData.emergencyContactName);
		data.append("emergency_contact_relationship", formData.emergencyContactRelationship);
		data.append("emergency_contact_phone_country_code", formData.emergencyContactPhoneCountryCode);
		data.append("emergency_contact_phone_area_code", formData.emergencyContactPhoneAreaCode);
		data.append("emergency_contact_phone_number", formData.emergencyContactPhoneNumber);
		data.append("emergency_contact_address", formData.emergencyContactAddress);
		if (fileUploadData.file) {
			data.append(
				"image",
				fileUploadData.file,
				fileUploadData.fileName
			);
		}
		
		for (let pair of data.entries()) {
			console.log(pair[0]+ ', ' + pair[1]);
		}
		return data;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setIsFormSubmitted(true);
		if (isFormValid()) {
			rest.saveResidentProfileDetails(createResidentData());
			if (initialFileUploadData.fileUrl && !fileUploadData.fileUrl) {
				rest.deleteProfilePicture();
			}
		}
	};

	
	return (
		<Fragment>
			<div className="edit-resident-profile-details">
				<div className="container-fluid">
					<div className="title-bar d-flex justify-content-between align-items-center">
						<div className="font-45 dblue d-bold">
							{!isSaveResidentProfileDetailsSuccessful && (
								<Link onClick={goBack}>
									<img src={leftBlueArrow} alt="go back" />
								</Link>
							)}
							<span>Edit Info</span>
						</div>
					</div>
				</div>
				{isSaveResidentProfileDetailsSuccessful ? (
					<LuxpodSuccessMessage
						message="Personal Details Updated Successfully!"
						goBackToDefaultScreen={goBackToProfileDetails}
						returnTo={"Profile Details"}
					/>
				) : (
					<Fragment>
						<div className="profile-details col-md-12">
							<h6 className="dblue d-bold col-md-12 font-45">
								<label>Personal Info</label>
							</h6>
							<div className="lp-fields position-relative d-flex align-items-center">
								<img
									id="ImgPreview"
									src={
										(fileUploadData.file &&
											URL.createObjectURL(fileUploadData.file)) ||
										fileUploadData.fileUrl ||
										avatar
									}
									alt="profile_image"
									className="preview1 it"
								/>
								<span className="btn_upload">
									<input
										type="file"
										id="imag"
										title=""
										className="input-img"
										onChange={handleFileInputChange}
									/>
									<img src={addMedia} alt="file_upload_icon" />
									Change image
								</span>
								<input
									type="button"
									id="removeImage1"
									value="x"
									className="btn-rmv1 rmv"
									onClick={onCloseImagePreview}
								/>
							</div>
						</div>
						<form onSubmit={handleSubmit}>
							{/* </div></div><div className="col-md-9 col-sm-12><div className="row"> */}
							<div className="col-md-9 col-sm-12 pt-40">
								<div className="row">
									<div className="col-md-4 col-sm-12">
										<div className="font-18">First Name</div>
										<div className="lp-fields">
											<input
												type="text"
												name="firstName"
												value={formData.firstName}
												onChange={handleTextInputChange}
											/>
											{isFormSubmitted && formErrors.firstName && (
												<ValidationMessage id="firstNameHelp">
													{formErrors.firstName}
												</ValidationMessage>
											)}
										</div>
									</div>
									<div className="col-md-4 col-sm-12">
										<div className="font-18">Last Name</div>
										<div className="lp-fields">
											<input
												type="text"
												name="lastName"
												value={formData.lastName}
												onChange={handleTextInputChange}
											/>
											{isFormSubmitted && formErrors.lastName && (
												<ValidationMessage id="lastNameHelp">
													{formErrors.lastName}
												</ValidationMessage>
											)}
										</div>
									</div>
									<div className="col-md-4 col-sm-12">
										<div className="font-18">Preferred Name</div>
										<div className="lp-fields">
											<input
												type="text"
												name="preferredName"
												value={formData.preferredName}
												onChange={handleTextInputChange}
											/>
											{/* {isFormSubmitted && formErrors.preferredName && (
												<ValidationMessage id="preferredNameHelp">
													{formErrors.preferredName}
												</ValidationMessage>
											)} */}
										</div>
									</div>
									<div className="col-md-4 col-sm-12">
										<div className="font-18">Email</div>
										<div className="lp-fields">
											<input
												// className="pl-15"
												type="email"
												name="email"
												value={formData.email}
												// onChange={handleTextInputChange}
												disabled
											/>
											{isFormSubmitted && formErrors.email && (
												<ValidationMessage id="emailHelp">
													{formErrors.email}
												</ValidationMessage>
											)}
										</div>
									</div>
									{/* <div className="col-md-4 col-sm-12">
										<div className="font-18">Phone</div>
										<div className="lp-fields">
											<input
												type="tel"
												name="phone"
												value={formData.phone}
												onChange={handleTextInputChange}
											/>
											{isFormSubmitted && formErrors.phone && (
												<ValidationMessage id="phoneHelp">
													{formErrors.phone}
												</ValidationMessage>
											)}
										</div>
									</div> */}
									<div className="col-md-4 col-sm-12">
										<div className="font-18">Mobile/Landline</div>
										<div className="lp-fields">
											<div className="phone-fields d-flex justify-content-between align-items-center">
												<input
													type="tel"
													name="phoneCountryCode"
													placeholder="Country"
													value={formData.phoneCountryCode}
													onChange={handleTextInputChange}
													maxLength={PHONE_COUNTRY_CODE_MAX_LENGTH}
												/>
												<input
													type="tel"
													name="phoneAreaCode"
													placeholder="Area Code"
													value={formData.phoneAreaCode}
													onChange={handleTextInputChange}
													maxLength={PHONE_AREA_CODE_MAX_LENGTH}
												/>
												<input
													type="tel"
													name="phoneNumber"
													placeholder="Phone Number"
													value={formData.phoneNumber}
													onChange={handleTextInputChange}
													maxLength={PHONE_NUMBER_MAX_LENGTH}
												/>
											</div>
											{isFormSubmitted && formErrors.phone && (
												<ValidationMessage id="phoneHelp">
													{formErrors.phone}
												</ValidationMessage>
											)}
										</div>
									</div>
									<div className="col-md-4 col-sm-12">
										<div className="font-18">Date of Birth</div>
										<div className="lp-fields">
											<Datepicker
												placeholderText="dd/mm/yyyy"
												dateFormat="dd/MM/yyyy"
												minDate={null}
												maxDate={new Date()}
												selectedDate={formData.dob}
												handleDatepickerChange={handleDatepickerChange}
												name="dob"
											/>
											{isFormSubmitted && formErrors.dob && (
												<ValidationMessage id="dobHelp">
													{formErrors.dob}
												</ValidationMessage>
											)}
										</div>
									</div>
									<div className="col-md-4 col-sm-12">
										<div className="font-18">Occupation</div>
										<div className="lp-fields">
											<ul
												id="occupation"
												name="occupation"
												className={
													isOccupationDropdownOpen
														? "list-unstyled customselect show"
														: "list-unstyled customselect"
												}
												onClick={toggleSelect}
											>
												<li className="init">{occupation.label}</li>
												{OCCUPATIONS.map(
													(occupation, index) =>
														index > 0 && (
															<li
																id={occupation.value}
																onClick={() => setOccupation(occupation)}
															>
																{occupation.label}
															</li>
														)
												)}
											</ul>
											{isFormSubmitted && formErrors.occupation && (
												<ValidationMessage id={`occupationHelp`}>
													{formErrors.occupation}
												</ValidationMessage>
											)}
										</div>
									</div>
									{occupation.value == OCCUPATIONS[1].value && (
										<Fragment>
											{courseTypes.length > 0 && (
												<div className="col-md-4 col-sm-12">
													<div className="font-18">Course Type</div>
													<div className="lp-fields">
														<ul
															id="courseType"
															name="courseType"
															className={
																isCourseTypeDropdownOpen
																	? "list-unstyled customselect show"
																	: "list-unstyled customselect"
															}
															onClick={toggleSelect}
														>
															{courseType && (
																<li className="init">{courseType.label}</li>
															)}
															{courseTypes.map(
																(courseType, index) =>
																	index > 0 && (
																		<li
																			id={courseType.value}
																			onClick={() => setCourseType(courseType)}
																		>
																			{courseType.label}
																		</li>
																	)
															)}
														</ul>
														{/* <Dropdown
													options={
														ADMIN_ISSUES_REQUEST_STATUSES
													}
													defaultSelectedOption={
														ADMIN_ISSUES_REQUEST_STATUSES.find(
															(status) =>
																status.value ==
																selectedIssueDetails.status
														) ||
														ADMIN_ISSUES_REQUEST_STATUSES[0]
													}
													handleChange={
														handleStatusDropdownChange
													}
													parentCssClass={`list-unstyled customselect status-issue ${selectedIssueDetails.status} white`}
													id={selectedIssueDetails.id}
												/> */}
														{isFormSubmitted && formErrors.courseType && (
															<ValidationMessage id={`courseTypeHelp`}>
																{formErrors.courseType}
															</ValidationMessage>
														)}
													</div>
												</div>
											)}
											<div className="col-md-4 col-sm-12">
												<div className="font-18">Educational Institution</div>
												<div className="lp-fields">
													<input
														type="text"
														name="educationalInstitution"
														value={formData.educationalInstitution}
														onChange={handleTextInputChange}
													/>
													{isFormSubmitted &&
														formErrors.educationalInstitution && (
															<ValidationMessage id="educationalInstitutionHelp">
																{formErrors.educationalInstitution}
															</ValidationMessage>
														)}
												</div>
											</div>
										</Fragment>
									)}
									{occupation.value == OCCUPATIONS[2].value && (
										<div className="col-md-4 col-sm-12">
											<div className="font-18">Company/Business Name</div>
											<div className="lp-fields">
												<input
													type="text"
													name="organizationName"
													value={formData.organizationName}
													onChange={handleTextInputChange}
												/>
												{isFormSubmitted && formErrors.organizationName && (
													<ValidationMessage id="organizationNameHelp">
														{formErrors.organizationName}
													</ValidationMessage>
												)}
											</div>
										</div>
									)}
									<div className="col-md-12 col-sm-12">
										<div className="font-18">Postal Address</div>
										<div className="lp-fields">
											<input
												// className="pl-15"
												type="text"
												name="postalAddress"
												value={formData.postalAddress}
												onChange={handleTextInputChange}
												disabled
											/>
											{isFormSubmitted && formErrors.postalAddress && (
												<ValidationMessage id="postalAddressHelp">
													{formErrors.postalAddress}
												</ValidationMessage>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-12">
								<h6 className="dblue d-bold col-md-12">
									<label>Emergency Contact</label>
								</h6>
							</div>
							<div className="col-md-9 col-sm-12">
								<div className="row">
									<div className="col-md-4 col-sm-12">
										<div className="font-18">Name</div>
										<div className="lp-fields">
											<input
												type="text"
												name="emergencyContactName"
												value={formData.emergencyContactName}
												onChange={handleTextInputChange}
											/>
											{isFormSubmitted && formErrors.emergencyContactName && (
												<ValidationMessage id="emergencyContactNameHelp">
													{formErrors.emergencyContactName}
												</ValidationMessage>
											)}
										</div>
									</div>
									<div className="col-md-4 col-sm-12">
										<div className="font-18">Relationship</div>
										<div className="lp-fields">
											<input
												type="text"
												name="emergencyContactRelationship"
												value={formData.emergencyContactRelationship}
												onChange={handleTextInputChange}
											/>
											{isFormSubmitted &&
												formErrors.emergencyContactRelationship && (
													<ValidationMessage id="emergencyContactRelationshipHelp">
														{formErrors.emergencyContactRelationship}
													</ValidationMessage>
												)}
										</div>
									</div>
									<div className="col-md-4 col-sm-12">
										<div className="font-18">Mobile/Landline</div>
										<div className="lp-fields">
											<div className="phone-fields d-flex justify-content-between align-items-center">
												<input
													type="tel"
													name="emergencyContactPhoneCountryCode"
													placeholder="Country"
													value={formData.emergencyContactPhoneCountryCode}
													onChange={handleTextInputChange}
													maxLength={PHONE_COUNTRY_CODE_MAX_LENGTH}
												/>
												<input
													type="tel"
													name="emergencyContactPhoneAreaCode"
													placeholder="Area Code"
													value={formData.emergencyContactPhoneAreaCode}
													onChange={handleTextInputChange}
													maxLength={PHONE_AREA_CODE_MAX_LENGTH}
												/>
												<input
													type="tel"
													name="emergencyContactPhoneNumber"
													placeholder="Phone Number"
													value={formData.emergencyContactPhoneNumber}
													onChange={handleTextInputChange}
													maxLength={PHONE_NUMBER_MAX_LENGTH}
												/>
											</div>
											{isFormSubmitted && formErrors.emergencyContactPhone && (
												<ValidationMessage id="emergencyContactPhoneHelp">
													{formErrors.emergencyContactPhone}
												</ValidationMessage>
											)}
										</div>
									</div>
									<div className="col-md-4 col-sm-12">
										<div className="font-18">Address</div>
										<div className="lp-fields">
											<input
												type="text"
												name="emergencyContactAddress"
												value={formData.emergencyContactAddress}
												onChange={handleTextInputChange}
											/>
											{isFormSubmitted &&
												formErrors.emergencyContactAddress && (
													<ValidationMessage id="emergencyContactAddressHelp">
														{formErrors.emergencyContactAddress}
													</ValidationMessage>
												)}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-12">
								<div className="note d-flex-column">
									<div className="note-details d-bold font-50">
										If you require to update your email address, please contact
									</div>
									<div className="note-link font-36">
										<a href="mailto:info@luxpod.com.au">info@luxpod.com.au</a>
									</div>
								</div>
								<div className="col-md-12 d-flex pt-40">
									<button
										type="submit"
										className="curved-btn red-bg white bold"
										disabled={!isResidentProfileUpdated}
									>
										Save
									</button>
									<button
										className="curved-btn cancel red-border red bold"
										onClick={goBack}
									>
										Cancel
									</button>
								</div>
							</div>
						</form>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};

export default EditProfileDetailsPage;
