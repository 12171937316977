
export const LUXPOD_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    // "https://adcreatorsdemo.com.au/luxpod/frontend/web/index.php?r=api";
export const API_SUCCESS_CODE = 200;

export const USER_TYPE_ADMIN = "Admin";
export const USER_TYPE_BUILDING_MANAGER = "building-manager";
export const USER_TYPE_RESIDENT = "resident";
export const USER_TYPE_GUEST = "guest";

export const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
export const PHONE_NUMBER_PATTERN = /^[0-9]{10}$/;
export const NUMBER_PATTERN = /^[0-9]+$/;

export const PHONE_COUNTRY_CODE_MAX_LENGTH = 3;
export const PHONE_AREA_CODE_MAX_LENGTH = 3;
export const PHONE_NUMBER_MAX_LENGTH = 10;

export const DEFAULT_ERROR_MESSAGE = "Something went wrong. Please refresh and try again";
export const DEFAULT_NOTIFICATION_TIMEOUT = 7000;
export const PAGINATION_LENGTH = 5;

export const ADMIN_ROUTES = [
    {
        label: "Dashboard",
        path: "/admin/dashboard"
    },
    {
        label: "Noticeboard",
        path: "/admin/noticeboard"
    },
    {
        label: "Residents",
        path: "/admin/residents"
    },
    {
        label: "Issues",
        path: "/admin/issues"
    },
    {
        label: "Wifi",
        path: "/admin/wifi"
    }
];

export const RESIDENT_ROUTES = [
    {
        label: "Dashboard",
        path: "/resident/dashboard"
    },
    // {
    //     label: "Rent",
    //     path: "/resident/rent"
    // },
    {
        label: "Noticeboard",
        path: "/resident/noticeboard"
    },
    {
        label: "Report Issue",
        path: "/resident/issues"
    },
    {
        label: "My Profile",
        path: "/resident/profile"
    }
];

export const BUILDING_MANAGER_ROUTES = [
	{
		label: "Dashboard",
		path: "/admin/dashboard",
	},
	{
		label: "Noticeboard",
		path: "/admin/noticeboard",
	},
	{
		label: "Residents",
		path: "/admin/residents",
	},
	{
		label: "Issues",
		path: "/admin/issues",
	},
    {
        label: "Wifi",
        path: "/admin/wifi"
    }
];

export const ADMIN_POSTS_MONTHS = {
    current: "CURRENT",
    previous: "PREVIOUS",
};
export const ADMIN_POSTS_MONTHS_LABELS = {
    CURRENT: "This Month Posts",
    PREVIOUS: "Previous Posts"
};

export const ALLOWED_IMAGE_FILE_EXTENSIONS = [
    "jpeg",
    "png",
    "PNG",
    "jpg",
    "gif",
    "svg",
];
export const MAX_ALLOWED_POST_IMAGE_SIZE = 12000000;


export const ADMIN_RESIDENTS_DEFAULT_PAGE_SIZE = 5;

export const MAX_ALLOWED_PROFILE_IMAGE_SIZE = 12000000;
export const OCCUPATIONS = [
    {
        label: "Select Occupation",
        value: "",
    },
    {
        label: "Student",
        value: "Student",
    },
    {
        label: "Professional",
        value: "Professional",
    },
];
export const OCCUPATION_TYPE_STUDENT = OCCUPATIONS[1].value;
export const OCCUPATION_TYPE_PROFESSIONAL = OCCUPATIONS[2].value;

export const LEVELS = [
    {
        label: "1",
        value: 1,
    },
    {
        label: "2",
        value: 2,
    },
    {
        label: "3",
        value: 3,
    },
];

export const ALLOWED_OCCUPANTS_PER_ROOM = [
    {
        label: "1",
        value: 1,
    },
    {
        label: "2",
        value: 2,
    },
];

export const MONTHS_MM = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

export const ALLOWED_ATTACHMENT_DOCUMENTS_EXTENSIONS = ["pdf", "png", "PNG", "jpeg", "jpg"];

export const ADMIN_ISSUES_DEFAULT_PAGE_SIZE = 5;
export const ADMIN_ISSUES_FILTER_REQUEST_STATUSES = [
    {
        label: "Request status",
        value: ""
    },
    {
        label: "All",
        value: ""
    },
    {
        label: "New",
        value: "created",
    },
    {
        label: "In Progress",
        value: "in_progress",
    },
    {
        label: "Completed",
        value: "completed",
    },
];
export const ADMIN_ISSUES_REQUEST_STATUSES = [
    {
        label: "New",
        value: "created",
    },
    {
        label: "In Progress",
        value: "in_progress",
    },
    {
        label: "Completed",
        value: "completed",
    },
];

export const RESIDENT_POSTS_DEFAULT_PAGE_SIZE = 9;
export const RESIDENT_ISSUES_DEFAULT_PAGE_SIZE = 5;

export const ADMIN_RESIDENT_STATUSES = [
	{
		label: "All",
		value: "",
	},
	{
		label: "Active",
		value: "Active",
	},
	{
		label: "Archived",
		value: "Past",
	},
];
