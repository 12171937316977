import { combineReducers } from 'redux';
import auth from './auth';
import alert from './alert';
import loaderReducer from "./loader";
import modalReducer from "./modal";
import toastReducer from "./toast";
import noticeboardReducer from "./noticeboard";
import residentsReducer from "./residents";
import issueReducer from "./issues";
import dashboardReducer from "./dashboard";
import wifiReducer from "./wifi";

export default combineReducers({
	auth,
	alert,
	loader: loaderReducer,
	modal: modalReducer,
	toast: toastReducer,
	noticeboard: noticeboardReducer,
	residents: residentsReducer,
	issues: issueReducer,
	dashboard: dashboardReducer,
	wifi: wifiReducer,
});