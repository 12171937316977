import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import Pagination from "../../Ui/Pagination";
import RowLoader from "../../Ui/RowLoader";
import Dropdown from "../../Ui/Dropdown";
import Datepicker from "../../Ui/Datepicker";
import {
	ADMIN_ISSUES_DEFAULT_PAGE_SIZE,
	ADMIN_ISSUES_REQUEST_STATUSES,
	ADMIN_ISSUES_FILTER_REQUEST_STATUSES,
} from "../../../constants";
import { utils } from "../../../utils";
import viewIcon from "../../../assets/images/view1.svg";

const AdminIssuesListPage = ({
	issuesData: {
		issues,
		isFetchIssuesPending,
		meta,
		hasStatusChanged,
		defaultFilter,
		// unreadIssuesCount,
	},
	authData: { total_notifications, shouldUpdateForcefully },
	...rest
}) => {
	const [currentPageNumber, setCurrentPageNumber] = useState(
		// Add 1 to meta.currentpage once API is integrated as API returns currentpage starting with 0
		meta && meta.currentpage
			? meta.currentpage == 0
				? parseInt(meta.currentpage) + 1
				: parseInt(meta.currentpage)
			: 1
	);

	const [searchFilterValue, setSearchFilterValue] = useState("");

	const [selectedRequestDate, setSelectedRequestDate] = useState("");
	const [requestDateFilterValue, setRequestDateFilterValue] = useState("");

	// const [isStatusFilterDropdownOpen, setIsStatusFilterDropdownOpen] = useState(false);
	// const [selectedRequestStatus, setSelectedRequestStatus] = useState(ADMIN_ISSUES_REQUEST_STATUSES[0]);
	const [requestStatusFilterValue, setRequestStatusFilterValue] = useState(
		""
	);

	const [isFiltering, setIsFiltering] = useState(false);

	const fetchIssues = () => {
		rest.fetchIssues(
			isFiltering,
			searchFilterValue,
			requestDateFilterValue,
			requestStatusFilterValue,
			ADMIN_ISSUES_DEFAULT_PAGE_SIZE,
			currentPageNumber
		);
	};

	useEffect(() => {
		fetchIssues();
	}, [currentPageNumber]);

	useEffect(() => {
		if (defaultFilter && Object.keys(defaultFilter).length > 0) {
			setRequestStatusFilterValue(defaultFilter.value);
		}
	}, [defaultFilter]);

	useEffect(() => {
		return () => {
			rest.setDefaultFilter(null);
		};
	},[]);

	useEffect(() => {
		if (hasStatusChanged) {
			setCurrentPageNumber(1);
			if (currentPageNumber === 1) {
				fetchIssues();
			}
		}
	}, [hasStatusChanged]);

	// const searchIssues = () => {
	// 	fetchIssues();
	// };

	useEffect(() => {
		if (
			searchFilterValue ||
			requestDateFilterValue ||
			requestStatusFilterValue
		) {
			if (isFiltering) {
				fetchIssues();
			} else {
				setIsFiltering(true);
			}
		} else {
			setIsFiltering(false);
		}
	}, [searchFilterValue, requestDateFilterValue, requestStatusFilterValue]);

	useEffect(() => {
		fetchIssues();
	}, [isFiltering]);

	const refreshList = () => {
		if (searchFilterValue) {
			setSearchFilterValue("");
		}
		if (currentPageNumber > 1) {
			setCurrentPageNumber(1);
		}
		if (requestDateFilterValue) {
			setSelectedRequestDate("");
			setRequestDateFilterValue("");
		}
		if (requestStatusFilterValue) {
			setRequestStatusFilterValue("");
		}
		if (
			!searchFilterValue &&
			!requestDateFilterValue &&
			!requestStatusFilterValue &&
			currentPageNumber == 1
		) {
			fetchIssues();
		}
	};

	useEffect(() => {
		if (total_notifications > 0) {
			refreshList();
		}
	}, [total_notifications]);
	
	useEffect(() => {
		if (shouldUpdateForcefully) {
			refreshList();
			rest.setForceUpdate(false);
		}
	}, [shouldUpdateForcefully]);

	const handleSearchChange = (e) => {
		setSearchFilterValue(e.target.value);
	};

	const handleDatepickerChange = (date) => {
		setSelectedRequestDate(date);
		setRequestDateFilterValue(date ? utils.formatDateYYYYMMDD(date) : "");
	};

	const handleRequestStatusDropdownChange = (e, status) => {
		// setSelectedRequestStatus(status);
		setRequestStatusFilterValue(status.value);
	};

	const setRequestStatusValue = (id, value) => {
		rest.changeIssueStatus(id, value);
	};

	const handleStatusDropdownChange = (e, status, id) => {
		e.preventDefault();
		setRequestStatusValue(id, status.value);
	};

	return (
		<Fragment>
			<div className="container-fluid">
				<div className="title-bar d-flex justify-content-between align-items-center">
					<div className="font-45 dblue d-bold">Issues</div>

					<div className="issues-search">
						<div className="search-div admin-issues search-residents d-flex align-items-center">
							<div className="lp-fields">
								{/* <input type="date" /> */}
								<Datepicker
									placeholderText="Request date"
									dateFormat="dd/MM/yyyy"
									maxDate={new Date()}
									selectedDate={selectedRequestDate}
									handleDatepickerChange={
										handleDatepickerChange
									}
								/>
							</div>
							<div className="lp-fields">
								{/* <ul
									className={
										isStatusFilterDropdownOpen
											? "list-unstyled customselect show"
											: "list-unstyled customselect"
									}
									onClick={() =>
										setIsStatusFilterDropdownOpen(!isStatusFilterDropdownOpen)
									}
								>
									<li className="init">
										{selectedRequestStatus.label}
									</li>
									{ADMIN_ISSUES_REQUEST_STATUSES.map(
										(status) => (
											<li
												onClick={() =>
													handleRequestStatusDropdownChange(status)
												}
											>
												{status.label}
											</li>
										)
									)}
								</ul> */}
								<Dropdown
									options={
										ADMIN_ISSUES_FILTER_REQUEST_STATUSES
									}
									defaultSelectedOption={
										defaultFilter &&
										Object.keys(defaultFilter).length > 0
											? defaultFilter
											: ADMIN_ISSUES_FILTER_REQUEST_STATUSES[0]
									}
									handleChange={
										handleRequestStatusDropdownChange
									}
									parentCssClass="list-unstyled customselect"
									shouldHideFirstOption={true}
								/>
							</div>
							<form>
								<div className="search-field position-relative">
									<input
										placeholder="Search for an issue"
										type="search"
										value={searchFilterValue}
										onChange={handleSearchChange}
									/>
									<input type="submit" />
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			<div className="issue_list pt-40">
				<div className="container-fluid">
					<div className="issue__list">
						{!isFetchIssuesPending ? (
							issues && issues.length > 0 ? (
								issues.map((issue) => (
									<IssueRow
										issue={issue}
										handleStatusDropdownChange={
											handleStatusDropdownChange
										}
									/>
								))
							) : (
								<center>No issues found.</center>
							)
						) : (
							<RowLoader />
						)}
					</div>
				</div>
			</div>
			{meta && meta.pagecount > 1 && (
				<Pagination
					totalNumberOfPages={meta.pagecount}
					currentPageNumber={currentPageNumber}
					setCurrentPageNumber={setCurrentPageNumber}
				/>
			)}
		</Fragment>
	);
};

export default AdminIssuesListPage;


const IssueRow = ({ issue, handleStatusDropdownChange }) => (
	// <Link to={`/admin/issues/${issue.id}`} key={issue.id}>
	<Fragment>
		<div
			className={`issues-row d-flex justify-content-between align-items-center${
				issue && issue.is_read ? "" : " unread-bg"
			}`}
		>
			<div className="issue_name">
				{/* <Link to={`/admin/issues/${issue.id}`} key={issue.id}> */}
				<div className="font-20">{utils.clipTextWithEllipsis(issue.title,25)}</div>
				{/* </Link> */}
			</div>
			<div className="case-number">
				{/* <Link to={`/admin/issues/${issue.id}`} key={issue.id}> */}
				<div className="font-18">Issue #{issue.id}</div>
				{/* </Link> */}
			</div>
			<div className="resident_name">
				{/* <Link to={`/admin/issues/${issue.id}`} key={issue.id}> */}
				<div className="font-18">{issue.fullname}</div>
				{/* </Link> */}
			</div>
			<div className="level-room">
				{/* <Link to={`/admin/issues/${issue.id}`} key={issue.id}> */}
				<div className="font-18">
					{/* Level {issue.level}, */}
					Room {issue.room}
				</div>
				{/* </Link> */}
			</div>
			<div className="issue-date">
				{/* <Link to={`/admin/issues/${issue.id}`} key={issue.id}> */}
				<div className="font-18">{issue.requestdate}</div>
				{/* </Link> */}
			</div>
			<div className="issue-view">
				<Link
					to={`/admin/issues/${issue.id}`}
					key={issue.id}
					data-toggle="tooltip"
					title="View issue details"
				>
					<div className="font-18">
						<img src={viewIcon} alt="view" />
					</div>
				</Link>
			</div>
			<div className="issue-status">
				{/* {ADMIN_ISSUES_REQUEST_STATUSES.find(
					(status) => status.value == issue.status
				) && (
					<div
						className={`font-18 status-issue ${issue.status} white`}
					>
						{
							ADMIN_ISSUES_REQUEST_STATUSES.find(
								(status) => status.value == issue.status
							).label
						}
					</div>
				)} */}
				<Dropdown
					options={ADMIN_ISSUES_REQUEST_STATUSES}
					defaultSelectedOption={
						ADMIN_ISSUES_REQUEST_STATUSES.find(
							(status) => status.value == issue.status
						) || ADMIN_ISSUES_REQUEST_STATUSES[0]
					}
					handleChange={handleStatusDropdownChange}
					parentCssClass={`list-unstyled customselect status-issue ${issue.status} white`}
					id={issue.id}
					shouldHideFirstOption={false}
				/>
			</div>
		</div>
	</Fragment>
	// </Link>
);
