import React from 'react';
import PropTypes from 'prop-types';

const Notification = (props) => {
    return (
		// <div className="notificationWrapper">
		// 	<div
		// 		className={
		// 			"alert alert-white rounded show " + props.alertClassName
		// 		}
		// 	>
		// 		{/* <span className="icon"><img src={props.imageSrc} alt="" /></span> */}
		// 		<div className="text text-center">
		// 			<strong>{props.messageLabel}</strong> <br /> {props.message}
		// 		</div>
		// 	</div>
		// </div>

		<div class={`notification ${props.alertClassName}`}>
			<span class="font-24 bold">{props.messageLabel}</span>
			{props.message}
		</div>
	);
}

Notification.propTypes = {
	message: PropTypes.string.isRequired,
	messageLabel: PropTypes.string.isRequired,
	alertClassName: PropTypes.string.isRequired,
};

export default Notification;
