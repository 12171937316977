import {
	FETCH_RESIDENTS_PENDING,
	FETCH_RESIDENTS_SUCCESSFUL,
	FETCH_RESIDENT_DETAILS_PENDING,
	FETCH_RESIDENT_DETAILS_SUCCESSFUL,
	FETCH_RESIDENT_LEASE_DETAILS_PENDING,
	FETCH_RESIDENT_LEASE_DETAILS_SUCCESSFUL,
	FETCH_COURSE_TYPES_PENDING,
	FETCH_COURSE_TYPES_SUCCESSFUL,
	SET_SAVE_RESIDENT_STATUS,
	FETCH_CSV_LINK_PENDING,
	FETCH_CSV_LINK_SUCCESSFUL,
	RESET_CSV_LINK,
} from "./types";
import { DEFAULT_ERROR_MESSAGE } from "../constants";
import apiService from "../services/apiService";
import {
	showSuccessMessage,
	showErrorMessage,
	// showDefaultErrorMessage,
} from "./toast";

const fetchResidentsPending = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENTS_PENDING,
		payload,
	});
};

const fetchResidentsSuccessful = (result, meta) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENTS_SUCCESSFUL,
		payload: {
			result,
			meta,
		},
	});
};

export const fetchResidents = (toSearch, searchString, residentStatusFilterValue, pageSize, pageNo) => (
	dispatch
) => {
	dispatch(fetchResidentsPending(true));
	apiService.fetchResidents(toSearch, searchString, residentStatusFilterValue, pageSize, pageNo).then(
		(res) => {
			dispatch(fetchResidentsPending(false));
			dispatch(fetchResidentsSuccessful(res.result, res.meta));
		},
		(err) => {
			console.error(err);
			if (!err.cancelledAsAnotherCallMade) {
				dispatch(fetchResidentsPending(false));
				dispatch(
					showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
				);
			}
		}
	);
};

const fetchResidentDetailsPending = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENT_DETAILS_PENDING,
		payload,
	});
};

const fetchResidentDetailsSuccessful = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENT_DETAILS_SUCCESSFUL,
		payload,
	});
};

export const fetchResidentDetails = (id) => (dispatch) => {
	dispatch(fetchResidentDetailsPending(true));
	apiService.fetchResidentDetails(id).then(
		(res) => {
			dispatch(fetchResidentDetailsPending(false));
			dispatch(
				fetchResidentDetailsSuccessful({
					...res.result,
					documents: res.documents,
				})
			);
		},
		(err) => {
			console.error(err);
			dispatch(fetchResidentDetailsPending(false));
			dispatch(
				showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
			);
		}
	);
};

const fetchResidentLeaseDetailsPending = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENT_LEASE_DETAILS_PENDING,
		payload,
	});
};

const fetchResidentLeaseDetailsSuccessful = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENT_LEASE_DETAILS_SUCCESSFUL,
		payload,
	});
};

export const fetchResidentLeaseDetails = (id) => (dispatch) => {
	dispatch(fetchResidentLeaseDetailsPending(true));
	apiService.fetchResidentLeaseDetails(id).then(
		(res) => {
			dispatch(fetchResidentLeaseDetailsPending(false));
			dispatch(fetchResidentLeaseDetailsSuccessful(res.result));
		},
		(err) => {
			console.error(err);
			dispatch(fetchResidentLeaseDetailsPending(false));
			dispatch(
				showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
			);
		}
	);
};

const fetchCourseTypesPending = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_COURSE_TYPES_PENDING,
		payload,
	});
};

const fetchCourseTypesSuccessful = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_COURSE_TYPES_SUCCESSFUL,
		payload,
	});
};

export const fetchCourseTypes = () => (dispatch) => {
	dispatch(fetchCourseTypesPending(true));
	apiService.fetchCourseTypes().then(
		(res) => {
			dispatch(fetchCourseTypesPending(false));
			dispatch(fetchCourseTypesSuccessful(res.result));
		},
		(err) => {
			console.error(err);
			dispatch(fetchCourseTypesPending(false));
			dispatch(
				showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
			);
		}
	);
};

const fetchCSVFileLinkPending = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_CSV_LINK_PENDING,
		payload,
	});
};

const fetchCSVFileLinkSuccessful = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_CSV_LINK_SUCCESSFUL,
		payload,
	});
};

export const resetCSVLink = () => (dispatch) => {
	dispatch({
		type: RESET_CSV_LINK,
	});
};

export const fetchCSVFileLink = () => (dispatch) => {
	dispatch(fetchCSVFileLinkPending(true));
	apiService.fetchCSVFileLink().then(
		(res) => {
			dispatch(fetchCSVFileLinkPending(false));
			dispatch(fetchCSVFileLinkSuccessful(res.link));
		},
		(err) => {
			console.error(err);
			dispatch(fetchCSVFileLinkPending(false));
			dispatch(showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE));
		}
	);
};

// const saveResidentPending = (payload) => (dispatch) => {
// 	dispatch({
// 		type: SAVE_RESIDENT_PENDING,
// 		payload,
// 	});
// };

export const setSaveResidentStatus = (payload) => (dispatch) => {
	dispatch({
		type: SET_SAVE_RESIDENT_STATUS,
		payload,
	});
};

export const saveResident = (path, residentData) => (dispatch) => {
	// dispatch(saveResidentPending(true));
	apiService.saveResident(path, residentData).then(
		(res) => {
			// dispatch(saveResidentPending(false));
			dispatch(setSaveResidentStatus(true));
			// dispatch(
			// 	showSuccessMessage(
			// 		res.message || "Resident saved successfully."
			// 	)
			// );
		},
		(err) => {
			console.error(err);
			// dispatch(saveResidentPending(false));
			dispatch(showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE));
		}
	);
};

export const deleteDocument = (id) => (dispatch) => {
	apiService.deleteDocument(id).then(
		(res) => {
			// dispatch(showSuccessMessage(res.message));
		},
		(err) => {
			console.error(err);
			dispatch(
				showErrorMessage(
					err.message || DEFAULT_ERROR_MESSAGE
				)
			);
		}
	);
};