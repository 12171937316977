import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AdminNoticeboardPage from "../../../components/AdminNoticeboard/AdminNoticeboardPage";
import {
	fetchAllPosts,
	savePost,
	deletePost,
	deletePostImage,
} from "../../../actions/noticeboard";
import { mountModal, unmountModal } from "../../../actions/modal";
import { showErrorMessage } from "../../../actions/toast";

const AdminNoticeboard = ({
	noticeboard,
	fetchAllPosts,
	savePost,
	deletePost,
	deletePostImage,
	mountModal,
	unmountModal,
	showErrorMessage,
	...rest
}) => {
	return (
		<AdminNoticeboardPage
			noticeboard={noticeboard}
			fetchAllPosts={fetchAllPosts}
			savePost={savePost}
			deletePost={deletePost}
			deletePostImage={deletePostImage}
			mountModal={mountModal}
			unmountModal={unmountModal}
			showErrorMessage={showErrorMessage}
		/>
	);
};

AdminNoticeboard.propTypes = {
	fetchAllPosts: PropTypes.func.isRequired,
	savePost: PropTypes.func.isRequired,
	deletePost: PropTypes.func.isRequired,
	deletePostImage: PropTypes.func.isRequired,
	mountModal: PropTypes.func.isRequired,
	unmountModal: PropTypes.func.isRequired,
	noticeboard: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	noticeboard: state.noticeboard,
});

export default connect(mapStateToProps, {
	fetchAllPosts,
	savePost,
	deletePost,
	deletePostImage,
	mountModal,
	unmountModal,
	showErrorMessage,
})(AdminNoticeboard);