import React from 'react';
import { Redirect } from "react-router-dom";
 
const Home = () => {
   return (
		// <div>
		// 	<h2>Home (Front)</h2>
		// 	<Link to="/login">to login</Link>
		// </div>
	   <Redirect to="/login" />
   );
}
 
export default Home;