import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";

import Home from "./../Home";
import Loader from "../Ui/Loader";
import Modal from "../Ui/Modal";
import Toast from "../Ui/Toast";

import AdminPrivateRoute from "./AdminPrivateRoute";
import AuthRoute from "./AuthRoute";
// import BuildingManagerPrivateRoute from "./BuildingManagerPrivateRoute";
import ResidentPrivateRoute from "./ResidentPrivateRoute";
import FrontRoute from "./FrontRoute";

import AdminProfile from "../../containers/Admin/AdminProfile";
import EditPassword from "../../containers/Auth/EditPassword";
import AdminDashboard from "../../containers/Admin/Dashboard/AdminDashboard";
import AdminNoticeboard from "../../containers/Admin/Noticeboard/AdminNoticeboard";
import AdminResidents from "../../containers/Admin/Residents/AdminResidents";
import AdminIssues from "../../containers/Admin/Issues/AdminIssues";
import AdminWifi from "../../conta../../containers/Admin/Wifi/AdminWifi";

// import BuildingManagerDashboard from "../../containers/BuildingManager/BuildingManagerDashboard";

import ResidentDashboard from "../../containers/Resident/Dashboard/ResidentDashboard";
import ResidentRent from "../../containers/Resident/Rent/ResidentRent";
import ResidentNoticeboard from "../../containers/Resident/Noticeboard/ResidentNoticeboard";
import ResidentIssues from "../../containers/Resident/Issues/ResidentIssues";
import ResidentProfile from "../../containers/Resident/Profile/ResidentProfile";

import Login from "../../containers/Auth/Login";
import ForgotPassword from "../../containers/Auth/ForgotPassword";
import ResetPassword from "../../containers/Auth/ResetPassword";

const Routes = () => {
  return (
		<Fragment>
			<Loader />
			<Modal />
			<Toast />
			<Switch>
				<FrontRoute exact path="/" component={Home} />
				<AuthRoute path="/login" component={Login} />
				<AuthRoute path="/forgot-password" component={ForgotPassword} />
				<AuthRoute path="/reset-password" component={ResetPassword} />
				<AdminPrivateRoute
					path="/admin/profile"
					component={AdminProfile}
				/>
				<AdminPrivateRoute
					path="/admin/edit-password"
					component={EditPassword}
				/>
				<AdminPrivateRoute
					path="/admin/dashboard"
					component={AdminDashboard}
				/>
				<AdminPrivateRoute
					path="/admin/noticeboard"
					component={AdminNoticeboard}
				/>
				<AdminPrivateRoute
					path="/admin/residents"
					component={AdminResidents}
				/>
				<AdminPrivateRoute
					path="/admin/issues"
					component={AdminIssues}
				/>
				<AdminPrivateRoute
					path="/admin/wifi"
					component={AdminWifi}
				/>
				{/* <BuildingManagerPrivateRoute
					path="/admin/bm-dashboard"
					component={BuildingManagerDashboard}
				/> */}
				<ResidentPrivateRoute
					path="/resident/dashboard"
					component={ResidentDashboard}
				/>
				{/* <ResidentPrivateRoute
					path="/resident/rent"
					component={ResidentRent}
				/> */}
				<ResidentPrivateRoute
					path="/resident/noticeboard"
					component={ResidentNoticeboard}
				/>
				<ResidentPrivateRoute
					path="/resident/issues"
					component={ResidentIssues}
				/>
				<ResidentPrivateRoute
					path="/resident/profile"
					component={ResidentProfile}
				/>
				<Route render={() => <h2>Page not found :(</h2>} />
			</Switch>
		</Fragment>
  );
};

export default Routes;
