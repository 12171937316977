export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
// export const REGISTER_FAIL = 'REGISTER_FAIL';
// export const AUTH_FAILED = 'AUTH_FAILED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const USER_LOADED = 'USER_LOADED';

export const SET_RESET_PASSWORD_STATUS = "SET_RESET_PASSWORD_STATUS";

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const MOUNT_MODAL = "MOUNT_MODAL";
export const UNMOUNT_MODAL = "UNMOUNT_MODAL";

export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SHOW_DEFAULT_ERROR_MESSAGE = "SHOW_DEFAULT_ERROR_MESSAGE";

// Admin

export const FETCH_CURRENT_MONTH_POSTS_PENDING = "FETCH_CURRENT_MONTH_POSTS_PENDING";
export const FETCH_CURRENT_MONTH_POSTS_SUCCESSFUL = "FETCH_CURRENT_MONTH_POSTS_SUCCESSFUL";
export const FETCH_PREVIOUS_POSTS_PENDING = "FETCH_PREVIOUS_POSTS_PENDING";
export const FETCH_PREVIOUS_POSTS_SUCCESSFUL = "FETCH_PREVIOUS_POSTS_SUCCESSFUL";
export const SAVE_POST_PENDING = "SAVE_POST_PENDING";
export const SAVE_POST_SUCCESSFUL = "SAVE_POST_SUCCESSFUL";


export const FETCH_RESIDENTS_PENDING = "FETCH_RESIDENTS_PENDING";
export const FETCH_RESIDENTS_SUCCESSFUL = "FETCH_RESIDENTS_SUCCESSFUL";
export const FETCH_RESIDENT_DETAILS_PENDING = "FETCH_RESIDENT_DETAILS_PENDING";
export const FETCH_RESIDENT_DETAILS_SUCCESSFUL = "FETCH_RESIDENT_DETAILS_SUCCESSFUL";
export const FETCH_RESIDENT_LEASE_DETAILS_PENDING = "FETCH_RESIDENT_LEASE_DETAILS_PENDING";
export const FETCH_RESIDENT_LEASE_DETAILS_SUCCESSFUL = "FETCH_RESIDENT_LEASE_DETAILS_SUCCESSFUL";

export const SET_EDIT_ADMIN_PROFILE_STATUS = "SET_EDIT_ADMIN_PROFILE_STATUS";
export const SET_EDIT_ADMIN_PASSWORD_STATUS = "SET_EDIT_ADMIN_PASSWORD_STATUS";
export const FETCH_COURSE_TYPES_PENDING = "FETCH_COURSE_TYPES_PENDING";
export const FETCH_COURSE_TYPES_SUCCESSFUL = "FETCH_COURSE_TYPES_SUCCESSFUL";

export const SET_SAVE_RESIDENT_STATUS = "SET_SAVE_RESIDENT_STATUS";


export const FETCH_ISSUES_PENDING = "FETCH_ISSUES_PENDING";
export const FETCH_ISSUES_SUCCESSFUL = "FETCH_ISSUES_SUCCESSFUL";
export const FETCH_ISSUE_DETAILS_PENDING = "FETCH_ISSUE_DETAILS_PENDING";
export const FETCH_ISSUE_DETAILS_SUCCESSFUL = "FETCH_ISSUE_DETAILS_SUCCESSFUL";
export const CHANGE_ISSUE_STATUS_SUCCESSFUL = "CHANGE_ISSUE_STATUS_SUCCESSFUL";

export const FETCH_LATEST_POSTS_PENDING = "FETCH_LATEST_POSTS_PENDING";
export const FETCH_LATEST_POSTS_SUCCESSFUL = "FETCH_LATEST_POSTS_SUCCESSFUL";
export const FETCH_ISSUES_GRAPH_DATA_PENDING = "FETCH_ISSUES_GRAPH_DATA_PENDING";
export const FETCH_ISSUES_GRAPH_DATA_SUCCESSFUL = "FETCH_ISSUES_GRAPH_DATA_SUCCESSFUL";

export const SET_ADMIN_ISSUES_DEFAULT_FILTER = "SET_ADMIN_ISSUES_DEFAULT_FILTER";

// Resident

export const FETCH_RESIDENT_POSTS_PENDING = "FETCH_RESIDENT_POSTS_PENDING";
export const FETCH_RESIDENT_POSTS_SUCCESSFULL = "FETCH_RESIDENT_POSTS_SUCCESSFULL";

export const SAVE_ISSUE_PENDING = "SAVE_ISSUE_PENDING";
export const SET_SAVE_ISSUE_STATUS = "SET_SAVE_ISSUE_STATUS";

export const FETCH_RESIDENT_ISSUES_PENDING = "FETCH_RESIDENT_ISSUES_PENDING";
export const FETCH_RESIDENT_ISSUES_SUCCESSFUL = "FETCH_RESIDENT_ISSUES_SUCCESSFUL";

export const SET_FETCH_RESIDENT_PROFILE_DETAILS_PENDING = "SET_FETCH_RESIDENT_PROFILE_DETAILS_PENDING";
export const SET_FETCH_RESIDENT_PROFILE_DETAILS_SUCCESSFUL = "SET_FETCH_RESIDENT_PROFILE_DETAILS_SUCCESSFUL";

export const SET_SAVE_RESIDENT_PROFILE_DETAILS_STATUS = "SET_SAVE_RESIDENT_PROFILE_DETAILS_STATUS";

export const FETCH_RESIDENT_DASHBOARD_LEASE_DETAILS_PENDING = "FETCH_RESIDENT_DASHBOARD_LEASE_DETAILS_PENDING";
export const FETCH_RESIDENT_DASHBOARD_LEASE_DETAILS_SUCCESSFUL = "FETCH_RESIDENT_DASHBOARD_LEASE_DETAILS_SUCCESSFUL";
export const FETCH_RESIDENT_DASHBOARD_WIFI_DETAILS_PENDING = "FETCH_RESIDENT_DASHBOARD_WIFI_DETAILS_PENDING";
export const FETCH_RESIDENT_DASHBOARD_WIFI_DETAILS_SUCCESSFUL = "FETCH_RESIDENT_DASHBOARD_WIFI_DETAILS_SUCCESSFUL";

export const SET_SAVE_WIFI_DETAILS_STATUS = "SET_SAVE_WIFI_DETAILS_STATUS";
export const SET_FETCH_ADMIN_WIFI_DETAILS_PENDING = "SET_FETCH_ADMIN_WIFI_DETAILS_PENDING";
export const SET_FETCH_ADMIN_WIFI_DETAILS_SUCCESSFUL = "SET_FETCH_ADMIN_WIFI_DETAILS_SUCCESSFUL";
export const FETCH_RESIDENT_DASHBOARD_BUILDING_MANAGER_DETAILS_PENDING = "FETCH_RESIDENT_DASHBOARD_BUILDING_MANAGER_DETAILS_PENDING";
export const FETCH_RESIDENT_DASHBOARD_BUILDING_MANAGER_DETAILS_SUCCESSFUL = "FETCH_RESIDENT_DASHBOARD_BUILDING_MANAGER_DETAILS_SUCCESSFUL";

// export const SET_ISSUES_BADGE_COUNT = "SET_ISSUES_BADGE_COUNT";
export const SET_NOTIFICATIONS_COUNT = "SET_NOTIFICATIONS_COUNT";
export const SET_FORCE_UPDATE = "SET_FORCE_UPDATE";

export const SET_FETCH_DEFAULT_POSTAL_ADDRESS_PENDING = "SET_FETCH_DEFAULT_POSTAL_ADDRESS_PENDING";
export const SET_FETCH_DEFAULT_POSTAL_ADDRESS_SUCCESSFUL = "SET_FETCH_DEFAULT_POSTAL_ADDRESS_SUCCESSFUL";

export const FETCH_CSV_LINK_PENDING = "FETCH_CSV_LINK_PENDING";
export const FETCH_CSV_LINK_SUCCESSFUL = "FETCH_CSV_LINK_SUCCESSFUL";
export const RESET_CSV_LINK = "RESET_CSV_LINK";

export const FETCH_RESIDENT_DASHBOARD_LUXPOD_BANK_DETAILS_PENDING = "FETCH_RESIDENT_DASHBOARD_LUXPOD_BANK_DETAILS_PENDING";
export const FETCH_RESIDENT_DASHBOARD_LUXPOD_BANK_DETAILS_SUCCESSFUL = "FETCH_RESIDENT_DASHBOARD_LUXPOD_BANK_DETAILS_SUCCESSFUL";

export const SET_LOGIN_PATH = "SET_LOGIN_PATH";
export const SET_LOGGING_OUT = "SET_LOGGING_OUT";
