import React, { Fragment } from "react";
import { Link, Redirect } from "react-router-dom";

import Post from "./Post";
import {
	ADMIN_POSTS_MONTHS_LABELS,
} from "../../constants";
import leftBlueArrow from "../../assets/images/left-blue-arrow.svg";
import "./adminNoticeboard.scss";

const AdminNoticeboardSeeAllPage = ({
	postsMonth,
	currentMonthPosts,
	previousPosts,
	openAddUpdatePostModal,
}) => {
	let posts = [];
	switch (postsMonth) {
		case "CURRENT":
			posts = currentMonthPosts;
			break;
		case "PREVIOUS":
			posts = previousPosts;
			break;
		default:
			break;
	}
	return (
		<Fragment>
			<div class="container-fluid">
				<div class="title-bar">
					<div class="font-45 dblue d-bold">
						<Link to="/admin/noticeboard">
							<img src={leftBlueArrow} alt="left-arrow" />
						</Link>
						{ADMIN_POSTS_MONTHS_LABELS[postsMonth]}
					</div>
				</div>
			</div>

			{posts.length > 0 ? (
				<div class="post_lists mt-5">
					<div class="container-fluid">
						<div class="prev-months">
							<div class="prev-months-list mt-4">
								<div class="row">
									{posts.length > 0 &&
										posts.map((post) => (
											<Post
												handleClick={
													openAddUpdatePostModal
												}
												post={post}
												parentCSSClass="col-md-4 col-sm-12"
												titleCSSClass="post_title font-15 bold mb-3"
											/>
										))}
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<Redirect to="/admin/noticeboard" />
			)}
		</Fragment>
	);
};

export default AdminNoticeboardSeeAllPage;
