import React from "react";
import { Route, Switch } from "react-router-dom";

import { USER_TYPE_ADMIN } from "../../../constants";
import AdminResidentsListPage from "./AdminResidentsListPage";
import AdminResidentDetailPage from "./AdminResidentDetailPage";
import AdminResidentLeaseDetailsPage from "./AdminResidentLeaseDetailsPage";
import AdminAddUpdateResidentPage from "./AdminAddUpdateResidentPage";
import "./adminResidents.scss";

const AdminResidentsPage = ({ residentsData, auth, ...rest }) => {
	return (
		<Switch>
			<Route
				exact
				path="/admin/residents/:id/lease"
				render={(props) => (
					<AdminResidentLeaseDetailsPage
						isAdmin={auth.userType === USER_TYPE_ADMIN ? true : false}
						residentsData={residentsData}
						fetchResidentLeaseDetails={rest.fetchResidentLeaseDetails}
						{...props}
					/>
				)}
			/>
			<Route
				path="/admin/residents/update"
				render={(props) => (
					<AdminAddUpdateResidentPage
						courseTypes={residentsData.courseTypes}
						fetchCourseTypes={rest.fetchCourseTypes}
						saveResident={rest.saveResident}
						deleteDocument={rest.deleteDocument}
						resident={residentsData.selectedResidentDetails}
						isSaveResidentSuccessful={residentsData.isSaveResidentSuccessful}
						fetchDefaultPostalAddress={rest.fetchDefaultPostalAddress}
						defaultPostalAddress={auth.defaultPostalAddress}
						setSaveResidentStatus={rest.setSaveResidentStatus}
						{...props}
					/>
				)}
			/>
			<Route
				path="/admin/residents/add"
				render={(props) => (
					<AdminAddUpdateResidentPage
						courseTypes={residentsData.courseTypes}
						fetchCourseTypes={rest.fetchCourseTypes}
						saveResident={rest.saveResident}
						isSaveResidentSuccessful={residentsData.isSaveResidentSuccessful}
						setSaveResidentStatus={rest.setSaveResidentStatus}
						fetchDefaultPostalAddress={rest.fetchDefaultPostalAddress}
						defaultPostalAddress={auth.defaultPostalAddress}
						{...props}
					/>
				)}
			/>
			<Route
				exact
				path="/admin/residents/:id"
				render={(props) => (
					<AdminResidentDetailPage
						fetchResidentDetails={rest.fetchResidentDetails}
						residentsData={residentsData}
						isAdmin={auth.userType === USER_TYPE_ADMIN ? true : false}
						{...props}
					/>
				)}
			/>
			<Route
				path="/"
				render={(props) => (
					<AdminResidentsListPage
						fetchResidents={rest.fetchResidents}
						fetchCSVFileLink={rest.fetchCSVFileLink}
						resetCSVLink={rest.resetCSVLink}
						residentsData={residentsData}
						isAdmin={auth.userType === USER_TYPE_ADMIN ? true : false}
						{...props}
					/>
				)}
			/>
			<Route render={() => <h2>Page not found :(</h2>} />
		</Switch>
	);
};

export default AdminResidentsPage;
