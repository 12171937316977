import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Datepicker = ({
	placeholderText,
	dateFormat,
	minDate,
	maxDate,
	selectedDate,
	handleDatepickerChange,
	name,
}) => {
	const minAllowedDate = new Date(`${new Date().getFullYear() - 120}`);
	const maxAllowedDate = new Date(`${new Date().getFullYear() + 10}`);

	return (
		<ReactDatePicker
			placeholderText={placeholderText}
			dateFormat={dateFormat}
			minDate={minDate || minAllowedDate}
			maxDate={maxDate || maxAllowedDate}
			selected={selectedDate}
			onChange={(date) => handleDatepickerChange(date, name)}
			showYearDropdown={true}
			scrollableYearDropdown={true}
			yearDropdownItemNumber={50}
		/>
	);
};

export default Datepicker;