import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { USER_TYPE_ADMIN, USER_TYPE_RESIDENT, USER_TYPE_BUILDING_MANAGER } from "../../constants";
import { logout } from "../../actions/auth";
import avatar from "../../assets/images/no_image_user.png";
import ProfileMenu from "../../components/Ui/ProfileMenu";

const Header = ({ auth: { user, userType }, ...rest }) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	return (
		<Fragment>
			<div className="header">
				<div className="container-fluid">
					<div className="search-user d-flex justify-content-end align-items-center">
						{/* <div className="search-div">
							<form>
								<div className="search-field position-relative">
									<input
										placeholder="Search..."
										type="search"
									/>
									<input type="submit" />
								</div>
							</form>
						</div> */}
						<div className="user-profile">
							{userType === USER_TYPE_RESIDENT && (
								<Link
									to="/resident/profile"
									className="user__profile"
								>
									<img
										src={
											(user && user.profile_picture) ||
											avatar
										}
										alt="profile_picture"
									/>
								</Link>
							)}
							{(userType === USER_TYPE_ADMIN ||
								userType === USER_TYPE_BUILDING_MANAGER) && (
								// <div className="row align-items-center">
								// 	<Link
								// 		to="/admin/edit-password"
								// 		className="curved-btn blue-bg bold white"
								// 	>
								// 		Edit Password
								// 	</Link>
								// 	<button
								// 		className="curved-btn logout-btn red bold"
								// 		onClick={rest.logout}
								// 	>
								// 		Logout
								// 	</button>
								// </div>
								<div className="user__profile profile-menu-container">
									<img
										src={
											(user && user.profile_picture) ||
											avatar
										}
										alt="profile_picture"
										onClick={() =>
											setIsMenuOpen(!isMenuOpen)
										}
									/>
									{isMenuOpen && (
										<ProfileMenu
											setIsMenuOpen={setIsMenuOpen}
											userType={userType}
											logout={rest.logout}
										/>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};


Header.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});
 
// export default Header;
export default connect(mapStateToProps, { logout })(Header);