import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ForgotPasswordPage from "../../components/Auth/ForgotPasswordPage";
import { forgotPassword } from "../../actions/auth";

const ForgotPassword = ({ forgotPassword, ...rest }) => (
	<ForgotPasswordPage forgotPassword={forgotPassword} />
);

ForgotPassword.propTypes = {
	forgotPassword: PropTypes.func.isRequired,
	// isAuth: PropTypes.bool,
};

// const mapStateToProps = (state) => ({
// 	isAuth: state.auth.isAuth,
// });

// export default connect(mapStateToProps, { forgotPassword, showSuccessMessage })(ForgotPassword);
export default connect(null, { forgotPassword })(ForgotPassword);
