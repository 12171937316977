import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Notification from "../Notification/Notification";
import { DEFAULT_ERROR_MESSAGE } from "../../constants";

const Toast = (props) => (
	<div>
		{props.isSuccessMessage && props.message && (
			<Notification
				alertClassName="success show"
				messageLabel="Success"
				// imageSrc={}
				message={props.message}
			/>
		)}
		{props.isErrorMessage && props.message && (
			<Notification
				alertClassName="error show"
				messageLabel="Error"
				// imageSrc={}
				message={props.message}
			/>
		)}
		{props.isWarningMessage && props.message && (
			<Notification
				alertClassName="alert-warning"
				messageLabel="Warning"
				// imageSrc={}
				message={props.message}
			/>
		)}

		{props.isDefaultErrorMessage && (
			<Notification
				alertClassName="error show"
				messageLabel="Error"
				// imageSrc={}
				message={DEFAULT_ERROR_MESSAGE}
			/>
		)}
	</div>
);

const mapStateToProps = (state) => {
	return {
		isSuccessMessage: state.toast.isSuccessMessage,
		isErrorMessage: state.toast.isErrorMessage,
		message: state.toast.message,
		isDefaultErrorMessage: state.toast.isDefaultErrorMessage,
		isWarningMessage: state.toast.isWarningMessage,
	};
};

Toast.propTypes = {
	message: PropTypes.string.isRequired,
	isSuccessMessage: PropTypes.bool,
	isErrorMessage: PropTypes.bool,
	isDefaultErrorMessage: PropTypes.bool,
	isWarningMessage: PropTypes.bool
};

export default connect(mapStateToProps)(Toast);
