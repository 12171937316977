import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import FacebookLoader from "../../Ui/FacebookLoader";
import logoutIcon from "../../../assets/images/Logout.svg";
import avatarBackground from "../../../assets/images/avatar-bg.svg";
import avatar from "../../../assets/images/no_image_user.png";
import phoneIcon from "../../../assets/images/phone.svg";
import emailIcon from "../../../assets/images/email.svg";
import addressIcon from "../../../assets/images/address.svg";
import dateIcon from "../../../assets/images/date.svg";
import instituteIcon from "../../../assets/images/institute.svg";
import masterCardImage from "../../../assets/images/master-card.png";
import editPaymentIcon from "../../../assets/images/edit-payment.svg";
import popupCloseIcon from "../../../assets/images/popup-close.svg";
import visaImage from "../../../assets/images/visa.png";
import { utils } from "../../../utils";

const ProfileDetailsPage = ({
	auth: { isFetchResidentProfileDetailsPending, residentProfileDetails },
	...rest
}) => {
	useEffect(() => {
		rest.fetchResidentProfileDetails();
	}, []);

	return isFetchResidentProfileDetailsPending ? (
		<FacebookLoader />
	) : (
		<Fragment>
			<div className="container-fluid">
				<div className="title-bar d-flex justify-content-between align-items-center">
					<div className="font-45 dblue d-bold">
						Hello{" "}
						{residentProfileDetails.preferred_name ||
							residentProfileDetails.first_name}
					</div>
					<div className="profile-pages d-flex">
						<Link
							to="/resident/profile/edit-password"
							className="white blue-bg curved-btn bold font-18"
						>
							Edit Password
						</Link>
						<div className="logout-btn ml-3">
							<Link className="curved-btn red-bg white" onClick={rest.logout}>
								<img src={logoutIcon} alt="logout_icon" />
								Logout
							</Link>
						</div>
					</div>
				</div>
			</div>

			<div className="resident-profile resident_list pt-40">
				<div className="container-fluid">
					<div className="resident-information d-flex align-content-center">
						<div className="resident-photo">
							<div className="resident_photo position-relative">
								<div className="avatar__bg position-absolute">
									<img src={avatarBackground} alt="avatarBackground" />
								</div>
								<div className="avatar_image position-relative">
									<img
										src={residentProfileDetails.image || avatar}
										alt="profile_image"
									/>
								</div>
							</div>
						</div>
						<div className="resident-name">
							{residentProfileDetails.fullname}
						</div>
						<div className="resident__details">
							<div className="resident-details d-flex align-items-center">
								<div className="detail-div d-flex align-items-center">
									<div className="detail-icon">
										<img src={emailIcon} alt="email_icon" />
									</div>
									<div className="detail-detail font-20">
										<a href={`mailto:${residentProfileDetails.email}`}>
											{residentProfileDetails.email}
										</a>
									</div>
								</div>
								<div className="detail-div d-flex align-items-center">
									<div className="detail-icon">
										<img src={dateIcon} alt="date_icon" />
									</div>
									<div className="detail-detail font-20">
										{residentProfileDetails.dob}
									</div>
								</div>
								<div className="detail-div d-flex align-items-center">
									<div className="detail-icon">
										<img src={instituteIcon} alt="institute_icon" />
									</div>
									<div className="detail-detail font-20">
										{residentProfileDetails.education ||
											residentProfileDetails.organizationname}
									</div>
								</div>
								<div className="detail-div d-flex align-items-center">
									<div className="detail-icon">
										<img src={phoneIcon} alt="phone_icon" />
									</div>
									<div className="detail-detail font-20">
										{residentProfileDetails.phone_number && (
											<a
												href={
													"tel:+" +
													residentProfileDetails.phone_country_code +
													residentProfileDetails.phone_area_code +
													residentProfileDetails.phone_number
												}
											>
												{/* {utils.formatPhoneNumber(residentProfileDetails.phone)} */}
												{"+"+residentProfileDetails.phone_country_code +
													" " +
													residentProfileDetails.phone_area_code +
													" " +
													residentProfileDetails.phone_number}
											</a>
										)}
									</div>
								</div>
								<div className="detail-div d-flex align-items-center">
									<div className="detail-icon">
										<img src={addressIcon} alt="address_icon" />
									</div>
									<div className="detail-detail font-20">
										{residentProfileDetails.postal_address_string}
									</div>
								</div>

								<div className="detail-div d-flex align-items-center">
									<div className="editinfo">
										<Link
											to="/resident/profile/edit-info"
											className="pink-bg curved-btn bold white font-18"
										>
											Edit Info
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="my-lease-data">
						<div className="font-28 d-bold blue pb-40">Emergency Contact</div>
						<div className="my__lease d-flex justify-content-start">
							<div className="lease_detail">
								<div className="bold">Name</div>
								<div className="regular">
									{residentProfileDetails.emergency_contact_name}
								</div>
							</div>
							<div className="lease_detail">
								<div className="bold">Relationship</div>
								<div className="regular">
									{residentProfileDetails.emergency_contact_relationship}
								</div>
							</div>
							<div className="lease_detail">
								<div className="bold">Phone</div>
								<div className="regular">
									<a
										href={
											"tel:+" +
											residentProfileDetails.emergency_contact_phone_country_code +
											residentProfileDetails.emergency_contact_phone_area_code +
											residentProfileDetails.emergency_contact_phone_number
										}
									>
										{"+" + residentProfileDetails.emergency_contact_phone_country_code +
											" " +
											residentProfileDetails.emergency_contact_phone_area_code +
											" " +
											residentProfileDetails.emergency_contact_phone_number}
									</a>
								</div>
							</div>
							<div className="lease_detail">
								<div className="bold">Address</div>
								<div className="regular">
									{residentProfileDetails.emergency_contact_address}
								</div>
							</div>
						</div>
					</div>

					<div className="my-lease-data">
						<div className="font-28 d-bold blue pb-40">My Lease details</div>
						<div className="my__lease d-flex justify-content-start">
							<div className="lease_detail">
								<div className="bold">Start</div>
								<div className="regular">
									{residentProfileDetails.startdate}
								</div>
							</div>
							<div className="lease_detail">
								<div className="bold">End</div>
								<div className="regular">{residentProfileDetails.end_date}</div>
							</div>
							<div className="lease_detail">
								<div className="bold">Fortnightly rent</div>
								<div className="regular">
									{residentProfileDetails.fortnightlyrent}
								</div>
							</div>
							<div className="lease_detail">
								<div className="bold">Room level</div>
								<div className="regular">
									{residentProfileDetails.room_level}
								</div>
							</div>
							<div className="lease_detail">
								<div className="bold">Room number</div>
								<div className="regular">{residentProfileDetails.room}</div>
							</div>
							<div className="lease_detail">
								<div className="bold">Number of residents per room</div>
								<div className="regular">
									{residentProfileDetails.room_resident_number}
								</div>
							</div>
						</div>
					</div>

					{/* <div className="tenant-payment-details">
						<div className="dblue d-bold font-28">Payment Details</div>
						<div className="row">
							<div className="col-md-10">
								<div className="card-details-list mt-4">
									<div className="card-details d-flex align-items-center justify-content-between">
										<div className="card-image">
											<img src={masterCardImage} alt="mastercard_image" />
										</div>
										<div className="saved-cards d-flex">
											<div className="card-nos d-flex align-items-center">
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos">1</span>
												<span className="nos">2</span>
												<span className="nos">3</span>
												<span className="nos">4</span>
											</div>
											<div className="ml-5">Expires 12/22</div>
										</div>
										<div className="edit-delete">
											<Link className="edit-card">
												<img src={editPaymentIcon} alt="edit_payment_icon" />
											</Link>
											<Link className="delete-card">
												<img src={popupCloseIcon} alt="cross_icon" />
											</Link>
										</div>
									</div>
									<div className="card-details d-flex align-items-center justify-content-between">
										<div className="card-image">
											<img src={visaImage} alt="visa_image" />
										</div>
										<div className="saved-cards d-flex">
											<div className="card-nos d-flex align-items-center">
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos"></span>
												<span className="nos">1</span>
												<span className="nos">2</span>
												<span className="nos">3</span>
												<span className="nos">4</span>
											</div>
											<div className="ml-5">Expires 12/22</div>
										</div>
										<div className="edit-delete">
											<Link className="edit-card">
												<img src={editPaymentIcon} alt="edit_payment_icon" />
											</Link>
											<Link className="delete-card">
												<img src={popupCloseIcon} alt="cross_icon" />
											</Link>
										</div>
									</div>

									<div className="add-card mt-5">
										<Link
											data-toggle="modal"
											data-target="#addpayment"
											className="purple-bg white font-18 bold curved-btn"
										>
											<span className="d-bold font-36 mr-2 d-inline-block">
												+
											</span>
											Add a new payment method
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div> */}

					<div className="note d-flex-column">
						<div className="note-details d-bold font-50">
							If you require to update your email address, please contact
						</div>
						<div className="note-link font-36">
							<a href="mailto:info@luxpod.com.au">info@luxpod.com.au</a>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ProfileDetailsPage;
