import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import ReactPusher from 'react-pusher';

import {
	ADMIN_ROUTES,
	RESIDENT_ROUTES,
	USER_TYPE_RESIDENT,
	USER_TYPE_ADMIN,
	USER_TYPE_BUILDING_MANAGER,
	BUILDING_MANAGER_ROUTES,
	LUXPOD_BASE_URL
} from "../../constants";
import logo from "../../assets/images/logo2.svg";
import { setNotificationsCount, setForceUpdate } from "../../actions/auth";

// import { setPusherClient } from "react-pusher";
import Pusher from "pusher-js";


// setPusherClient(pusherClient);
const Sidebar = ({
	auth: { userType, user, total_notifications },
	// issuesData: { unreadIssuesCount },
	// setIssuesBadgeCount,
	setNotificationsCount,
	setForceUpdate,
	rest,
}) => {
	const pusherClient = new Pusher("ef09b31f905def57a880", {
		cluster: "ap4",
		authEndpoint: LUXPOD_BASE_URL + "/pusher/auth",
	});

	pusherClient.connection.bind("error", function (err) {
		console.log(err);
		if (
			err &&
			err.error &&
			err.error.data &&
			err.error.data.code === 4004
		) {
			console.log("Over limit!");
		}
	});

	const updateBadge = (data) => {
		console.log(data);
		if (
			data &&
			(data.total_notifications || data.total_notifications === 0)
		) {
			setNotificationsCount(data.total_notifications);
			if (total_notifications == data.total_notifications) {
				setForceUpdate(true);
			}
		}
	};

	useEffect(() => {
		const channel = pusherClient.subscribe(`user.${user.id}`);
		channel.bind("issue_created", updateBadge);
		channel.bind("issue_updated", updateBadge);
		channel.bind("issue_viewed", updateBadge);

		return () => {
			pusherClient.unsubscribe(`user.${user.id}`);
			pusherClient.disconnect();
		};
	}, []);

	return (
		<Fragment>
			<div className="left-panel dblue-bg">
				<div className="logo">
					<Link to="/login">
						<img src={logo} alt="logo" />
					</Link>
				</div>

				<div className={`panel-menu ${userType.toLowerCase()}-user`}>
					{userType === USER_TYPE_ADMIN && (
						<ul className="list-unstyled">
							{ADMIN_ROUTES.map((adminRoute) => (
								<li
									className={
										rest.match.path == adminRoute.path
											? "active"
											: ""
									}
								>
									<Link to={adminRoute.path}>
										{adminRoute.label}
										{adminRoute.label ===
											ADMIN_ROUTES[3].label &&
											parseInt(total_notifications + "") >
												0 && (
												<span className="badge badge-danger red-bg">
													{total_notifications}
												</span>
											)}
										{/* <ReactPusher
											channel={`private-user.${user.id}`}
											event="issue_created"
											onUpdate={({ ...params }) =>
											{
												console.log(params);
												// alert("issue_created: "+params);
											}
											}
										/> */}
									</Link>
								</li>
							))}
						</ul>
					)}
					{userType === USER_TYPE_RESIDENT && (
						<ul className="list-unstyled">
							{RESIDENT_ROUTES.map((residentRoute) => (
								<li
									className={
										rest.match.path == residentRoute.path
											? "active"
											: ""
									}
								>
									<Link to={residentRoute.path}>
										{residentRoute.label}
										{residentRoute.label ===
											RESIDENT_ROUTES[2].label &&
											parseInt(total_notifications + "") >
												0 && (
												<span className="badge badge-danger red-bg">
													{total_notifications}
												</span>
											)}
									</Link>
								</li>
							))}
						</ul>
					)}
					{userType === USER_TYPE_BUILDING_MANAGER && (
						<ul className="list-unstyled">
							{BUILDING_MANAGER_ROUTES.map(
								(buildingManagerRoute) => (
									<li
										className={
											rest.match.path ==
											buildingManagerRoute.path
												? "active"
												: ""
										}
									>
										<Link to={buildingManagerRoute.path}>
											{buildingManagerRoute.label}
											{buildingManagerRoute.label ===
												BUILDING_MANAGER_ROUTES[3]
													.label &&
												parseInt(
													total_notifications + ""
												) > 0 && (
													<span className="badge badge-danger red-bg">
														{total_notifications}
													</span>
												)}
										</Link>
									</li>
								)
							)}
						</ul>
					)}
				</div>
			</div>
		</Fragment>
	);
};

Sidebar.propTypes = {
	auth: PropTypes.object.isRequired,
	// issuesData: PropTypes.object.isRequired,
	setNotificationsCount: PropTypes.func.isRequired,
	setForceUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
//   issuesData: state.issues,
});

export default connect(mapStateToProps, {
	setNotificationsCount,
	setForceUpdate,
})(Sidebar);
