import React, { Fragment } from "react";

import popupClose from "../../assets/images/popup-close.svg";
import { utils } from "../../utils";

const DeletePostModal = ({ post, deletePost, closeModal, ...rest }) => {
    return (
		<Fragment>
			<span class="modal-close" data-dismiss="modal" onClick={closeModal}>
				<img src={popupClose} alt="close" />
			</span>
			<div class="modal-body">
				<div class="font-45 dblue d-bold mb-4 text-center">
					Are You Sure You Want To Delete This Post From Your
					Noticeboard?
				</div>
				<div class="delete-post post_details d-flex">
					{post.images && post.images.length > 0 && (
						<div class="post-image">
							<div class="post__image">
								<img
									width="100%"
									src={post.images[0].image}
									alt="post_image"
								/>
							</div>
						</div>
					)}
					<div class="post__details">
						<div class="post_title font-14 bold mb-3">
							{post.title}
						</div>
						<div class="post_jist font-13">
							{utils.clipTextWithEllipsis(post.details, 165)}
						</div>
						<div class="post_time font-11">{post.time_ago}</div>
					</div>
				</div>
				<div class="delete-ctas">
					<button
						type="submit"
						onClick={() => deletePost(post.id)}
						class="curved-btn red-bg"
					>
						Delete
					</button>
					<button
						type="button"
						onClick={closeModal}
						class="curved-btn red-border"
					>
						Cancel
					</button>
				</div>
			</div>
		</Fragment>
	);
};

export default DeletePostModal;
