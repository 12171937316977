import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";

import Datepicker from "../../Ui/Datepicker";
// import ReactDatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { utils } from "../../../utils";
import ValidationMessage from "../../Ui/ValidationMessage";
import {
	OCCUPATIONS,
	LEVELS,
	ALLOWED_OCCUPANTS_PER_ROOM,
	ALLOWED_ATTACHMENT_DOCUMENTS_EXTENSIONS,
	EMAIL_PATTERN,
	PHONE_NUMBER_PATTERN,
	OCCUPATION_TYPE_STUDENT,
	OCCUPATION_TYPE_PROFESSIONAL,
	NUMBER_PATTERN,
	PHONE_COUNTRY_CODE_MAX_LENGTH,
	PHONE_AREA_CODE_MAX_LENGTH,
	PHONE_NUMBER_MAX_LENGTH,
} from "../../../constants";
import leftBlueArrow from "../../../assets/images/left-blue-arrow.svg";
import man from "../../../assets/images/tenant-man.svg";
import girl from "../../../assets/images/tenant-girl.svg";
import addMedia from "../../../assets/images/add-media2.svg";
import uploadIcon from "../../../assets/images/upload.svg";
import documentIcon from "../../../assets/images/document.svg";
import LuxpodSuccessMessage from "../../Ui/LuxpodSuccessMessage";

const AdminAddUpdateResidentPage = ({
	courseTypes,
	fetchCourseTypes,
	saveResident,
	deleteDocument,
	resident,
	isSaveResidentSuccessful,
	setSaveResidentStatus,
	fetchDefaultPostalAddress,
	defaultPostalAddress,
	...rest
}) => {
	const [isEdit, setIsEdit] = useState(resident && resident.id ? true : false);

	const goBack = (e) => {
		if (e) {
			e.preventDefault();
		}
		window.history.go(-1);
	};

	useEffect(() => {
		fetchCourseTypes();
		if (isEdit) {
			setValue(resident.postal_address_string, false);
			let postalAddress = {
				line1: resident.postal_address_line1,
				line2: resident.postal_address_line2,
				line3: resident.postal_address_line3,
				line4: resident.postal_address_line4,
				city: resident.postal_address_city,
				postalCode: resident.postal_address_postal_code,
				country: resident.postal_address_country,
			};
			setPostalAddress(postalAddress);
			setCurrentAddressAutoCompleteValues({
				currentAddressLine1: resident.current_address_line1,
				currentAddressLine2: resident.current_address_line2,
				currentAddressLine3: resident.current_address_line3,
				currentAddressLine4: resident.current_address_line4,
				currentAddressCity: resident.current_address_city,
				currentAddressPostalCode: resident.current_address_postal_code,
				currentAddressCountry: resident.current_address_country,
			});
			currentAddressAutoCompleteObj.setValue(resident.current_address_line1, false);
		} else {
			fetchDefaultPostalAddress();
		}
	}, []);

	useEffect(() => {
		return () => {
			setSaveResidentStatus(false);
		};
	}, []);

	const history = useHistory();
	const goBackToResidentsList = () => {
		setSaveResidentStatus(false);
		history.push("/admin/residents");
	};
	// useEffect(() => {
	// 	if (isSaveResidentSuccessful) {
	// 		setSaveResidentStatus(false);
	// 		goBack();
	// 	}
	// }, [isSaveResidentSuccessful]);

	const getCurrentDropDownObject = (options, value) => {
		return (
			options.find((occupation) => occupation.value == value) || options[0]
		);
	};
	const [occupation, setOccupation] = useState(
		isEdit
			? getCurrentDropDownObject(OCCUPATIONS, resident.occupation_type)
			: OCCUPATIONS[0]
	);
	const [courseType, setCourseType] = useState(
		// isEdit ? courseTypes[resident.courseType - 1] :
		{}
	);
	useEffect(() => {
		if (courseTypes.length > 0) {
			if (isEdit) {
				setCourseType(
					courseTypes[
						resident.courses_type_id === 0 ? 0 : resident.courses_type_id
					]
				);
			} else {
				setCourseType(courseTypes[0]);
			}
		} else {
			setCourseType({});
		}
	}, [courseTypes]);
	// const [selectedPreferredLevel, setSelectedPreferredLevel] = useState(
	// 	isEdit
	// 		? getCurrentDropDownObject(LEVELS, resident.preferredlevel)
	// 		: LEVELS[0]
	// );
	const [selectedRoomLevel, setSelectedRoomLevel] = useState(
		isEdit ? getCurrentDropDownObject(LEVELS, resident.room_level) : LEVELS[0]
	);
	const [occupantsPerRoom, setOccupantsPerRoom] = useState(
		isEdit
			? getCurrentDropDownObject(
					ALLOWED_OCCUPANTS_PER_ROOM,
					resident.room_resident_number
			  )
			: ALLOWED_OCCUPANTS_PER_ROOM[0]
	);

	const [isOccupationDropdownOpen, setIsOccupationDropdownOpen] = useState(
		false
	);
	const [isCourseTypeDropdownOpen, setIsCourseTypeDropdownOpen] = useState(
		false
	);
	const [
		isSelectPreferredLevelDropdownOpen,
		setIsSelectPreferredLevelDropdownOpen,
	] = useState(false);
	const [isRoomLevelDropdownOpen, setIsRoomLevelDropdownOpen] = useState(false);
	const [isOccupantsDropdownOpen, setIsOccupantsDropdownOpen] = useState(false);

	const initialformErrors = {
		firstName: "",
		lastName: "",
		email: "",
		phone: "",
		phoneCountryCode: "",
		phoneAreaCode: "",
		phoneNumber: "",
		dob: "",
		occupation: "",
		courseType: "",
		educationalInstitution: "",
		organizationName: "",
		postalAddressString: "",
		currentAddressLine1: "",
		currentAddressLine2: "",
		// currentAddressLine3: "",
		// currentAddressLine4: "",
		currentAddressCity: "",
		currentAddressPostalCode: "",
		currentAddressCountry: "",
		emergencyContactName: "",
		emergencyContactRelationship: "",
		emergencyContactPhone: "",
		emergencyContactPhoneCountryCode: "",
		emergencyContactPhoneAreaCode: "",
		emergencyContactPhoneNumber: "",
		emergencyContactAddress: "",
		// leaseStartDate: "",
		// leaseEndDate: "",
		startDate: "",
		endDate: "",
		deposit: "",
		bond: "",
		fortnightlyRentAmount: "",
		roomNumber: "",
		// documentsUpload: "",
	};

	const removeDollarSign = (amount) => {
		return amount.substring(0, 1) == "$" ? amount.substring(1) : amount;
	};

	const calculateLeasePeriod = (startDate, endDate) => {
		let startDateObj = utils.getDateObjectFromDDMMYYYY(startDate);
		let endDateObj = utils.getDateObjectFromDDMMYYYY(endDate);
		const diffInMilliSeconds = (endDateObj - startDateObj);
		const diffInDays = diffInMilliSeconds / 1000 / 60 / 60 / 24;
		if (175 < diffInDays && diffInDays < 185) {
			return "6";
		} else if (355 < diffInDays && diffInDays < 370) {
			return "12";
		} else {
			return "";
		}
	};

	const initialFormData = {
		firstName: isEdit ? resident.first_name : "",
		lastName: isEdit ? resident.last_name : "",
		preferredName: isEdit ? resident.preferred_name : "",
		email: isEdit ? resident.email : "",
		// phone: isEdit
		// 	? "" +
		// 	  resident.phone_country_code +
		// 	  resident.phone_area_code +
		// 	  resident.phone_number
		// 	: "",
		phoneCountryCode: isEdit ? resident.phone_country_code : "",
		phoneAreaCode: isEdit ? resident.phone_area_code : "",
		phoneNumber: isEdit ? resident.phone_number : "",
		dob: isEdit ? utils.getDateObjectFromDDMMYYYY(resident.dob) : "",
		educationalInstitution: isEdit ? resident.education : "",
		organizationName: isEdit ? resident.organizationname : "",
		// address: isEdit ? resident.address : "",
		emergencyContactName: isEdit ? resident.emergency_contact_name : "",
		emergencyContactRelationship: isEdit
			? resident.emergency_contact_relationship
			: "",
		// emergencyContactPhone: isEdit
		// 	? "" +
		// 	  resident.emergency_contact_phone_country_code +
		// 	  resident.emergency_contact_phone_area_code +
		// 	  resident.emergency_contact_phone_number
		// 	: "",
		emergencyContactPhoneCountryCode: isEdit ? resident.emergency_contact_phone_country_code : "",
		emergencyContactPhoneAreaCode: isEdit ? resident.emergency_contact_phone_area_code : "",
		emergencyContactPhoneNumber: isEdit ? resident.emergency_contact_phone_number : "",
		emergencyContactAddress: isEdit ? resident.emergency_contact_address : "",
		// leaseStartDate: isEdit ? new Date(resident.leasestartdate) : "",
		// leaseEndDate: isEdit ? new Date(resident.leaseenddate) : "",
		startDate: isEdit
			? utils.getDateObjectFromDDMMYYYY(resident.startdate)
			: "",
		leasePeriod: isEdit ? calculateLeasePeriod(resident.startdate, resident.enddate) : "6",
		endDate: isEdit ? utils.getDateObjectFromDDMMYYYY(resident.enddate) : "",
		deposit: isEdit ? removeDollarSign(resident.deposit) : "",
		bond: isEdit ? removeDollarSign(resident.bond) : "",
		fortnightlyRentAmount: isEdit
			? removeDollarSign(resident.fortnightlyrent)
			: "",
		roomNumber: isEdit ? resident.room + "" : "",
		formErrors: initialformErrors,
	};
	const [formData, setFormData] = useState(initialFormData);
	const [formErrors, setFormErrors] = useState(initialformErrors);
	// const { formErrors } = formData;
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	const handleTextInputChange = (e) => {
		if (
			e.target.name == "phoneCountryCode" ||
			e.target.name == "phoneAreaCode" ||
			e.target.name == "phoneNumber" ||
			e.target.name == "emergencyContactPhoneCountryCode" ||
			e.target.name == "emergencyContactPhoneAreaCode" ||
			e.target.name == "emergencyContactPhoneNumber" ||
			e.target.name == "deposit" ||
			e.target.name == "bond" ||
			e.target.name == "fortnightlyRentAmount" ||
			e.target.name == "roomNumber"
		) {
			if (/^\d*$/.test(e.target.value)) {
				setFormData({
					...formData,
					[e.target.name]: e.target.value,
				});
			}
		} else {
			setFormData({
				...formData,
				[e.target.name]: e.target.value,
			});
		}
	};

	const toggleSelect = (e) => {
		switch (e.currentTarget.id) {
			case "occupation":
				setIsOccupationDropdownOpen(!isOccupationDropdownOpen);
				break;
			case "courseType":
				setIsCourseTypeDropdownOpen(!isCourseTypeDropdownOpen);
				break;
			case "selectPreferredLevel":
				setIsSelectPreferredLevelDropdownOpen(
					!isSelectPreferredLevelDropdownOpen
				);
				break;
			case "roomLevel":
				setIsRoomLevelDropdownOpen(!isRoomLevelDropdownOpen);
				break;
			case "occupants":
				setIsOccupantsDropdownOpen(!isOccupantsDropdownOpen);
				break;
			default:
				break;
		}
	};

	const handleDatepickerChange = (date, field) => {
		setFormData({
			...formData,
			[field]: date,
		});
	};

	const [counterForDocument, setCounterForDocument] = useState(0);
	const getCurrentDocuments = () => {
		const currentDocuments = [];
		resident.documents.forEach((document) => {
			currentDocuments.push({
				id: document.id,
				name: document.file_name,
				fileUrl: document.file_url,
			});
		});
		return currentDocuments;
	};
	const [documents, setDocuments] = useState(
		isEdit ? getCurrentDocuments() : []
	);
	const [documentsToBeDeleted, setDocumentsToBeDeleted] = useState([]);

	const handleFileUpload = (e) => {
		let validFiles = [];
		const files = [...e.target.files];
		files.forEach((file) => {
			const extension = file.name.split(".").pop();
			if (ALLOWED_ATTACHMENT_DOCUMENTS_EXTENSIONS.includes(extension)) {
				let validFile = {
					index: counterForDocument,
					name: file.name,
					file: file,
					fileUrl: "",
				};
				setCounterForDocument(counterForDocument + 1);
				validFiles.push(validFile);
			} else {
				// showErrorMessage("Please upload PDF files only.");
			}
		});
		setDocuments((documents) => [...documents, ...validFiles]);
	};

	const removeDocument = ({ id, index }) => {
		if (id) {
			setDocuments(documents.filter((document) => document.id != id));
			setDocumentsToBeDeleted([
				...documentsToBeDeleted,
				documents.find((document) => document.id == id),
			]);
		}
		if (index || index === 0) {
			setDocuments(documents.filter((document) => document.index !== index));
		}
	};
	// useEffect(() => {
	// 	console.log("documentsToBeDeleted ", documentsToBeDeleted);
	// },[documentsToBeDeleted]);

	const isFormValid = () => {
		let updatedFormErrors = formErrors;
		let isFormValid = true;
		Object.keys(formErrors).forEach((field) => {
			switch (field) {
				case "firstName":
					if (!formData.firstName) {
						updatedFormErrors[field] = "Please enter the resident's first name";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "lastName":
					if (!formData.lastName) {
						updatedFormErrors[field] = "Please enter the resident's last name";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "email":
					if (!formData.email) {
						updatedFormErrors[field] = "Please enter the resident's email";
						isFormValid = false;
					} else if (!formData.email.match(EMAIL_PATTERN)) {
						updatedFormErrors[field] = "Please enter a valid email address";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				// case "phone":
				// 	if (!formData.phone) {
				// 		updatedFormErrors[field] =
				// 			"Please enter the resident's phone number";
				// 		isFormValid = false;
				// 	} else if (!formData.phone.match(PHONE_NUMBER_PATTERN)) {
				// 		updatedFormErrors[field] =
				// 			"Please enter a valid (10-digit numeric) phone number";
				// 		isFormValid = false;
				// 	} else {
				// 		updatedFormErrors[field] = "";
				// 	}
				// 	break;

				// Check data from split fields but error message will be singular for 'phone'
				case "phoneCountryCode":
				case "phoneAreaCode":
				case "phoneNumber":
					if (
						// !formData.phoneCountryCode &&
						// !formData.phoneAreaCode &&
						!formData.phoneNumber
					) {
						updatedFormErrors["phone"] =
							"Please enter the resident's phone number";
						isFormValid = false;
					} else if (
						!("" + formData.phoneCountryCode).match(/^[0-9]{1,3}$|^$/) // ||
						// !("" + formData.phoneAreaCode).match(/^[0-9]{1-3}$/) ||
						// !("" + formData.phoneNumber).match(/^[0-9]{8-10}$/)
					) {
						updatedFormErrors["phone"] =
							"Please enter a valid (1 to 3 digits long) country code";
						isFormValid = false;
					} else if (!("" + formData.phoneAreaCode).match(/^[0-9]{1,3}$|^$/)) {
						updatedFormErrors["phone"] =
							"Please enter a valid (1 to 3 digits long) area code";
						isFormValid = false;
					} else if (!("" + formData.phoneNumber).match(/^[0-9]{8,10}$/)) {
						updatedFormErrors["phone"] =
							"Please enter a valid (8 to 10 digits long) local number";
						isFormValid = false;
					} else {
						updatedFormErrors["phone"] = "";
					}
					break;
				case "occupation":
					if (!occupation.value) {
						updatedFormErrors["occupation"] =
							"Please select the resident's occupation";
						isFormValid = false;
					} else {
						updatedFormErrors["occupation"] = "";
					}
					break;
				case "courseType":
					if (
						occupation.value == OCCUPATION_TYPE_STUDENT &&
						!courseType.value
					) {
						updatedFormErrors["courseType"] =
							"Please select the resident's course type";
						isFormValid = false;
					} else {
						updatedFormErrors["courseType"] = "";
					}
					break;
				case "educationalInstitution":
					if (
						occupation.value == OCCUPATION_TYPE_STUDENT &&
						!formData.educationalInstitution
					) {
						updatedFormErrors[field] =
							"Please enter the resident's educational institution";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "organizationName":
					if (
						occupation.value == OCCUPATION_TYPE_PROFESSIONAL &&
						!formData.organizationName
					) {
						updatedFormErrors[field] =
							"Please enter the resident's company/business name";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "postalAddressString":
					if (!value) {
						updatedFormErrors[field] = "Please enter the postal address";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "currentAddressLine1":
					if (!currentAddressAutoCompleteObj.value) {
						updatedFormErrors[field] =
							"Please enter the resident's current address line 1";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "currentAddressLine2":
					if (!currentAddressAutoCompleteValues.currentAddressLine2) {
						updatedFormErrors[field] =
							"Please enter the resident's current address line 2";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				// case "currentAddressLine3":
				// 	if (!formData.address) {
				// 		updatedFormErrors[field] =
				// 			"Please enter the resident's current address line 3";
				// 		isFormValid = false;
				// 	} else {
				// 		updatedFormErrors[field] = "";
				// 	}
				// 	break;
				// case "currentAddressLine4":
				// 	if (!formData.address) {
				// 		updatedFormErrors[field] =
				// 			"Please enter the resident's current address line 4";
				// 		isFormValid = false;
				// 	} else {
				// 		updatedFormErrors[field] = "";
				// 	}
				// 	break;
				case "currentAddressCity":
					if (!currentAddressAutoCompleteValues.currentAddressCity) {
						updatedFormErrors[field] =
							"Please enter the resident's current address city";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "currentAddressPostalCode":
					if (!currentAddressAutoCompleteValues.currentAddressPostalCode) {
						updatedFormErrors[field] =
							"Please enter the resident's current address postal code";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "currentAddressCountry":
					if (!currentAddressAutoCompleteValues.currentAddressCountry) {
						updatedFormErrors[field] =
							"Please enter the resident's current address country";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "emergencyContactName":
					if (!formData.emergencyContactName) {
						updatedFormErrors[field] =
							"Please enter the resident's emergency contact's name";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "emergencyContactRelationship":
					if (!formData.emergencyContactRelationship) {
						updatedFormErrors[field] =
							"Please enter the resident's relationship with their emergency contact";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "emergencyContactPhoneCountryCode":
				case "emergencyContactPhoneAreaCode":
				case "emergencyContactPhoneNumber":
					if (
						// !formData.emergencyContactPhoneCountryCode &&
						// !formData.emergencyContactPhoneAreaCode &&
						!formData.emergencyContactPhoneNumber
					) {
						updatedFormErrors["emergencyContactPhone"] =
							"Please enter the resident's emergency contact's phone number";
						isFormValid = false;
					} else if (
						!("" + formData.emergencyContactPhoneCountryCode).match(
							/^[0-9]{1,3}$|^$/
						)
					) {
						updatedFormErrors["emergencyContactPhone"] =
							"Please enter a valid (1 to 3 digits long) country code";
						isFormValid = false;
					} else if (
						!("" + formData.emergencyContactPhoneAreaCode).match(
							/^[0-9]{1,3}$|^$/
						)
					) {
						updatedFormErrors["emergencyContactPhone"] =
							"Please enter a valid (1 to 3 digits long) area code";
						isFormValid = false;
					} else if (
						!("" + formData.emergencyContactPhoneNumber).match(/^[0-9]{8,10}$/)
					) {
						updatedFormErrors["emergencyContactPhone"] =
							"Please enter a valid (8 to 10 digits long) local number";
						isFormValid = false;
					} else {
						updatedFormErrors["emergencyContactPhone"] = "";
					}
					break;
				case "emergencyContactAddress":
					if (!formData.emergencyContactAddress) {
						updatedFormErrors[field] =
							"Please enter the resident's emergency contact's address";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "dob":
					if (!formData.dob) {
						updatedFormErrors[field] =
							"Please enter the resident's date of birth";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				// case "leaseStartDate":
				// 	if (!formData.leaseStartDate) {
				// 		updatedFormErrors[field] =
				// 			"Please enter the resident's lease start date";
				// 		isFormValid = false;
				// 	} else {
				// 		updatedFormErrors[field] = "";
				// 	}
				// 	break;
				// case "leaseEndDate":
				// 	if (!formData.leaseEndDate) {
				// 		updatedFormErrors[field] =
				// 			"Please enter the resident's lease end date";
				// 		isFormValid = false;
				// 	} else {
				// 		updatedFormErrors[field] = "";
				// 	}
				// 	break;
				case "startDate":
					if (!formData.startDate) {
						updatedFormErrors[field] = "Please enter start date";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "endDate":
					if (!formData.endDate) {
						updatedFormErrors[field] = "Please enter end date";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "deposit":
					if (!formData.deposit) {
						updatedFormErrors[field] = "Please enter deposit amount";
						isFormValid = false;
					} else if (!formData.deposit.match(NUMBER_PATTERN)) {
						updatedFormErrors[field] = "Please enter a numeric value";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "bond":
					if (!formData.bond) {
						updatedFormErrors[field] = "Please enter bond amount";
						isFormValid = false;
					} else if (!formData.bond.match(NUMBER_PATTERN)) {
						updatedFormErrors[field] = "Please enter a numeric value";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "fortnightlyRentAmount":
					if (!formData.fortnightlyRentAmount) {
						updatedFormErrors[field] = "Please enter fortnightly rent amount";
						isFormValid = false;
					} else if (!formData.fortnightlyRentAmount.match(NUMBER_PATTERN)) {
						updatedFormErrors[field] = "Please enter a numeric value";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "roomNumber":
					if (!formData.roomNumber) {
						updatedFormErrors[field] =
							"Please enter the resident's room number";
						isFormValid = false;
					} else if (!formData.roomNumber.match(NUMBER_PATTERN)) {
						updatedFormErrors[field] = "Please enter a numeric value";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				default:
					break;
			}
		});
		// if (!(documents.length > 0)) {
		// 	updatedFormErrors["documentsUpload"] = "Please upload documents";
		// 	isFormValid = false;
		// } else {
		// 	updatedFormErrors["documentsUpload"] = "";
		// }
		if (isFormValid) {
			updatedFormErrors = initialformErrors;
		}
		setFormErrors({ ...formErrors, ...updatedFormErrors });
		return isFormValid;
	};

	const createResidentData = () => {
		var data = new FormData();
		if (isEdit) {
			data.append("id", resident.id);
		}
		data.append("first_name", formData.firstName);
		data.append("last_name", formData.lastName);
		data.append("preferred_name", formData.preferredName);
		// if (!isEdit) {
			data.append("email", formData.email);
		// }
		data.append("phone_country_code", formData.phoneCountryCode);
		data.append("phone_area_code", formData.phoneAreaCode);
		data.append("phone_number", formData.phoneNumber);
		data.append("dob", utils.formatDateDDMMYYYY(formData.dob));
		data.append("occupation_type", occupation.value);
		data.append("education", formData.educationalInstitution);
		data.append("organizationname", formData.organizationName);
		// data.append("address", formData.address);
		data.append("postal_address_string", value);
		data.append("postal_address_line1", postalAddress.line1);
		data.append("postal_address_line2", postalAddress.line2);
		data.append("postal_address_line3", postalAddress.line3);
		data.append("postal_address_line4", postalAddress.line4);
		data.append("postal_address_city", postalAddress.city);
		data.append("postal_address_postal_code", postalAddress.postalCode);
		data.append("postal_address_country", postalAddress.country);
		data.append("current_address_line1", currentAddressAutoCompleteValues.currentAddressLine1 || currentAddressAutoCompleteObj.value);
		data.append("current_address_line2", currentAddressAutoCompleteValues.currentAddressLine2);
		data.append("current_address_line3", currentAddressAutoCompleteValues.currentAddressLine3);
		data.append("current_address_line4", currentAddressAutoCompleteValues.currentAddressLine4);
		data.append("current_address_city", currentAddressAutoCompleteValues.currentAddressCity);
		data.append("current_address_postal_code", currentAddressAutoCompleteValues.currentAddressPostalCode);
		data.append(
			"current_address_country",
			currentAddressAutoCompleteValues.currentAddressCountry
		);
		data.append(
			"emergency_contact_phone_country_code",
			formData.emergencyContactPhoneCountryCode
		);
		data.append(
			"emergency_contact_phone_area_code",
			formData.emergencyContactPhoneAreaCode
		);
		data.append(
			"emergency_contact_phone_number",
			formData.emergencyContactPhoneNumber
		);
		data.append("emergency_contact_name", formData.emergencyContactName);
		data.append(
			"emergency_contact_relationship",
			formData.emergencyContactRelationship
		);
		data.append("emergency_contact_address", formData.emergencyContactAddress);

		data.append("courses_type_id", courseType.id);
		// data.append(
		// 	"leasestartdate",
		// 	utils.formatDateDDMMYYYY(formData.leaseStartDate)
		// );
		// data.append(
		// 	"leaseenddate",
		// 	utils.formatDateDDMMYYYY(formData.leaseEndDate)
		// );
		// data.append("preferredlevel", selectedPreferredLevel.value);
		data.append("startdate", utils.formatDateDDMMYYYY(formData.startDate));
		data.append("enddate", utils.formatDateDDMMYYYY(formData.endDate));
		data.append("deposit", formData.deposit);
		data.append("bond", formData.bond);
		data.append("fortnightlyrent", formData.fortnightlyRentAmount);
		data.append("room_level", selectedRoomLevel.value);
		data.append("room_number", formData.roomNumber);
		data.append("room_resident_number", occupantsPerRoom.value);
		if (documents && documents.length > 0) {
			documents.forEach((document) => {
				if (document.file) {
					data.append("documents[]", document.file, document.name);
				}
			});
		}
		for (let pair of data.entries()) {
			console.log(pair[0] + ", " + pair[1]);
		}
		return data;
	};

	const handleSubmit = (e, path) => {
		e.preventDefault();
		setIsFormSubmitted(true);
		if (isFormValid()) {
			// createResidentData();
			saveResident(path, createResidentData());
			if (documentsToBeDeleted.length > 0) {
				documentsToBeDeleted.forEach((document) => {
					deleteDocument(document.id);
				});
			}
		}
	};

	const [postalAddress, setPostalAddress] = useState({
		line1: "",
		line2: "",
		line3: "",
		line4: "",
		city: "",
		postalCode: "",
		country: "",
	});
	const {
		ready,
		value,
		suggestions: { status, data },
		setValue,
		clearSuggestions,
	} = usePlacesAutocomplete();

	useEffect(() => {
		if (!isEdit && defaultPostalAddress) {
			setValue(defaultPostalAddress.postal_address_string, false);
			let postalAddress = {
				line1: defaultPostalAddress.postal_address_line1,
				line2: defaultPostalAddress.postal_address_line2,
				line3: defaultPostalAddress.postal_address_line3,
				line4: defaultPostalAddress.postal_address_line4,
				city: defaultPostalAddress.postal_address_city,
				postalCode: defaultPostalAddress.postal_address_postal_code,
				country: defaultPostalAddress.postal_address_country,
			};
			setPostalAddress(postalAddress);
		}
	}, [defaultPostalAddress]);
	// useEffect(() => {
	// 	console.log("postalAddress", postalAddress);
	// }, [postalAddress]);

	const handleAutoCompleteInput = (e) => {
		// setValue sets the input keyword AND asks for autocomplete suggestions
		// from the API
		setValue(e.target.value);
	};

	const handleSelect = (dataObj) => () => {
		// setValue() with false as second parameter only sets the input
		// keywords WITHOUT asking for suggestions from API
		setValue(dataObj.description, false);
		// console.log("dataObj", dataObj);
		clearSuggestions();
		getDetails(dataObj)
			.then((details) => {
				let addressComponents = details.address_components;
				let address = {
					line1: "",
					line2: "",
					line3: "",
					line4: "",
					city: "",
					postalCode: "",
					country: "",
				};
				addressComponents.forEach((addressComponent) => {
					if (
						addressComponent.types.includes("subpremise") ||
						addressComponent.types.includes("premise") ||
						addressComponent.types.includes("street_number")
					) {
						address.line1 += ", " + addressComponent.long_name;
						address.line1 = address.line1.replace(/^,\s/, "");
					}
					if (addressComponent.types.includes("route")) {
						address.line2 = addressComponent.long_name;
					}
					if (
						addressComponent.types.includes("neighborhood") ||
						addressComponent.types.includes("sublocality_level_2")
					) {
						address.line3 += ", " + addressComponent.long_name;
						address.line3 = address.line3.replace(/^,\s/, "");
					}
					if (addressComponent.types.includes("sublocality_level_1")) {
						address.line4 = addressComponent.long_name;
					}
					if (addressComponent.types.includes("locality")) {
						address.city = addressComponent.long_name;
					}
					if (addressComponent.types.includes("postal_code")) {
						address.postalCode = addressComponent.long_name;
					}
					if (addressComponent.types.includes("country")) {
						address.country = addressComponent.long_name;
					}
				});
				console.log("Formatted Postal Address", address);
				setPostalAddress(address);
			})
			.catch((error) => {
				console.log("Error: ", error);
			});
	};

	const renderSuggestions = () =>
		data.map((suggestion) => {
			const {
				id,
				structured_formatting: { main_text, secondary_text },
			} = suggestion;

			return (
				<li key={id} onClick={handleSelect(suggestion)}>
					<strong>{main_text}</strong> <small>{secondary_text}</small>
				</li>
			);
		});

	const currentAddressAutoCompleteObj = usePlacesAutocomplete();
	// const currentAddressAutoCompleteValue = currentAddressAutoCompleteObj.value;
	const [
		currentAddressAutoCompleteValues,
		setCurrentAddressAutoCompleteValues,
	] = useState({
		currentAddressLine1: "",
		currentAddressLine2: "",
		currentAddressLine3: "",
		currentAddressLine4: "",
		currentAddressCity: "",
		currentAddressPostalCode: "",
		currentAddressCountry: "",
	});

	const handleCurrentAddressInputChange = (e) => {
		setCurrentAddressAutoCompleteValues({
			...currentAddressAutoCompleteValues,
			[e.target.name]: e.target.value,
		});
	};

	useEffect(() => {
		if (isFormSubmitted) {
			isFormValid();
		}
	}, [
		formData,
		occupation,
		courseType,
		// selectedPreferredLevel,
		selectedRoomLevel,
		occupantsPerRoom,
		documents,
		currentAddressAutoCompleteValues,
	]);

	const currentAddressAutoCompleteSuggestions =
		currentAddressAutoCompleteObj.suggestions;
	const currentAddressAutoCompleteReady = currentAddressAutoCompleteObj.ready;
	const clearCurrentAddressAutoCompleteSuggestions =
		currentAddressAutoCompleteObj.clearSuggestions;

	const handleCurrentAddressAutoCompleteInput = (e) => {
		currentAddressAutoCompleteObj.setValue(e.target.value);
	};

	const handleCurrentAddressAutoCompleteSelect = (dataObj) => {
		clearCurrentAddressAutoCompleteSuggestions();
		getDetails(dataObj)
			.then((details) => {
				let addressComponents = details.address_components;
				let address = {
					currentAddressLine1: "",
					currentAddressLine2: "",
					currentAddressLine3: "",
					currentAddressLine4: "",
					currentAddressCity: "",
					currentAddressPostalCode: "",
					currentAddressCountry: "",
				};
				addressComponents.forEach((addressComponent) => {
					if (
						addressComponent.types.includes("subpremise") ||
						addressComponent.types.includes("premise") ||
						addressComponent.types.includes("street_number")
					) {
						address.currentAddressLine1 += ", " + addressComponent.long_name;
						address.currentAddressLine1 = address.currentAddressLine1.replace(
							/^,\s/,
							""
						);
						currentAddressAutoCompleteObj.setValue(
							address.currentAddressLine1,
							false
						);
					}
					if (addressComponent.types.includes("route")) {
						address.currentAddressLine2 = addressComponent.long_name;
					}
					if (
						addressComponent.types.includes("neighborhood") ||
						addressComponent.types.includes("sublocality_level_2")
					) {
						address.currentAddressLine3 += ", " + addressComponent.long_name;
						address.currentAddressLine3 = address.currentAddressLine3.replace(
							/^,\s/,
							""
						);
					}
					if (addressComponent.types.includes("sublocality_level_1")) {
						address.currentAddressLine4 = addressComponent.long_name;
					}
					if (addressComponent.types.includes("locality")) {
						address.currentAddressCity = addressComponent.long_name;
					}
					if (addressComponent.types.includes("postal_code")) {
						address.currentAddressPostalCode = addressComponent.long_name;
					}
					if (addressComponent.types.includes("country")) {
						address.currentAddressCountry = addressComponent.long_name;
					}
				});
				console.log("Formatted Current Address", address);
				setCurrentAddressAutoCompleteValues(address);
			})
			.catch((error) => {
				console.log("Error: ", error);
			});
	};

	const setLeasePeriod = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const calculateEndDate = (startDate, period) => {
		let endDate = "";
		if (startDate && period) {
			endDate = new Date(startDate);
			const currentMonth = endDate.getMonth() + endDate.getFullYear() * 12;
			endDate.setMonth(endDate.getMonth() + parseInt(period));
			const diff = endDate.getMonth() + endDate.getFullYear() * 12 - currentMonth;
			if (diff != period) {
				endDate.setDate(0);
			} else {
				endDate.setDate(endDate.getDate() - 1);
			}
		}
		return endDate;
	};

	useEffect(() => {
		let endDate = calculateEndDate(formData.startDate, formData.leasePeriod);
		setFormData({
			...formData,
			endDate,
		});
	}, [formData.startDate, formData.leasePeriod]);

	const renderCurrentAddressSuggestions = () =>
		currentAddressAutoCompleteSuggestions.data.map((suggestion) => {
			const {
				id,
				structured_formatting: { main_text, secondary_text },
			} = suggestion;

			return (
				<li
					key={id}
					onClick={() => handleCurrentAddressAutoCompleteSelect(suggestion)}
				>
					<strong>{main_text}</strong> <small>{secondary_text}</small>
				</li>
			);
		});

	return (
		<Fragment>
			<div className={isEdit ? "edit-tenant" : "add-tenant"}>
				<div className="container-fluid h-100">
					<div className="title-bar d-flex justify-content-between align-items-center">
						<div className="font-45 dblue d-bold">
							{!isSaveResidentSuccessful && (
								<Link onClick={goBack}>
									<img src={leftBlueArrow} alt="go_back" />
								</Link>
							)}
							{isEdit ? "Edit Resident" : "Add A New Resident"}
						</div>
					</div>

					{isSaveResidentSuccessful ? (
						<LuxpodSuccessMessage
							message={
								isEdit
									? "Details Updated Successfully!"
									: "Resident profile has been successfully created and credentials sent via email."
							}
							goBackToDefaultScreen={goBackToResidentsList}
							returnTo="Residents list"
						/>
					) : (
						<div className="new-tenant-form pt-40 position-relative h-100">
							<div className="absolute-images">
								<img className="man" src={man} alt="artwork_man" />
								<img className="girl" src={girl} alt="artwork_girl" />
							</div>
							<div className="row">
								<div className="col-md-9 col-sm-12">
									<form
										onSubmit={(e) =>
											handleSubmit(
												e,
												isEdit ? "/editresident" : "/createresident"
											)
										}
									>
										<div className="row">
											{/* <div className="col-md-4 col-sm-12">
											<div className="font-18">Name</div>
											<div className="lp-fields">
												<input
													type="text"
													name="name"
													value={formData.name}
													onChange={
														handleTextInputChange
													}
												/>
												{isFormSubmitted &&
													formErrors.name && (
														<ValidationMessage id="nameHelp">
															{formErrors.name}
														</ValidationMessage>
													)}
											</div>
										</div> */}
											<h6 className="dblue d-bold col-md-12">
												<label>Personal Info</label>
											</h6>
											<InputField
												title="First Name"
												fieldName="firstName"
												formData={formData}
												handleTextInputChange={handleTextInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/>
											<InputField
												title="Last Name"
												fieldName="lastName"
												formData={formData}
												handleTextInputChange={handleTextInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/>
											<InputField
												title="Preferred Name"
												fieldName="preferredName"
												formData={formData}
												handleTextInputChange={handleTextInputChange}
											/>
											<div className="col-md-4 col-sm-12">
												<div className="font-18">Email</div>
												<div className="lp-fields">
													<input
														type="email"
														name="email"
														value={formData.email}
														onChange={handleTextInputChange}
														// disabled={isEdit}
													/>
													{isFormSubmitted && formErrors.email && (
														<ValidationMessage id="emailHelp">
															{formErrors.email}
														</ValidationMessage>
													)}
												</div>
											</div>
											<div className="col-md-4 col-sm-12">
												<div className="font-18">Mobile/Landline</div>
												<div className="lp-fields">
													<div className="phone-fields d-flex justify-content-between align-items-center">
														<input
															type="tel"
															name="phoneCountryCode"
															placeholder="Country"
															value={formData.phoneCountryCode}
															onChange={handleTextInputChange}
															maxLength={PHONE_COUNTRY_CODE_MAX_LENGTH}
														/>
														<input
															type="tel"
															name="phoneAreaCode"
															placeholder="Area Code"
															value={formData.phoneAreaCode}
															onChange={handleTextInputChange}
															maxLength={PHONE_AREA_CODE_MAX_LENGTH}
														/>
														<input
															type="tel"
															name="phoneNumber"
															placeholder="Phone Number"
															value={formData.phoneNumber}
															onChange={handleTextInputChange}
															maxLength={PHONE_NUMBER_MAX_LENGTH}
														/>
													</div>
													{isFormSubmitted && formErrors.phone && (
														<ValidationMessage id="phoneHelp">
															{formErrors.phone}
														</ValidationMessage>
													)}
												</div>
											</div>
											{/* <InputField
												title="Address"
												fieldName="address"
												formData={formData}
												handleTextInputChange={handleTextInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/> */}
											<div className="col-md-4 col-sm-12">
												<div className="font-18">Date of Birth</div>
												<div className="lp-fields">
													{/* <input
												type="date"
												placeholder=""
												name="dob"
												value={formData.dob}
												onChange={(e) =>
													handleDatepickerChange(
														e,
														"dob"
													)
												}
											/> */}
													{/* <Datepicker
													placeholderText="dd/mm/yyyy"
													dateFormat="dd/MM/yyyy"
													// minDate={subDays(new Date(), 5)}
													maxDate={new Date()}
													selectedDate={formData.dob}
													handleDatepickerChange={
														handleDatepickerChange
													}
													name="dob"
												/> */}
													<Datepicker
														placeholderText="dd/mm/yyyy"
														dateFormat="dd/MM/yyyy"
														minDate={null}
														maxDate={new Date()}
														selectedDate={formData.dob}
														handleDatepickerChange={handleDatepickerChange}
														name="dob"
													/>
													{isFormSubmitted && formErrors.dob && (
														<ValidationMessage id="dobHelp">
															{formErrors.dob}
														</ValidationMessage>
													)}
												</div>
											</div>
											<div className="col-md-4 col-sm-12">
												<div className="font-18">Occupation</div>
												<div className="lp-fields">
													<ul
														id="occupation"
														name="occupation"
														className={
															isOccupationDropdownOpen
																? "list-unstyled customselect show"
																: "list-unstyled customselect"
														}
														onClick={toggleSelect}
													>
														<li className="init">{occupation.label}</li>
														{/* <li data-value="value 1">
													Student
												</li>
												<li data-value="value 2">
													Professional
												</li> */}
														{OCCUPATIONS.map(
															(occupation, index) =>
																index > 0 && (
																	<li
																		id={occupation.value}
																		onClick={() => setOccupation(occupation)}
																	>
																		{occupation.label}
																	</li>
																)
														)}
													</ul>
													{isFormSubmitted && formErrors.occupation && (
														<ValidationMessage id={`occupationHelp`}>
															{formErrors.occupation}
														</ValidationMessage>
													)}
												</div>
											</div>
											{occupation.value == OCCUPATIONS[1].value && (
												<Fragment>
													{courseTypes.length > 0 && (
														<div className="col-md-4 col-sm-12">
															<div className="font-18">Course Type</div>
															<div className="lp-fields">
																<ul
																	id="courseType"
																	name="courseType"
																	className={
																		isCourseTypeDropdownOpen
																			? "list-unstyled customselect show"
																			: "list-unstyled customselect"
																	}
																	onClick={toggleSelect}
																>
																	{courseType && (
																		<li className="init">{courseType.label}</li>
																	)}
																	{courseTypes.map(
																		(courseType, index) =>
																			index > 0 && (
																				<li
																					id={courseType.value}
																					onClick={() =>
																						setCourseType(courseType)
																					}
																				>
																					{courseType.label}
																				</li>
																			)
																	)}
																</ul>
																{isFormSubmitted && formErrors.courseType && (
																	<ValidationMessage id={`courseTypeHelp`}>
																		{formErrors.courseType}
																	</ValidationMessage>
																)}
															</div>
														</div>
													)}
													<InputField
														title="Educational Institution"
														fieldName="educationalInstitution"
														formData={formData}
														handleTextInputChange={handleTextInputChange}
														isFormSubmitted={isFormSubmitted}
														formErrors={formErrors}
													/>
												</Fragment>
											)}
											{occupation.value == OCCUPATIONS[2].value && (
												<InputField
													title="Company/Business Name"
													fieldName="organizationName"
													formData={formData}
													handleTextInputChange={handleTextInputChange}
													isFormSubmitted={isFormSubmitted}
													formErrors={formErrors}
												/>
											)}
											{/* <InputField
												title="Postal Address"
												fieldName="postalAddress"
												formData={formData}
												handleTextInputChange={handleTextInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/> */}
											<div className="col-md-12 col-sm-12">
												<div className="font-18">Postal Address</div>
												<div className="lp-fields">
													<div>
														<input
															type="text"
															name="postalAddressString"
															value={value}
															onChange={handleAutoCompleteInput}
															disabled={!ready}
															autocomplete="off"
														/>
														{status === "OK" && (
															<ul className="autocomplete-suggestions">
																{renderSuggestions()}
															</ul>
														)}
													</div>
													{isFormSubmitted &&
														formErrors["postalAddressString"] && (
															<ValidationMessage
																id={`${"postalAddressString"}Help`}
															>
																{formErrors["postalAddressString"]}
															</ValidationMessage>
														)}
												</div>
											</div>

											{/* <InputField
												title="Current Address Line 1"
												fieldName="currentAddressLine1"
												formData={formData}
												handleTextInputChange={handleTextInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/> */}
											<div className="col-md-4 col-sm-12">
												<div className="font-18">Current Address Line 1</div>
												<div className="lp-fields">
													<div>
														<input
															type="text"
															name="currentAddressLine1"
															value={currentAddressAutoCompleteObj.value}
															onChange={handleCurrentAddressAutoCompleteInput}
															disabled={!currentAddressAutoCompleteReady}
															autocomplete="off"
														/>
														{currentAddressAutoCompleteSuggestions.status ===
															"OK" && (
															<ul className="autocomplete-suggestions">
																{renderCurrentAddressSuggestions()}
															</ul>
														)}
													</div>
													{isFormSubmitted &&
														formErrors["currentAddressLine1"] && (
															<ValidationMessage
																id={`${"currentAddressLine1"}Help`}
															>
																{formErrors["currentAddressLine1"]}
															</ValidationMessage>
														)}
												</div>
											</div>

											<InputField
												title="Current Address Line 2"
												fieldName="currentAddressLine2"
												formData={currentAddressAutoCompleteValues}
												handleTextInputChange={handleCurrentAddressInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/>
											<InputField
												title="Current Address Line 3"
												fieldName="currentAddressLine3"
												formData={currentAddressAutoCompleteValues}
												handleTextInputChange={handleCurrentAddressInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/>
											<InputField
												title="Current Address Line 4"
												fieldName="currentAddressLine4"
												formData={currentAddressAutoCompleteValues}
												handleTextInputChange={handleCurrentAddressInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/>
											<InputField
												title="City"
												fieldName="currentAddressCity"
												formData={currentAddressAutoCompleteValues}
												handleTextInputChange={handleCurrentAddressInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/>
											<InputField
												title="Postal Code"
												fieldName="currentAddressPostalCode"
												formData={currentAddressAutoCompleteValues}
												handleTextInputChange={handleCurrentAddressInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/>
											<InputField
												title="Country"
												fieldName="currentAddressCountry"
												formData={currentAddressAutoCompleteValues}
												handleTextInputChange={handleCurrentAddressInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/>
											{/* <div className="col-md-4 col-sm-12">
											<div className="font-18">
												Lease start date
											</div>
											<div className="lp-fields">
												{/* <input
												type="date"
												placeholder=""
												name="leaseStartDate"
												value={formData.leaseStartDate}
												onChange={(e) =>
													handleDatepickerChange(
														e,
														"leaseStartDate"
													)
												}
											/> *}
												<Datepicker
													placeholderText="dd/mm/yyyy"
													dateFormat="dd/MM/yyyy"
													maxDate={
														formData.leaseEndDate ||
														null
													}
													selectedDate={
														formData.leaseStartDate
													}
													handleDatepickerChange={
														handleDatepickerChange
													}
													name="leaseStartDate"
												/>
												{isFormSubmitted &&
													formErrors.leaseStartDate && (
														<ValidationMessage id="leaseStartDateHelp">
															{
																formErrors.leaseStartDate
															}
														</ValidationMessage>
													)}
											</div>
										</div>
										<div className="col-md-4 col-sm-12">
											<div className="font-18">
												Lease end date
											</div>
											<div className="lp-fields">
												{/* <input
												type="date"
												placeholder=""
												name="leaseEndDate"
												value={formData.leaseEndDate}
												onChange={(e) =>
													handleDatepickerChange(
														e,
														"leaseEndDate"
													)
												}
											/> *}
												<Datepicker
													placeholderText="dd/mm/yyyy"
													dateFormat="dd/MM/yyyy"
													minDate={
														formData.leaseStartDate ||
														null
													}
													selectedDate={
														formData.leaseEndDate
													}
													handleDatepickerChange={
														handleDatepickerChange
													}
													name="leaseEndDate"
												/>
												{isFormSubmitted &&
													formErrors.leaseEndDate && (
														<ValidationMessage id="leaseEndDateHelp">
															{
																formErrors.leaseEndDate
															}
														</ValidationMessage>
													)}
											</div>
										</div>
										<div className="col-md-4 col-sm-12">
											<div className="font-18">
												Select preferred level
											</div>
											<div className="lp-fields">
												<ul
													id="selectPreferredLevel"
													className={
														isSelectPreferredLevelDropdownOpen
															? "list-unstyled customselect show"
															: "list-unstyled customselect"
													}
													onClick={toggleSelect}
												>
													<li className="init">
														{
															selectedPreferredLevel.label
														}
													</li>
													{LEVELS.map((level) => (
														<li
															id={level.value}
															onClick={() =>
																setSelectedPreferredLevel(
																	level
																)
															}
														>
															{level.label}
														</li>
													))}
												</ul>
											</div>
										</div> */}
											<h6 className="dblue d-bold col-md-12">
												<label>Emergency Contact</label>
											</h6>
											<InputField
												title="Name"
												fieldName="emergencyContactName"
												formData={formData}
												handleTextInputChange={handleTextInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/>
											<InputField
												title="Relationship"
												fieldName="emergencyContactRelationship"
												formData={formData}
												handleTextInputChange={handleTextInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/>
											{/* <InputField
												title="Phone"
												fieldName="emergencyContactPhone"
												formData={formData}
												handleTextInputChange={handleTextInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/> */}
											<div className="col-md-4 col-sm-12">
												<div className="font-18">Mobile/Landline</div>
												<div className="lp-fields">
													<div className="phone-fields d-flex justify-content-between align-items-center">
														<input
															type="tel"
															name="emergencyContactPhoneCountryCode"
															placeholder="Country"
															value={formData.emergencyContactPhoneCountryCode}
															onChange={handleTextInputChange}
															maxLength={PHONE_COUNTRY_CODE_MAX_LENGTH}
														/>
														<input
															type="tel"
															name="emergencyContactPhoneAreaCode"
															placeholder="Area Code"
															value={formData.emergencyContactPhoneAreaCode}
															onChange={handleTextInputChange}
															maxLength={PHONE_AREA_CODE_MAX_LENGTH}
														/>
														<input
															type="tel"
															name="emergencyContactPhoneNumber"
															placeholder="Phone Number"
															value={formData.emergencyContactPhoneNumber}
															onChange={handleTextInputChange}
															maxLength={PHONE_NUMBER_MAX_LENGTH}
														/>
													</div>
													{isFormSubmitted &&
														formErrors.emergencyContactPhone && (
															<ValidationMessage id="emergencyContactPhoneHelp">
																{formErrors.emergencyContactPhone}
															</ValidationMessage>
														)}
												</div>
											</div>
											<InputField
												title="Address"
												fieldName="emergencyContactAddress"
												formData={formData}
												handleTextInputChange={handleTextInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/>
											<h6 className="dblue d-bold col-md-12">
												<label>Lease Details</label>
											</h6>
											<div className="col-md-12">
												<div className="font-18">Upload documents</div>
												<div class="documents d-flex">
													{/*  */}
													{documents.length > 0 &&
														documents.map((document) => (
															<div class="document-div d-flex align-items-center position-relative">
																<div class="document-icon mr-2">
																	<img src={documentIcon} alt="document_icon" />
																</div>
																<div class="document-title font-20 light">
																	{utils.clipTextWithEllipsis(
																		document.name,
																		25
																	)}
																</div>
																<input
																	type="button"
																	id="removeImage1"
																	value="x"
																	className="btn-rmv1"
																	onClick={() =>
																		removeDocument({
																			id: document.id,
																			index: document.index,
																		})
																	}
																/>
															</div>
														))}
													{/*  */}
													<div className="d-flex input-flex mt-3">
														<div className="file-uploader__message-area"></div>
														<div className="file-chooser">
															<span className="btn_upload">
																<input
																	type="file"
																	className="file-chooser__input input-img"
																	id="file5"
																	name="file5[]"
																	onChange={handleFileUpload}
																	multiple
																/>
																<img
																	src={
																		documents.length > 0 ? uploadIcon : addMedia
																	}
																	alt="add_icon"
																/>
															</span>
															{/* {isFormSubmitted &&
																formErrors.documentsUpload && (
																	<ValidationMessage id="documentsUploadHelp">
																		{
																			formErrors.documentsUpload
																		}
																	</ValidationMessage>
																)} */}
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-4 col-sm-12">
												<div className="font-18">Start date</div>
												<div className="lp-fields">
													{/* <input
												type="date"
												placeholder=""
												name="startDate"
												value={formData.startDate}
												onChange={(e) =>
													handleDatepickerChange(
														e,
														"startDate"
													)
												}
											/> */}
													<Datepicker
														placeholderText="dd/mm/yyyy"
														dateFormat="dd/MM/yyyy"
														maxDate={formData.endDate || null}
														selectedDate={formData.startDate}
														handleDatepickerChange={handleDatepickerChange}
														name="startDate"
													/>
													{isFormSubmitted && formErrors.startDate && (
														<ValidationMessage id="startDateHelp">
															{formErrors.startDate}
														</ValidationMessage>
													)}
												</div>
											</div>
											<div className="col-md-4 col-sm-12">
												<div className="font-18">Lease Period</div>
												<div className="lp-fields d-flex h-70 justify-content-around align-items-center">
													{/* <input
												type="date"
												placeholder=""
												name="endDate"
												value={formData.endDate}
												onChange={(e) =>
													handleDatepickerChange(
														e,
														"endDate"
													)
												}
											/> */}
													{/* <Datepicker
														placeholderText="dd/mm/yyyy"
														dateFormat="dd/MM/yyyy"
														minDate={formData.startDate || null}
														selectedDate={formData.endDate}
														handleDatepickerChange={handleDatepickerChange}
														name="endDate"
													/> */}
													<label for="6" className="radio-container">
														6 Months
														<input
															type="radio"
															id="6"
															name="leasePeriod"
															value="6"
															checked={formData.leasePeriod == "6"}
															onChange={setLeasePeriod}
														/>
														{/* <label for="6">6 Months</label> */}
														<span class="radio-checkmark"></span>
													</label>
													<label for="12" className="radio-container">
														12 Months
														<input
															type="radio"
															id="12"
															name="leasePeriod"
															value="12"
															checked={formData.leasePeriod == "12"}
															onChange={setLeasePeriod}
														/>
														{/* <label for="12">12 Months</label> */}
														<span class="radio-checkmark"></span>
													</label>
													{/* {isFormSubmitted && formErrors.endDate && (
														<ValidationMessage id="endDateHelp">
															{formErrors.endDate}
														</ValidationMessage>
													)} */}
												</div>
											</div>
											<div className="col-md-4 col-sm-12">
												<div className="font-18">End Date</div>
												<div className="lp-fields">
													<div>
														<input
															type="text"
															name="endDate"
															value={
																formData.endDate &&
																utils.formatDateDDMMYYYY(formData.endDate, "/")
															}
															placeholder="Please select a Start Date"
															disabled={true}
														/>
													</div>
												</div>
											</div>
											<InputField
												title="Deposit"
												fieldName="deposit"
												formData={formData}
												handleTextInputChange={handleTextInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/>
											<InputField
												title="Bond"
												fieldName="bond"
												formData={formData}
												handleTextInputChange={handleTextInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/>
											{/* <div className="col-md-4 col-sm-12"></div> */}
											<InputField
												title="Fortnightly rent amount"
												fieldName="fortnightlyRentAmount"
												formData={formData}
												handleTextInputChange={handleTextInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/>
											<div className="col-md-4 col-sm-12">
												<div className="font-18">Room level</div>
												<div className="lp-fields">
													<ul
														id="roomLevel"
														className={
															isRoomLevelDropdownOpen
																? "list-unstyled customselect show"
																: "list-unstyled customselect"
														}
														onClick={toggleSelect}
													>
														<li className="init">{selectedRoomLevel.label}</li>
														{LEVELS.map((level) => (
															<li
																key={level.value}
																onClick={() => setSelectedRoomLevel(level)}
															>
																{level.label}
															</li>
														))}
													</ul>
												</div>
											</div>
											<InputField
												title="Room Number"
												fieldName="roomNumber"
												formData={formData}
												handleTextInputChange={handleTextInputChange}
												isFormSubmitted={isFormSubmitted}
												formErrors={formErrors}
											/>
											<div className="col-md-4 col-sm-12">
												<div className="font-18">Number of residents</div>
												<div className="lp-fields">
													<ul
														id="occupants"
														className={
															isOccupantsDropdownOpen
																? "list-unstyled customselect show"
																: "list-unstyled customselect"
														}
														onClick={toggleSelect}
													>
														<li className="init">{occupantsPerRoom.label}</li>
														{ALLOWED_OCCUPANTS_PER_ROOM.map(
															(occupantsPerRoom) => (
																<li
																	id={occupantsPerRoom.value}
																	onClick={() =>
																		setOccupantsPerRoom(occupantsPerRoom)
																	}
																>
																	{occupantsPerRoom.label}
																</li>
															)
														)}
													</ul>
												</div>
											</div>
											<button
												type="submit"
												className="curved-btn red-bg white bold"
												// disabled={!isResidentUpdated}
											>
												Save
											</button>
											<button
												className="curved-btn cancel white-bg red bold"
												onClick={goBack}
											>
												Cancel
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default AdminAddUpdateResidentPage;


const InputField = (
	{title,
	fieldName,
	formData,
	handleTextInputChange,
	isFormSubmitted,
	formErrors}
) => {
	const hasCurrency =
		(fieldName == "deposit" ||
		fieldName == "bond" ||
		fieldName == "fortnightlyRentAmount")
			? true
			: false;
	return (
		<div className="col-md-4 col-sm-12">
			<div className="font-18">{title}</div>
			<div className="lp-fields">
				<div>
					{hasCurrency && <span className="label-currency">$</span>}
					<input
						type="text"
						className={hasCurrency ? "pl-50" : ""}
						name={fieldName}
						value={formData[fieldName]}
						onChange={handleTextInputChange}
					/>
				</div>
				{isFormSubmitted && formErrors[fieldName] && (
					<ValidationMessage id={`${fieldName}Help`}>
						{formErrors[fieldName]}
					</ValidationMessage>
				)}
			</div>
		</div>
	);
};