import React from "react";
import ContentLoader from "react-content-loader";

const WifiDetailsLoader = (props) => (
	<ContentLoader
		speed={2}
		width={800}
		height={150}
		viewBox="0 0 800 150"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		{...props}
	>
		<rect x="10" y="45" rx="15" ry="15" width="450" height="80" />
		<rect x="480" y="45" rx="15" ry="15" width="300" height="80" />
	</ContentLoader>
);

export default WifiDetailsLoader;
