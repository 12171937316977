import axios from "axios";

import {
	LUXPOD_BASE_URL,
	API_SUCCESS_CODE,
	// USER_TYPE_ADMIN,
	// USER_TYPE_BUILDING_MANAGER,
	USER_TYPE_RESIDENT,
} from "../constants";
import {
	currentPosts,
	previousPosts,
	residentsLists,
	metas,
	issuesLists,
	issues,
	meta,
	images,
	dashboardPosts,
	dashboardIssuesData,
	residentProfileDetails,
	residentLeaseDetails,
	residentWifiDetails,
} from "../mockData";

function getCommonHeaders(headers) {
	return Object.assign({}, headers || {}, {
		"Content-Type": "application/json",
	});
}

function getCommonHeadersWithAuth(headers) {
	return Object.assign({}, headers || {}, {
		"Content-Type": "application/json",
		Authorization: `Bearer ${localStorage.getItem("token")}`,
	});
}

function isSuccessful(response) {
	if (response.code === API_SUCCESS_CODE) {
		return true;
	}
	return false;
}

function handleResponse(response) {
	if (isSuccessful(response.data)) {
		return response.data;
	} else {
		throw response.data;
	}
}

function handleError(error) {
	if (error.response) {
		throw error.response.data;
	} else {
		console.error(error);
		throw error;
	}
}

function login({ email, password }) {
	// return new Promise(function (resolve, reject) {
	//     if (true) {
	//         const payload = {
	// 			code: 200,
	// 			message: "Logged in successfully!",
	// 			role: USER_TYPE_RESIDENT,
	// 			user: {
	// 				name: "User",
	// 				access_token: "D9RUGR95H8Y348H34HT49HTW9T84"
	// 			},
	// 		};
	//         setTimeout(() => {
	//             resolve(payload);
	//         }, 2000);
	//     } else {
	//         reject(new Error("Error"));
	//     }
	// });

	const options = {
		headers: getCommonHeaders(),
	};
	const body = JSON.stringify({ email, password });

	return axios
		.post(`${LUXPOD_BASE_URL}/admin/login`, body, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function forgotPassword(email) {
	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	//             code: 200,
	//             message: "A link has been sent your email. Please click on it to reset your password."
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 2000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });

	const options = {
		headers: getCommonHeaders(),
	};
	const body = JSON.stringify({ email });

	return axios
		.post(`${LUXPOD_BASE_URL}/admin/forgotpassword`, body, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function resetPassword({ password, token }) {
	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	// 			code: 200,
	// 			message:
	// 				"Your password has been successfully reset.",
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 2000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });

	const options = {
		headers: getCommonHeaders(),
	};
	const body = JSON.stringify({
		auth: token,
		password,
	});

	return axios
		.post(`${LUXPOD_BASE_URL}/admin/savenewpassword`, body, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function fetchCurrentMonthPosts() {
	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	// 			code: 200,
	// 			message: "Current Month Posts fetched.",
	// 			result: currentPosts,
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 3000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });

	const options = {
		headers: getCommonHeadersWithAuth(),
	};

	return axios
		.get(`${LUXPOD_BASE_URL}/admin/listpostthismonth`, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function fetchPreviousPosts() {
	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	// 			code: 200,
	// 			message: "Previous Posts fetched.",
	// 			result: previousPosts,
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 3000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });

	const options = {
		headers: getCommonHeadersWithAuth(),
	};

	return axios
		.get(`${LUXPOD_BASE_URL}/admin/listpreviouspost`, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function savePost(path, post) {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};
	const body = JSON.stringify(post);

	return axios
		.post(`${LUXPOD_BASE_URL}/admin${path}`, body, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});

	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	// 			code: 200,
	// 			message: "New post saved.",
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 3000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });
}

function deletePost(id) {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};
	const body = JSON.stringify({ id });

	return axios
		.post(`${LUXPOD_BASE_URL}/admin/deletepost`, body, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function deleteImage(id) {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};
	const body = JSON.stringify({ id });

	return axios
		.post(`${LUXPOD_BASE_URL}/admin/deleteimage`, body, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

const CancelToken = axios.CancelToken;
let cancel;
function fetchResidents(toSearch, searchString, residentStatusFilterValue, pageSize, pageNo) {
	const headers = getCommonHeadersWithAuth();

	let url = `${LUXPOD_BASE_URL}/admin/listresidents`;
	if (toSearch) {
		if (searchString) {
			url += `&search=${searchString}`;
		}
		if (residentStatusFilterValue) {
			url += `&status=${residentStatusFilterValue}`;
		}
	} else {
		url += `&pagesize=${pageSize}&pagenumber=${pageNo - 1}`;
	}

	if (cancel !== undefined) {
		cancel();
	}

	return axios
		.get(url, {
			cancelToken: new CancelToken(function executor(c) {
				cancel = c;
			}),
			headers,
		})
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			if (axios.isCancel(error)) {
				// Setting this true because it needs to be checked in actionCreator to dispatch (or not dispatch) corresponding action.
				error.cancelledAsAnotherCallMade = true;
			}
			handleError(error);
		});

	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	// 			code: 200,
	// 			message: "Residents fetched.",
	// 			result: residentsLists[pageNo - 1],
	// 			meta: metas[pageNo - 1],
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 3000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });
}

function fetchResidentDetails(id) {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};

	return axios
		.get(`${LUXPOD_BASE_URL}/admin/residentdetail&id=${id}`, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function fetchResidentLeaseDetails(id) {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};

	return axios
		.get(`${LUXPOD_BASE_URL}/admin/leasedetail&id=${id}`, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function saveAdminProfile(data) {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};
	options.headers["Content-Type"] = "multipart/form-data";

	return axios
		.post(`${LUXPOD_BASE_URL}/admin/editprofile`, data, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function fetchCourseTypes() {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};

	return axios
		.get(`${LUXPOD_BASE_URL}/admin/listcoursestype`, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function deleteResidentProfilePicture() {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};

	return axios
		.get(`${LUXPOD_BASE_URL}/admin/deleteprofilepicture`, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function editAdminPassword(password) {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};
	const body = JSON.stringify({
		password,
	});

	return axios
		.post(`${LUXPOD_BASE_URL}/admin/editpassword`, body, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function saveResident(path, residentData) {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};
	options.headers["Content-Type"] = "multipart/form-data";

	// const body = JSON.stringify(residentData);

	return axios
		.post(`${LUXPOD_BASE_URL}/admin${path}`, residentData, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function deleteDocument(id) {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};
	const body = JSON.stringify({ id });

	return axios
		.post(`${LUXPOD_BASE_URL}/admin/deletedocument`, body, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function fetchIssues(toSearch, searchString, requestDate, requestStatus, pageSize, pageNo) {
	const headers = getCommonHeadersWithAuth();
	let url = `${LUXPOD_BASE_URL}/admin/listissues`;
	if (toSearch) {
		if (searchString) {
			url += `&search=${searchString}`;
		}
		if (requestDate) {
			url += `&filterdate=${requestDate}`;
		}
		if (requestStatus) {
			url += `&filterstatus=${requestStatus}`;
		}
	} else {
		url += `&pagesize=${pageSize}&pagenumber=${pageNo - 1}`;
	}

	if (cancel !== undefined) {
		cancel();
	}

	return axios
		.get(url, {
			cancelToken: new CancelToken(function executor(c) {
				cancel = c;
			}),
			headers,
		})
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			if (axios.isCancel(error)) {
				// Setting this true because it needs to be checked in actionCreator to dispatch (or not dispatch) corresponding action.
				error.cancelledAsAnotherCallMade = true;
			}
			handleError(error);
		});

	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	// 			code: 200,
	// 			message: "Issues fetched.",
	// 			result: toSearch ? issues : issuesLists[pageNo - 1],
	// 			meta: toSearch ? meta : metas[pageNo - 1],
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 3000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });
}

function fetchIssueDetails(id) {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};

	return axios
		.get(`${LUXPOD_BASE_URL}/admin/issuedetail&id=${id}`, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});

	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	// 			code: 200,
	// 			message: "Issues fetched.",
	// 			result:
	// 				issuesLists[
	// 					parseInt(id) % 5
	// 						? parseInt(parseInt(id) / 5)
	// 						: parseInt(parseInt(id) / 5) - 1
	// 				][
	// 					parseInt(id) % 5
	// 						? (parseInt(id) % 5) - 1
	// 						: 5 - 1
	// 				],
	// 			images,
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 3000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });
}

function changeIssueStatus(id, value) {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};
	const body = JSON.stringify({ id, status: value });

	return axios
		.post(`${LUXPOD_BASE_URL}/admin/changestatus`, body, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function fetchLatestPosts() {
	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	// 			code: 200,
	// 			result: dashboardPosts,
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 3000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });

	const options = {
		headers: getCommonHeadersWithAuth(),
	};

	return axios
		.get(`${LUXPOD_BASE_URL}/admin/listlastposts`, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}


function fetchIssuesGraphData() {
	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	// 			code: 200,
	// 			result: dashboardIssuesData,
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 3000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });

	const options = {
		headers: getCommonHeadersWithAuth(),
	};

	return axios
		.get(`${LUXPOD_BASE_URL}/admin/dashboardissues`, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function fetchResidentPosts(pageSize, pageNo) {
	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	// 			code: 200,
	// 			message: "Resident Posts fetched.",
	// 			result: currentPosts,
	// 			meta: metas[pageNo - 1],
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 2000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });

	const options = {
		headers: getCommonHeadersWithAuth(),
	};

	return axios
		.get(
			`${LUXPOD_BASE_URL}/resident/noticeboard&pagesize=${pageSize}&pagenumber=${pageNo - 1}`,
			options
		)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function saveIssue(data, path) {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};
	options.headers["Content-Type"] = "multipart/form-data";

	return axios
		.post(`${LUXPOD_BASE_URL}/resident/${path}`, data, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});

	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	// 			code: 200,
	// 			message: "Issue successfully reported."
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 2000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });
}


function fetchResidentIssues(toSearch, searchString, pageSize, pageNo) {
	const headers = getCommonHeadersWithAuth();
	let url = `${LUXPOD_BASE_URL}/resident/listissues`;
	if (toSearch) {
		if (searchString) {
			url += `&search=${searchString}`;
		}
	} else {
		url += `&pagesize=${pageSize}&pagenumber=${pageNo - 1}`;
	}

	if (cancel !== undefined) {
		cancel();
	}

	return axios
		.get(url, {
			cancelToken: new CancelToken(function executor(c) {
				cancel = c;
			}),
			headers,
		})
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			if (axios.isCancel(error)) {
				// Setting this true because it needs to be checked in actionCreator to dispatch (or not dispatch) corresponding action.
				error.cancelledAsAnotherCallMade = true;
			}
			handleError(error);
		});

	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	// 			code: 200,
	// 			message: "Issues fetched.",
	// 			result: toSearch ? issues : issuesLists[pageNo - 1],
	// 			meta: toSearch ? meta : metas[pageNo - 1],
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 3000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });
}

function fetchResidentProfileDetails() {
	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	// 			code: 200,
	// 			result: residentProfileDetails,
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 3000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });

	const options = {
		headers: getCommonHeadersWithAuth(),
	};

	return axios
		.get(`${LUXPOD_BASE_URL}/resident/viewprofile`, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function saveResidentProfileDetails(residentProfileData) {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};
	options.headers["Content-Type"] = "multipart/form-data";

	// const body = JSON.stringify(residentData);

	return axios
		.post(
			`${LUXPOD_BASE_URL}/resident/editprofile`,
			residentProfileData,
			options
		)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});

	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	// 			code: 200,
	// 			message: "Profile successfully updated."
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 2000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });
}

function fetchLeaseDetails() {
	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	// 			code: 200,
	// 			result: residentLeaseDetails,
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 3000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });

	const options = {
		headers: getCommonHeadersWithAuth(),
	};

	return axios
		.get(`${LUXPOD_BASE_URL}/resident/leasedetail`, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function fetchWifiDetails(residentId) {
	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	// 			code: 200,
	// 			result: residentWifiDetails,
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 3000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });

	const options = {
		headers: getCommonHeadersWithAuth(),
	};

	return axios
		.get(
			`${LUXPOD_BASE_URL}/resident/wifidetail&tenant_id=${residentId}`,
			options
		)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function fetchBuildingManagerDetails() {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};

	return axios
		.get(`${LUXPOD_BASE_URL}/resident/bmdetail`, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function deleteResidentIssueImage(data) {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};
	options.headers["Content-Type"] = "multipart/form-data";

	return axios
		.post(
			`${LUXPOD_BASE_URL}/resident/deleteimageissue`,
			data,
			options
		)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}



function fetchAdminWifiDetails() {

	const options = {
		headers: getCommonHeadersWithAuth(),
	};

	return axios
		.get(`${LUXPOD_BASE_URL}/admin/viewwifidetail`, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function editWifiDetails(data) {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};
	options.headers["Content-Type"] = "multipart/form-data";

	return axios
		.post(`${LUXPOD_BASE_URL}/admin/editwifidetail`, data, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function fetchDefaultPostalAddress() {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};

	return axios
		.get(`${LUXPOD_BASE_URL}/admin/defaultaddress`, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

function fetchCSVFileLink() {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};
	return axios
		.get(`${LUXPOD_BASE_URL}/admin/exportexcel`, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
	// return new Promise(function (resolve, reject) {
	// 	if (true) {
	// 		const payload = {
	// 			code: 200,
	// 			result:
	// 				"https://www.sample-videos.com/csv/Sample-Spreadsheet-10-rows.csv",
	// 		};
	// 		setTimeout(() => {
	// 			resolve(payload);
	// 		}, 1000);
	// 	} else {
	// 		reject(new Error("Error"));
	// 	}
	// });
}

function fetchLuxpodBankDetails() {
	const options = {
		headers: getCommonHeadersWithAuth(),
	};

	return axios
		.get(`${LUXPOD_BASE_URL}/resident/getbankinfo`, options)
		.then((response) => {
			return handleResponse(response);
		})
		.catch((error) => {
			handleError(error);
		});
}

const apiService = {
	login,
	forgotPassword,
	resetPassword,
	fetchCurrentMonthPosts,
	fetchPreviousPosts,
	savePost,
	deletePost,
	deleteImage,
	fetchResidents,
	fetchResidentDetails,
	fetchResidentLeaseDetails,
	saveAdminProfile,
	deleteResidentProfilePicture,
	editAdminPassword,
	fetchCourseTypes,
	saveResident,
	deleteDocument,
	fetchIssues,
	fetchIssueDetails,
	fetchLatestPosts,
	fetchIssuesGraphData,
	changeIssueStatus,
	fetchResidentPosts,
	saveIssue,
	fetchResidentIssues,
	fetchResidentProfileDetails,
	saveResidentProfileDetails,
	fetchLeaseDetails,
	fetchWifiDetails,
	fetchBuildingManagerDetails,
	deleteResidentIssueImage,
	fetchAdminWifiDetails,
	editWifiDetails,
	fetchDefaultPostalAddress,
	fetchCSVFileLink,
	fetchLuxpodBankDetails,
};

export default apiService;
