import {
	FETCH_ISSUES_PENDING,
	FETCH_ISSUES_SUCCESSFUL,
	FETCH_ISSUE_DETAILS_PENDING,
	FETCH_ISSUE_DETAILS_SUCCESSFUL,
	CHANGE_ISSUE_STATUS_SUCCESSFUL,
	SAVE_ISSUE_PENDING,
	SET_SAVE_ISSUE_STATUS,
	FETCH_RESIDENT_ISSUES_PENDING,
	FETCH_RESIDENT_ISSUES_SUCCESSFUL,
	SET_ADMIN_ISSUES_DEFAULT_FILTER,
	// SET_ISSUES_BADGE_COUNT,
} from "./types";
import { DEFAULT_ERROR_MESSAGE } from "../constants";
import apiService from "../services/apiService";
import {
	// showSuccessMessage,
	showErrorMessage,
} from "./toast";

const fetchIssuesPending = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_ISSUES_PENDING,
		payload,
	});
};

const fetchIssuesSuccessful = (result, meta) => (dispatch) => {
	dispatch({
		type: FETCH_ISSUES_SUCCESSFUL,
		payload: {
			result,
			meta,
		},
	});
};

export const fetchIssues = (toSearch, searchString, requestDate, requestStatus, pageSize, pageNo) => (
	dispatch
) => {
	dispatch(fetchIssuesPending(true));
	apiService.fetchIssues(toSearch, searchString, requestDate, requestStatus, pageSize, pageNo).then(
		(res) => {
			dispatch(fetchIssuesPending(false));
			dispatch(fetchIssuesSuccessful(res.result, res.meta));
		},
		(err) => {
			console.error(err);
			if (!err.cancelledAsAnotherCallMade) {
				dispatch(fetchIssuesPending(false));
				dispatch(
					showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
				);
			}
		}
	);
};

const fetchIssueDetailsPending = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_ISSUE_DETAILS_PENDING,
		payload,
	});
};

const fetchIssueDetailsSuccessful = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_ISSUE_DETAILS_SUCCESSFUL,
		payload,
	});
};

export const fetchIssueDetails = (id) => (dispatch) => {
	dispatch(fetchIssueDetailsPending(true));
	apiService.fetchIssueDetails(id).then(
		(res) => {
			dispatch(fetchIssueDetailsPending(false));
			dispatch(
				fetchIssueDetailsSuccessful({
					...res.result,
					// images: res.images,
				})
			);
		},
		(err) => {
			console.error(err);
			dispatch(fetchIssueDetailsPending(false));
			dispatch(
				showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
			);
		}
	);
};

const changeIssueStatusSuccessful = (payload) => (dispatch) => {
	dispatch({
		type: CHANGE_ISSUE_STATUS_SUCCESSFUL,
		payload,
	});
};

export const changeIssueStatus = (id, value) => (dispatch) => {
	// dispatch(changeIssueStatusPending(true));
	dispatch(changeIssueStatusSuccessful(false));
	apiService.changeIssueStatus(id, value).then(
		(res) => {
			// dispatch(changeIssueStatusPending(false));
			dispatch(changeIssueStatusSuccessful(true));
		},
		(err) => {
			console.error(err);
			// dispatch(changeIssueStatusPending(false));
			dispatch(
				showErrorMessage(err.message || DEFAULT_ERROR_MESSAGE)
			);
		}
	);
};



const saveIssuePending = (payload) => (dispatch) => {
	dispatch({
		type: SAVE_ISSUE_PENDING,
		payload,
	});
};

export const setSaveIssueStatus = (payload) => (dispatch) => {
	dispatch({
		type: SET_SAVE_ISSUE_STATUS,
		payload,
	});
};

export const saveIssue = (issueData, path) => (dispatch) => {
	dispatch(saveIssuePending(true));
	apiService.saveIssue(issueData, path).then(
		(res) => {
			dispatch(saveIssuePending(false));
			dispatch(setSaveIssueStatus(true));
		},
		(err) => {
			console.error(err);
			dispatch(saveIssuePending(false));
			dispatch(
				showErrorMessage((err & err.message) || DEFAULT_ERROR_MESSAGE)
			);
		}
	);
};

const fetchResidentIssuesPending = (payload) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENT_ISSUES_PENDING,
		payload,
	});
};

const fetchResidentIssuesSuccessful = (result, meta) => (dispatch) => {
	dispatch({
		type: FETCH_RESIDENT_ISSUES_SUCCESSFUL,
		payload: {
			result,
			meta,
		},
	});
};

export const fetchResidentIssues = (toSearch, searchString, pageSize, pageNo) => (dispatch) => {
	dispatch(fetchResidentIssuesPending(true));
	apiService
		.fetchResidentIssues(toSearch, searchString, pageSize, pageNo)
		.then(
			(res) => {
				dispatch(fetchResidentIssuesPending(false));
				dispatch(fetchResidentIssuesSuccessful(res.result, res.meta));
			},
			(err) => {
				console.error(err);
				if (!err.cancelledAsAnotherCallMade) {
					dispatch(fetchResidentIssuesPending(false));
					dispatch(
						showErrorMessage(
							err.message || DEFAULT_ERROR_MESSAGE
						)
					);
				}
			}
		);
};

export const deleteResidentIssueImage = (data) => (dispatch) => {
	apiService.deleteResidentIssueImage(data).then(
		(res) => {
			// dispatch(showSuccessMessage(res.message));
		},
		(err) => {
			console.error(err);
			dispatch(
				showErrorMessage(err.message || "Could not delete image. Please refresh and try again.")
			);
		}
	);
};

// export const setIssuesBadgeCount = (unreadIssuesCount) => (dispatch) => {
// 	dispatch({
// 		type: SET_ISSUES_BADGE_COUNT,
// 		payload: unreadIssuesCount,
// 	});
// };

export const setDefaultFilter = (defaultFilter) => (dispatch) => {
	dispatch({
		type: SET_ADMIN_ISSUES_DEFAULT_FILTER,
		payload: defaultFilter
	});
};