import {
	SET_SAVE_WIFI_DETAILS_STATUS,
	SET_FETCH_ADMIN_WIFI_DETAILS_PENDING,
	SET_FETCH_ADMIN_WIFI_DETAILS_SUCCESSFUL,
} from "../actions/types";

const initialState = {
	isSaveWifiDetailsSuccessful: false,
	isFetchAdminWifiDetailsPending: false,
	adminWifiDetails: [],
};

export default function wifiReducer(state = initialState, action) {
	switch (action.type) {
		case SET_SAVE_WIFI_DETAILS_STATUS:
			return Object.assign({}, state, {
				isSaveWifiDetailsSuccessful: action.payload,
			});
		case SET_FETCH_ADMIN_WIFI_DETAILS_PENDING:
			return Object.assign({}, state, {
				isFetchAdminWifiDetailsPending: action.payload,
			});
		case SET_FETCH_ADMIN_WIFI_DETAILS_SUCCESSFUL:
			return Object.assign({}, state, {
				adminWifiDetails: action.payload,
			});
		default:
			return state;
	}
}
