import React, { Fragment, useState, useEffect } from "react";

import ValidationMessage from "../../Ui/ValidationMessage";
import "./adminWifi.scss";
import { LEVELS } from "../../../constants";

const AdminWifiPage = ({
	wifiData: {
		isSaveWifiDetailsSuccessful,
		isFetchAdminWifiDetailsPending,
		adminWifiDetails,
	},
	...rest
}) => {
	useEffect(() => {
		rest.fetchAdminWifiDetails();
	}, []);
	
	const [initialFormData, setInitialFormData] = useState({});
	const [formData, setFormData] = useState(initialFormData);
	
	// const [initialFormErrors, setInitialFormErrors] = useState({});
	const initialFormErrors = {
		wifi1Name: "",
		wifi1Password: "",
		wifi2Name: "",
		wifi2Password: "",
		wifi3Name: "",
		wifi3Password: ""
	};
	const [formErrors, setFormErrors] = useState(initialFormErrors);

	useEffect(() => {
		// setFormData({
		// 	...initialFormData,
		// 	wifiName: adminWifiDetails.name,
		// 	wifiPassword: adminWifiDetails.password,
		// });
		if (adminWifiDetails && adminWifiDetails.length > 0) {
			let formData = {};
			// let formErrors = {};
			adminWifiDetails.forEach((wifiDetails) => {
				formData[`wifi${wifiDetails.level}Name`] =
					wifiDetails.name;
				formData[`wifi${wifiDetails.level}Password`] =
					wifiDetails.password;
				
				// formErrors[`wifi${wifiDetails.level}Name`] = "";
				// formErrors[`wifi${wifiDetails.level}Password`] = "";
			});
			
			// if (Object.keys(initialFormData).length === 0) {
				setInitialFormData(formData);
			// }
			setFormData({ ...initialFormData, ...formData });
			// if (Object.keys(initialFormErrors).length === 0) {
			// 	// setInitialFormErrors(formErrors);
			// 	// setFormErrors(formErrors);
			// }
		}
	}, [adminWifiDetails]);
	
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	const goBack = (e) => {
		if (e) {
			e.preventDefault();
		}
		window.history.go(-1);
	};
	useEffect(() => {
		if (isSaveWifiDetailsSuccessful) {
			rest.setEditWifiDetailsStatus(false);
			goBack();
		}
	}, [isSaveWifiDetailsSuccessful]);

	const handleInputChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const [isUpdated, setIsUpdated] = useState(false);

	const isObjectIdentical = (object1, object2) => {
		return JSON.stringify(object1) === JSON.stringify(object2);
	};

	const checkIfUpdated = () => {
		if (!isObjectIdentical(formData, initialFormData)) {
			setIsUpdated(true);
		} else {
			setIsUpdated(false);
		}
	};

	useEffect(() => {
		checkIfUpdated();
		if (isFormSubmitted) {
			isFormValid();
		}
	}, [formData]);

	const isFormValid = () => {
		let updatedFormErrors = formErrors;
		let isFormValid = true;
		Object.keys(formErrors).forEach((field) => {
			switch (field) {
				case "wifi1Name":
					// console.log(formData.wifi1Name);
					if (!formData.wifi1Name) {
						updatedFormErrors[field] = "Please enter Wifi Name";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "wifi1Password":
					// console.log(formData.wifi1Password);
					if (!formData.wifi1Password) {
						updatedFormErrors[field] = "Please enter Wifi Password";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "wifi2Name":
					// console.log(formData.wifi2Name);
					if (!formData.wifi2Name) {
						updatedFormErrors[field] = "Please enter Wifi Name";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "wifi2Password":
					// console.log(formData.wifi2Password);
					if (!formData.wifi2Password) {
						updatedFormErrors[field] = "Please enter Wifi Password";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "wifi3Name":
					if (!formData.wifi3Name) {
						updatedFormErrors[field] = "Please enter Wifi Name";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "wifi3Password":
					if (!formData.wifi3Password) {
						updatedFormErrors[field] = "Please enter Wifi Password";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				default:
					break;
			}
		});
		if (isFormValid) {
			updatedFormErrors = initialFormErrors;
		}
		setFormErrors({ ...formErrors, ...updatedFormErrors });
		return isFormValid;
	};

	const createWifiDetailsData = () => {
		let data = new FormData();
		data.append("name[1]", formData.wifi1Name);
		data.append("password[1]", formData.wifi1Password);
		data.append("name[2]", formData.wifi2Name);
		data.append("password[2]", formData.wifi2Password);
		data.append("name[3]", formData.wifi3Name);
		data.append("password[3]", formData.wifi3Password);
		// for (let pair of data.entries()) {
		// 	console.log(pair[0]+ ', ' + pair[1]);
		// }
		return data;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setIsFormSubmitted(true);
		if (isFormValid()) {
			rest.editWifiDetails(createWifiDetailsData());
		}
	};

	return (
		<Fragment>
			<div className="container-fluid">
				<div className="title-bar d-flex justify-content-between align-items-center">
					<div className="font-45 dblue d-bold">
						<span>Edit Wifi Credentials</span>
					</div>
				</div>
			</div>
			{!isFetchAdminWifiDetailsPending && (
				<div className="admin-wifi">
					{/* {(adminWifiDetails &&
					Object.keys(adminWifiDetails).length > 0) ? ( */}
					<form onSubmit={handleSubmit}>
						{LEVELS.map((level) => (
							<Fragment>
								<h6 className="dblue dbold col-md-9">
									<label>Level {level.value}</label>
								</h6>
								<div className="d-flex align-content-center">
									<div className="col-md-3 col-sm-12">
										<label className="font-18">
											Wifi Code
										</label>
										<div className="lp-fields">
											<input
												type="text"
												name={`wifi${level.value}Name`}
												value={
													formData[
														`wifi${level.value}Name`
													]
												}
												onChange={handleInputChange}
											/>
											{isFormSubmitted &&
												formErrors[
													`wifi${level.value}Name`
												] && (
													<ValidationMessage
														id={`wifi${level.value}NameHelp`}
													>
														{
															formErrors[
																`wifi${level.value}Name`
															]
														}
													</ValidationMessage>
												)}
										</div>
									</div>
									<div className="col-md-3 col-sm-12">
										<label className="font-18">
											Wifi Password
										</label>
										<div className="lp-fields">
											<input
												type="text"
												name={`wifi${level.value}Password`}
												value={
													formData[
														`wifi${level.value}Password`
													]
												}
												onChange={handleInputChange}
											/>
											{isFormSubmitted &&
												formErrors[
													`wifi${level.value}Password`
												] && (
													<ValidationMessage
														id={`wifi${level.value}PasswordHelp`}
													>
														{
															formErrors[
																`wifi${level.value}Password`
															]
														}
													</ValidationMessage>
												)}
										</div>
									</div>
								</div>
							</Fragment>
						))}

						<div className="col-md-12 d-flex pt-60">
							<button
								className="curved-btn no-border red-bg white bold"
								disabled={!isUpdated}
							>
								Save
							</button>
							<button
								type="button"
								className="curved-btn cancel red-border red bold"
								onClick={goBack}
							>
								Cancel
							</button>
						</div>
					</form>
					{/* ) : (
						<p>Could not fetch current wifi details.</p>
					)} */}
				</div>
			)}
		</Fragment>
	);
};

export default AdminWifiPage;
