import React, { Fragment, useState, useEffect } from "react";

import ViewPostPage from "./ViewPostPage";
import { RESIDENT_POSTS_DEFAULT_PAGE_SIZE } from "../../../constants";
import PostLoader from "../../Ui/PostLoader";
import Pagination from "../../Ui/Pagination";
import Post from "../../AdminNoticeboard/Post";
import "./residentNoticeboard.scss";

const ResidentNoticeboardPage = ({ noticboardData: { isResidentFetchPostsPending, residentPosts, meta }, ...rest }) => {
	const [currentPageNumber, setCurrentPageNumber] = useState(
		// Add 1 to meta.currentpage once API is integrated as API returns currentpage starting with 0
		meta && meta.currentpage
			? meta.currentpage == 0
				? parseInt(meta.currentpage) + 1
				: parseInt(meta.currentpage)
			: 1
	);

	useEffect(() => {
		fetchResidentPosts();
	}, [currentPageNumber]);
	
	const fetchResidentPosts = () => {
		rest.fetchResidentPosts(
			RESIDENT_POSTS_DEFAULT_PAGE_SIZE,
			currentPageNumber
		);
	};
	
	useEffect(() => {
		fetchResidentPosts();
	}, []);

	const handlePostClick = (e, post) => {
		e.preventDefault();
		rest.mountModal(<ViewPostPage post={post} closePopup={rest.unmountModal} />);
	};

	return (
		<Fragment>
			{isResidentFetchPostsPending ? (
				<PostLoader />
			) : (
				<Fragment>
					<div class="container-fluid">
						<div class="title-bar d-flex justify-content-between">
							<div class="font-45 dblue d-bold">Noticeboard</div>
						</div>
					</div>

					<div class="post_lists">
						<div class="container-fluid">
							<div class="notices-list mt-4">
								<div class="row">
									{residentPosts &&
									residentPosts.length > 0 ? (
										residentPosts.map((post) => (
											<Post
												handleClick={handlePostClick}
												post={post}
												parentCSSClass="col-md-4 col-sm-12"
												titleCSSClass="post_title font-15 bold mb-3"
											/>
										))
									) : (
										<center>No posts available.</center>
									)}
								</div>
							</div>
						</div>
					</div>

					{meta && meta.pagecount > 1 && (
						<Pagination
							totalNumberOfPages={meta.pagecount}
							currentPageNumber={currentPageNumber}
							setCurrentPageNumber={setCurrentPageNumber}
						/>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default ResidentNoticeboardPage;
