import React from "react";
import Proptypes from "prop-types";

import { utils } from "../../utils";

const Post = ({ handleClick, post, parentCSSClass, titleCSSClass }) => (
	<div onClick={(e) => handleClick(e, post)} className={parentCSSClass}>
		<div className="post_details d-flex">
			{post.images && post.images.length > 0 && (
				<div className="post-image">
					<div className="post__image">
						<img
							width="100%"
							src={post.images[0].image}
							alt="post_image"
						/>
					</div>
				</div>
			)}
			<div
				className={`post__details ${
					(post.images && post.images[0] && post.images[0].image)
						? ""
						: "pl-40"
				}`}
			>
				<div className={titleCSSClass}>
					{utils.clipTextWithEllipsis(post.title, (post.images && post.images[0] && post.images[0].image) ? 19 : 30)}
				</div>
				<div className="post_jist font-14 light">
					{utils.clipTextWithEllipsis(post.details, (post.images && post.images[0] && post.images[0].image) ? 38 : 65)}
				</div>
				<div className="post_time font-11">{post.time_ago}</div>
			</div>
		</div>
	</div>
);

Post.propTypes = {
    post: Proptypes.array.isRequired
};

export default Post;