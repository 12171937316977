import React from "react";
import ContentLoader from "react-content-loader";

const PostLoader = (props) => (
	<div className="row">
		<ContentLoader
			speed={2}
			width={375}
			height={160}
			viewBox="0 0 375 160"
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
			{...props}
		>
			<rect x="144" y="11" rx="3" ry="3" width="120" height="10" />
			<rect x="144" y="105" rx="3" ry="3" width="60" height="8" />
			<rect x="144" y="37" rx="3" ry="3" width="220" height="8" />
			<rect x="5" y="5" rx="10" ry="10" width="114" height="114" />
			<rect x="144" y="55" rx="3" ry="3" width="220" height="8" />
			<rect x="144" y="73" rx="3" ry="3" width="150" height="8" />
		</ContentLoader>
		<ContentLoader
			speed={2}
			width={375}
			height={160}
			viewBox="0 0 375 160"
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
			{...props}
		>
			<rect x="144" y="11" rx="3" ry="3" width="120" height="10" />
			<rect x="144" y="105" rx="3" ry="3" width="60" height="8" />
			<rect x="144" y="37" rx="3" ry="3" width="220" height="8" />
			<rect x="5" y="5" rx="10" ry="10" width="114" height="114" />
			<rect x="144" y="55" rx="3" ry="3" width="220" height="8" />
			<rect x="144" y="73" rx="3" ry="3" width="150" height="8" />
		</ContentLoader>
		<ContentLoader
			speed={2}
			width={375}
			height={160}
			viewBox="0 0 375 160"
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
			{...props}
		>
			<rect x="144" y="11" rx="3" ry="3" width="120" height="10" />
			<rect x="144" y="105" rx="3" ry="3" width="60" height="8" />
			<rect x="144" y="37" rx="3" ry="3" width="220" height="8" />
			<rect x="5" y="5" rx="10" ry="10" width="114" height="114" />
			<rect x="144" y="55" rx="3" ry="3" width="220" height="8" />
			<rect x="144" y="73" rx="3" ry="3" width="150" height="8" />
		</ContentLoader>
	</div>
);

export default PostLoader;