import React, { Fragment } from 'react';
 
const FrontLayout = ({ children }) => 
  
    <Fragment>
      <section className="content">    
        <div className="container-fluid">
          <h2>Front Layout</h2>
          <div className="content">
            { children }
          </div>
          </div>
      </section>
    </Fragment>

export default FrontLayout