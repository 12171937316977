import React, { Fragment, useEffect } from "react";
import '../../../components/Auth/login.scss';
 
const AuthLayout = ({ children }) => {
  useEffect(() => {
		document.body.classList.add("login");

		return () => {
			document.body.classList.remove("login");
		};
  }, []);

  return (
		<Fragment>
			{children}
		</Fragment>
  );
}

export default AuthLayout;
