import React from "react";
import ContentLoader from "react-content-loader";

const HalfWidthRowsLoader = (props) => (
	<ContentLoader
		speed={2}
		width={500}
		height={350}
		viewBox="0 0 500 350"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		{...props}
	>
		<rect x="135" y="25" rx="5" ry="5" width="300" height="15" />
		<rect x="10" y="10" rx="10" ry="10" width="90" height="90" />
		<rect x="135" y="65" rx="5" ry="5" width="320" height="10" />
		<rect x="135" y="135" rx="5" ry="5" width="300" height="15" />
		<rect x="10" y="120" rx="10" ry="10" width="90" height="90" />
		<rect x="135" y="175" rx="5" ry="5" width="320" height="10" />
		<rect x="135" y="245" rx="5" ry="5" width="300" height="15" />
		<rect x="10" y="230" rx="10" ry="10" width="90" height="90" />
		<rect x="135" y="285" rx="5" ry="5" width="320" height="10" />
	</ContentLoader>
);

export default HalfWidthRowsLoader;
