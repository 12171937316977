import {
	SET_SUCCESS_MESSAGE,
	SET_ERROR_MESSAGE,
	SHOW_DEFAULT_ERROR_MESSAGE,
} from "./types";
import {
	DEFAULT_ERROR_MESSAGE,
	DEFAULT_NOTIFICATION_TIMEOUT,
} from "../constants";

function setSuccessMessage(bool, message) {
	return {
		type: SET_SUCCESS_MESSAGE,
		isSuccessMessage: bool,
		message: message,
		isErrorMessage: false,
	};
}

export function showSuccessMessage(message) {
	return (dispatch) => {
		dispatch(setSuccessMessage(true, message));
		setTimeout(() => {
			dispatch(setSuccessMessage(false, ""));
		}, DEFAULT_NOTIFICATION_TIMEOUT);
	};
}

export function showErrorMessage(message) {
	return (dispatch) => {
		// TODO: Update logic for default error message
		if (message === DEFAULT_ERROR_MESSAGE) {
			dispatch(showDefaultErrorMessage(true));
			setTimeout(() => {
				dispatch(showDefaultErrorMessage(false));
			}, DEFAULT_NOTIFICATION_TIMEOUT);
		} else {
			dispatch(showCustomErrorMessage(true, message));
			setTimeout(() => {
				dispatch(showCustomErrorMessage(false, ""));
			}, DEFAULT_NOTIFICATION_TIMEOUT);
		}
	};
}

export function showCustomErrorMessage(bool, message) {
	return {
		type: SET_ERROR_MESSAGE,
		isErrorMessage: bool,
		message: message,
		isSuccessMessage: false,
		isDefaultErrorMessage: false,
	};
}

// showDefaultErrorMessage has been created as context.t("default_message") won't work
// when passed from componendDidMount in case of showErrorMessage.
export function showDefaultErrorMessage(bool) {
	return {
		type: SHOW_DEFAULT_ERROR_MESSAGE,
		isDefaultErrorMessage: bool,
		isSuccessMessage: false,
	};
}