import React from "react";

import leftArrow from "../../assets/images/left-arrow.svg";
import rightArrow from "../../assets/images/right-arrow.svg";
import { PAGINATION_LENGTH } from "../../constants";

const Pagination = ({ totalNumberOfPages, currentPageNumber, setCurrentPageNumber, ...rest }) => {
	const pages = [];
	let start = 1;
	let end = totalNumberOfPages;
	if (totalNumberOfPages > PAGINATION_LENGTH) {
		start = currentPageNumber - parseInt(PAGINATION_LENGTH / 2);
		end = currentPageNumber + parseInt(PAGINATION_LENGTH / 2);
		if (start < 1) {
			start = 1;
			end = PAGINATION_LENGTH;
		}
		if (end > totalNumberOfPages) {
			end = totalNumberOfPages;
			start = end - PAGINATION_LENGTH + 1;
		}
	}
	for (let i = start; i <= end; i++){
        pages.push(i);
    }

	const goToPageNumber = (pageNo) => {
		setCurrentPageNumber(pageNo);
	};

	const goToNextPage = () => {
		goToPageNumber(currentPageNumber + 1);
	};

	const goToPreviousPage = () => {
		goToPageNumber(currentPageNumber - 1);
    };
    
	return (
		<div className="pagination">
			<ul className="list-inline">
				<li className="previous-page">
					<button
						onClick={goToPreviousPage}
						disabled={currentPageNumber === 1 ? true : false}
					>
						<img src={leftArrow} alt="leftArrow" />
					</button>
				</li>
				{/* <div> */}
					{pages.map((pageNo) => (
						<li
							className={
								currentPageNumber === pageNo
									? "current-page"
									: ""
							}
						>
							{currentPageNumber === pageNo ? (
								<button>{pageNo}</button>
							) : (
								<button onClick={() => goToPageNumber(pageNo)}>
									{pageNo}
								</button>
							)}
						</li>
					))}
				{/* </div> */}
				<li className="next-page">
					<button
						onClick={goToNextPage}
						disabled={
							currentPageNumber === totalNumberOfPages
								? true
								: false
						}
					>
						<img src={rightArrow} alt="rightArrow" />
					</button>
				</li>
			</ul>
		</div>
	);
};

export default Pagination;