import {
	SET_SUCCESS_MESSAGE,
	SET_ERROR_MESSAGE,
	SHOW_DEFAULT_ERROR_MESSAGE,
} from "../actions/types";

export default function toastComponentReducer(
	state = {
		isSuccessMessage: false,
		isErrorMessage: false,
		message: "",
	},
	action
) {
	switch (action.type) {
		case SET_SUCCESS_MESSAGE:
			return Object.assign({}, state, {
				isSuccessMessage: action.isSuccessMessage,
				isErrorMessage: action.isErrorMessage,
				message: action.message,
			});
		case SET_ERROR_MESSAGE:
			return Object.assign({}, state, {
				isErrorMessage: action.isErrorMessage,
				isSuccessMessage: action.isSuccessMessage,
				isDefaultErrorMessage: action.isDefaultErrorMessage,
				message: action.message,
			});
		case SHOW_DEFAULT_ERROR_MESSAGE:
			return Object.assign({}, state, {
				isDefaultErrorMessage: action.isDefaultErrorMessage,
				isSuccessMessage: action.isSuccessMessage,
			});
		default:
			return state;
	}
}