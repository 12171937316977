import {
	FETCH_RESIDENTS_PENDING,
	FETCH_RESIDENTS_SUCCESSFUL,
	FETCH_RESIDENT_DETAILS_PENDING,
	FETCH_RESIDENT_DETAILS_SUCCESSFUL,
	FETCH_RESIDENT_LEASE_DETAILS_PENDING,
	FETCH_RESIDENT_LEASE_DETAILS_SUCCESSFUL,
	FETCH_COURSE_TYPES_PENDING,
	FETCH_COURSE_TYPES_SUCCESSFUL,
	SET_SAVE_RESIDENT_STATUS,
	FETCH_CSV_LINK_PENDING,
	FETCH_CSV_LINK_SUCCESSFUL,
	RESET_CSV_LINK,
} from "../actions/types";

const initialState = {
	isFetchResidentsPending: false,
	residents: [],
	meta: {},
	isFetchResidentDetailsPending: false,
	selectedResidentDetails: {},
	isFetchResidentLeaseDetailsPending: false,
	selectedResidentLeaseDetails: {},
	isFetchCourseTypesPending: false,
	courseTypes: [],
	isSaveResidentSuccessful: false,
	isFetchCSVLinkPending: false,
	csvLink: "",
};

export default function residentsReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_RESIDENTS_PENDING:
			return Object.assign({}, state, {
				isFetchResidentsPending: action.payload,
			});
		case FETCH_RESIDENTS_SUCCESSFUL:
			return Object.assign({}, state, {
				residents: action.payload.result,
				meta: action.payload.meta,
			});
		case FETCH_RESIDENT_DETAILS_PENDING:
			return Object.assign({}, state, {
				isFetchResidentDetailsPending: action.payload,
			});
		case FETCH_RESIDENT_DETAILS_SUCCESSFUL:
			return Object.assign({}, state, {
				selectedResidentDetails: action.payload,
			});
		case FETCH_RESIDENT_LEASE_DETAILS_PENDING:
			return Object.assign({}, state, {
				isFetchResidentLeaseDetailsPending: action.payload,
			});
		case FETCH_RESIDENT_LEASE_DETAILS_SUCCESSFUL:
			return Object.assign({}, state, {
				selectedResidentLeaseDetails: action.payload,
			});
		case FETCH_COURSE_TYPES_PENDING:
			return Object.assign({}, state, {
				isFetchCourseTypesPending: action.payload,
			});
		case FETCH_COURSE_TYPES_SUCCESSFUL:
			return Object.assign({}, state, {
				courseTypes: action.payload,
			});
		case FETCH_CSV_LINK_PENDING:
			return Object.assign({}, state, {
				isFetchCSVLinkPending: action.payload,
			});
		case FETCH_CSV_LINK_SUCCESSFUL:
			return Object.assign({}, state, {
				csvLink: action.payload,
			});
		case RESET_CSV_LINK:
			return Object.assign({}, state, {
				csvLink: "",
			});
		case SET_SAVE_RESIDENT_STATUS:
			return Object.assign({}, state, {
				isSaveResidentSuccessful: action.payload,
			});
		default:
			return state;
	}
}
