import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AdminWifiPage from "../../../components/Admin/Wifi/AdminWifiPage";
import {
	fetchAdminWifiDetails,
	editWifiDetails,
	setEditWifiDetailsStatus,
} from "../../../actions/wifi";

const AdminWifi = ({
	wifiData,
	fetchAdminWifiDetails,
	editWifiDetails,
	setEditWifiDetailsStatus,
	...rest
}) => {
	return (
		<AdminWifiPage
			wifiData={wifiData}
			fetchAdminWifiDetails={fetchAdminWifiDetails}
			editWifiDetails={editWifiDetails}
			setEditWifiDetailsStatus={setEditWifiDetailsStatus}
		/>
	);
};

AdminWifi.propTypes = {
	wifiData: PropTypes.object.isRequired,
	fetchAdminWifiDetails: PropTypes.func.isRequired,
	editWifiDetails: PropTypes.func.isRequired,
	setEditWifiDetailsStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	wifiData: state.wifi,
});

export default connect(mapStateToProps, {
	fetchAdminWifiDetails,
	editWifiDetails,
	setEditWifiDetailsStatus,
})(AdminWifi);
