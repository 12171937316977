import React, { Fragment, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Interweave from "interweave";

import FacebookLoader from "../../Ui/FacebookLoader";
import Dropdown from "../../Ui/Dropdown";
import leftBlueArrow from "../../../assets/images/left-blue-arrow.svg";
import prev from "../../../assets/images/prev.svg";
import next from "../../../assets/images/next.svg";
import { ADMIN_ISSUES_REQUEST_STATUSES } from "../../../constants";

const AdminIssueDetailPage = ({
	issuesData: {
		selectedIssueDetails,
		isFetchIssueDetailsPending,
		hasStatusChanged,
	},
	isUserResident,
	...rest
}) => {
	const { id } = useParams();

	useEffect(() => {
		rest.fetchIssueDetails(id);
	}, []);

	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	const handleStatusDropdownChange = (e, status, id) => {
		e.preventDefault();
		rest.changeIssueStatus(id, status.value);
	};

	useEffect(() => {
		if (hasStatusChanged) {
			rest.fetchIssueDetails(id);
		}
	}, [hasStatusChanged]);

	return (
		<Fragment>
			{isFetchIssueDetailsPending ? (
				<FacebookLoader />
			) : (
				<Fragment>
					{/* {console.log(selectedIssueDetails)} */}
					{Object.keys(selectedIssueDetails).length > 0 ? (
						<Fragment>
							<div className="container-fluid">
								<div className="title-bar d-flex justify-content-between align-items-center">
									<div className="font-45 dblue d-bold">
										<Link
											to={
												isUserResident
													? "/resident/issues/history"
													: "/admin/issues"
											}
										>
											<img
												src={leftBlueArrow}
												alt="go_back"
											/>
										</Link>
										Issue #{selectedIssueDetails.id}
									</div>

									<div className="issues-search">
										<div className="issue__status search-residents d-flex align-items-center">
											{selectedIssueDetails.status && (
												<div className={`font-18`}>
													{isUserResident ? (
														ADMIN_ISSUES_REQUEST_STATUSES.find(
															(status) =>
																status.value ==
																selectedIssueDetails.status
														) && (
															<div className="row align-items-baseline">
																<div>
																	{/* <button className="edit-issue-btn font-18 white blue-bg">
																		Edit Issue
																	</button> */}
																	<Link
																		to="/resident/issues/edit"
																		className="edit-issue-link font-18"
																	>
																		Edit
																		Issue
																	</Link>
																</div>
																<div
																	className={`font-18 status-issue ${selectedIssueDetails.status} white`}
																>
																	{
																		ADMIN_ISSUES_REQUEST_STATUSES.find(
																			(
																				status
																			) =>
																				status.value ==
																				selectedIssueDetails.status
																		).label
																	}
																</div>
															</div>
														)
													) : (
														<Dropdown
															options={
																ADMIN_ISSUES_REQUEST_STATUSES
															}
															defaultSelectedOption={
																ADMIN_ISSUES_REQUEST_STATUSES.find(
																	(status) =>
																		status.value ==
																		selectedIssueDetails.status
																) ||
																ADMIN_ISSUES_REQUEST_STATUSES[0]
															}
															handleChange={
																handleStatusDropdownChange
															}
															parentCssClass={`list-unstyled customselect status-issue ${selectedIssueDetails.status} white`}
															id={
																selectedIssueDetails.id
															}
															shouldHideFirstOption={
																false
															}
														/>
													)}
												</div>
											)}
										</div>
									</div>
								</div>
							</div>

							<div className="issue-details pt-40">
								<div className="container-fluid">
									<div className="row issue-basic-details">
										<div
											className={`col-md-${
												isUserResident ? 6 : 3
											} col-sm-12`}
										>
											<div className="font-18 issue__info">
												<div className="light">
													Issue name
												</div>
												<div>
													{selectedIssueDetails.title}
												</div>
											</div>
										</div>
										<div
											className={`col-md-${
												isUserResident ? 6 : 3
											} col-sm-12`}
										>
											<div className="font-18 issue__info">
												<div className="light">
													Date submitted
												</div>
												<div>
													{
														selectedIssueDetails.requestdate
													}
												</div>
											</div>
										</div>
										{!isUserResident && (
											<Fragment>
												<div className="col-md-3 col-sm-12">
													<div className="font-18 issue__info">
														<div className="light">
															Resident
														</div>
														<div>
															{
																selectedIssueDetails.fullname
															}
														</div>
													</div>
												</div>
												<div className="col-md-3 col-sm-12">
													<div className="font-18 issue__info">
														<div className="light">
															Room
														</div>
														<div>
															{/* Level{" "}
													{selectedIssueDetails.level}
													, */}
															{/* Room{" "} */}
															{
																selectedIssueDetails.room
															}
														</div>
													</div>
												</div>
											</Fragment>
										)}
									</div>

									<div className="row issue__indetail">
										<div className="col-md-6 col-sm-12 issue_description light">
											<Interweave
												content={
													selectedIssueDetails.description
												}
											/>
										</div>
										<div className="col-md-6 col-sm-12 issue_images">
											{selectedIssueDetails.images &&
												selectedIssueDetails.images
													.length > 0 && (
													<div className="issueimages">
														<div className="next-prev-arrows">
															{currentImageIndex >
																0 && (
																<Link
																	className="prev isvisible"
																	onClick={() =>
																		setCurrentImageIndex(
																			currentImageIndex -
																				1
																		)
																	}
																>
																	<img
																		width="100%"
																		src={
																			prev
																		}
																		alt="left_arrow"
																	/>
																</Link>
															)}
															{currentImageIndex <
																selectedIssueDetails
																	.images
																	.length -
																	1 && (
																<Link
																	className="next isvisible"
																	onClick={() =>
																		setCurrentImageIndex(
																			currentImageIndex +
																				1
																		)
																	}
																>
																	<img
																		width="100%"
																		src={
																			next
																		}
																		alt="right_arrow"
																	/>
																</Link>
															)}
														</div>
														<img
															src={
																selectedIssueDetails
																	.images[
																	currentImageIndex
																].image
															}
															alt="issue_detail_image"
															// onLoadEnd={console.log("loaded successfully")}
														/>
													</div>
												)}
										</div>
									</div>
								</div>
							</div>
						</Fragment>
					) : (
						<p>Issue details not found.</p>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default AdminIssueDetailPage;