import React from "react";
import ContentLoader from "react-content-loader";

const LeaseDetailsLoader = (props) => (
	<div className="row">
		<ContentLoader
			speed={2}
			width={300}
			height={200}
			viewBox="0 0 300 200"
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
			{...props}
		>
			<rect x="212" y="36" rx="5" ry="5" width="80" height="9" />
			<rect x="7" y="28" rx="10" ry="10" width="47" height="49" />
			<rect x="157" y="28" rx="10" ry="10" width="47" height="49" />
			<rect x="58" y="42" rx="5" ry="5" width="40" height="9" />
			<rect x="111" y="42" rx="5" ry="5" width="40" height="9" />
			<rect x="7" y="96" rx="15" ry="15" width="150" height="25" />
		</ContentLoader>
	</div>
);

export default LeaseDetailsLoader;
