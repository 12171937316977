import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import Post from "../../AdminNoticeboard/Post";
import PostLoader from "../../Ui/PostLoader";
import PieChartLoader from "../../Ui/PieChartLoader";
import HalfWidthRowsLoader from "../../Ui/HalfWidthRowsLoader";
import avatar from "../../../assets/images/no_image_user.jpg";
// import graph from "../../../assets/images/graph.svg";
import graph3 from "../../../assets/images/graph3.svg";
import graph2 from "../../../assets/images/graph2.svg";
import legend1 from "../../../assets/images/legend1.svg";
import legend2 from "../../../assets/images/legend2.svg";
import "./adminDashboard.scss";
import { PieChart, Pie, Cell, LabelList } from "recharts";
import { utils } from "../../../utils";

import AddUpdatePostModal from "../../AdminNoticeboard/AddUpdatePostModal";
import DeletePostModal from "../../AdminNoticeboard/DeletePostModal";
import { ADMIN_ISSUES_FILTER_REQUEST_STATUSES, ADMIN_RESIDENT_STATUSES } from "../../../constants";

const COLORS = ["#F41938", "#FFACB7"];

const AdminDashboardPage = ({
	dashboardData: {
		isFetchLatestPostsPending,
		latestPosts,
		isFetchIssuesGraphDataPending,
		issuesGraphData,
	},
	isAdmin,
	auth: { total_notifications, shouldUpdateForcefully },
	residentsData: { residents, isFetchResidentsPending },
	...rest
}) => {
	useEffect(() => {
		rest.fetchLatestPosts();
		rest.fetchIssuesGraphData();
		let isSearching = false,
			searchString = "",
			filterValue = ADMIN_RESIDENT_STATUSES[0].value,
			pageSize = 3,
			pageNo = 1;
		rest.fetchResidents(isSearching, searchString, filterValue, pageSize, pageNo);
	}, []);

	const [graphData, setGraphData] = useState([]);

	useEffect(() => {
		if (shouldUpdateForcefully) {
			rest.fetchIssuesGraphData();
			rest.setForceUpdate(false);
		}
	}, [shouldUpdateForcefully]);

	useEffect(() => {
		if (total_notifications > 0) {
			rest.fetchIssuesGraphData();
		}
	}, [total_notifications]);

	useEffect(() => {
		if (Object.keys(issuesGraphData).length > 0) {
			const data = [
				{
					name: "Completed",
					value: parseInt(issuesGraphData.completed),
				},
				{
					name: "Unfinished",
					value:
						parseInt(issuesGraphData.total) -
						parseInt(issuesGraphData.completed),
				},
			];
			setGraphData(data);
		}
	}, [issuesGraphData]);

	const renderCustomizedLabel = ({ cx, cy, index, ...rest }) => {
		return (
			<Fragment>
				{index === 0 ? (
					<Fragment>
						<text
							className="percentage-amount"
							x={cx}
							y={cy}
							fill="red"
							textAnchor="middle"
							dominantBaseline="middle"
						>
							{`${issuesGraphData.percentage.toFixed(0)}%`}
						</text>
						<text
							className="percentage-text"
							x={cx}
							y={cy + 30}
							fill="red"
							textAnchor="middle"
							dominantBaseline="top"
						>
							Issues solved
						</text>
					</Fragment>
				) : null}
			</Fragment>
		);
	};

	const closeModal = () => {
		rest.unmountModal();
	};

	const savePost = (path, post) => {
		let fromDashboard = true;
		rest.savePost(path, post, fromDashboard);
		rest.unmountModal();
	};

	const openAddUpdatePostModal = (e, post) => {
		e.preventDefault();
		rest.mountModal(
			<AddUpdatePostModal
				post={post}
				closeModal={closeModal}
				savePost={savePost}
				deletePostImage={rest.deletePostImage}
				openDeletePostModal={openDeletePostModal}
				showErrorMessage={rest.showErrorMessage}
			/>
		);
	};

	const deletePost = (id) => {
		let fromDashboard = true;
		rest.deletePost(id, fromDashboard);
		rest.unmountModal();
	};

	const openDeletePostModal = (post) => {
		rest.unmountModal();
		rest.mountModal(
			<DeletePostModal
				post={post}
				deletePost={deletePost}
				closeModal={closeModal}
			/>
		);
	};

	// const handlePostClick = (e, post) => {
	// 	e.preventDefault();
	// 	// Do nothing
	// };

	const history = useHistory();
	const goToFilteredIssues = (filterValue) => {
		rest.setDefaultFilter(filterValue);
		history.push("/admin/issues");
	};

	return (
		<Fragment>
			<div className={"admin-dashboard"}>
				{isFetchLatestPostsPending ? (
					<PostLoader />
				) : (
					<div className="container-fluid">
						<div className="title-cta d-flex justify-content-between align-items-center">
							<div className="d-bold dblue font-28">
								Recently Added
							</div>
						</div>

						<div className="recently-added mt-4">
							<div className="row">
								{latestPosts.map((post) => (
									<Post
										handleClick={openAddUpdatePostModal}
										post={post}
										parentCSSClass="col-md-3 col-sm-12"
										titleCSSClass="post_title font-14 bold mb-1"
									/>
								))}
							</div>
						</div>
					</div>
				)}

				<div className="dashboard-jist pt-40">
					<div className="container-fluid">
						<div className="row">
							{isFetchIssuesGraphDataPending ? (
								<PieChartLoader />
							) : (
								<div className="col-md-6 col-sm-12 issues-jist">
									<div className="issues__jist dashboard-div">
										<div className="dashboard-title nomargin-bottom d-flex justify-content-between">
											<div className="font-24 d-bold dblue">
												Issues
											</div>
											{/* <div className="font-11 light">
											This week
											<img
												src={toggleArrow}
												alt="toggle_arrow"
											/>
										</div> */}
										</div>
										<div className="chart-tickets d-flex align-items-center">
											<div className="chart">
												{/* <img src={graph} alt="graph" /> */}
												<PieChart
													width={235}
													height={235}
												>
													<LabelList
													// content={<h2>80%</h2>}
													/>
													<Pie
														data={graphData}
														// cx={200}
														// cy={200}
														labelLine={false}
														label={
															renderCustomizedLabel
														}
														position="center"
														outerRadius={"98%"}
														innerRadius={"75%"}
														fill="#8884d8"
														stroke="0"
														isAnimationActive={
															false
														}
														// dataKey="value"
													>
														{graphData.map(
															(entry, index) => (
																<Cell
																	key={`cell-${index}`}
																	fill={
																		COLORS[
																			index %
																				COLORS.length
																		]
																	}
																/>
															)
														)}
													</Pie>
												</PieChart>
											</div>
											<div className="tickets">
												<div className="text-center font-28 d-bold dblue">
													{issuesGraphData.total}{" "}
													Tickets
												</div>
												<div className="ticket-status d-flex justify-content-center">
													<div
														className="ticket_status white text-center"
														onClick={() =>
															goToFilteredIssues(
																ADMIN_ISSUES_FILTER_REQUEST_STATUSES[2]
															)
														}
													>
														<div className="bold font-11">
															New issues
														</div>
														<div className="d-bold font-45">
															{
																issuesGraphData.new
															}
														</div>
													</div>
													<div
														className="ticket_status white text-center"
														onClick={() =>
															goToFilteredIssues(
																ADMIN_ISSUES_FILTER_REQUEST_STATUSES[3]
															)
														}
													>
														<div className="bold font-11">
															In progress
														</div>
														<div className="d-bold font-45">
															{
																issuesGraphData.inprogress
															}
														</div>
													</div>
													{/* <div className="ticket_status white text-center">
														<div className="bold font-11">
															Pending
														</div>
														<div className="d-bold font-45">
															{
																issuesGraphData.inprogress
															}
														</div>
													</div> */}
													<div
														className="ticket_status white text-center"
														onClick={() =>
															goToFilteredIssues(
																ADMIN_ISSUES_FILTER_REQUEST_STATUSES[4]
															)
														}
													>
														<div className="bold font-11">
															Completed
														</div>
														<div className="d-bold font-45">
															{
																issuesGraphData.completed
															}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							{isAdmin ? (
								!isFetchIssuesGraphDataPending && null
								// (
								// 	<div className="col-md-6 col-sm-12 account-jist">
								// 		<div className="account__jist dashboard-div">
								// 			<div className="dashboard-title nomargin-bottom d-flex justify-content-between">
								// 				<div className="font-24 d-bold dblue">
								// 					Account
								// 				</div>
								// 				{/* <div className="font-11 light">
								// 					This month{" "}
								// 					<img src={toggleArrow} alt="toggle_arrow" />
								// 				</div> */}
								// 			</div>
								// 			<div className="chart-tickets d-flex align-items-center">
								// 				<div className="chart">
								// 					<img
								// 						className="height-230"
								// 						src={graph3}
								// 						alt="graph"
								// 					/>
								// 				</div>
								// 				<div className="account-status d-flex">
								// 					<div className="account_status">
								// 						<div className="bold font-12">
								// 							Rent Oustanding
								// 						</div>
								// 						<div className="font-45 d-bold">
								// 							$2,009.00
								// 						</div>
								// 					</div>
								// 					<div className="account_status">
								// 						<div className="bold font-12">
								// 							Unpaid
								// 						</div>
								// 						<div className="font-45 d-bold">
								// 							19%
								// 						</div>
								// 					</div>
								// 					<div className="account_status">
								// 						<div className="bold font-12">
								// 							Rent Collected
								// 						</div>
								// 						<div className="font-45 d-bold">
								// 							$19,400.00
								// 						</div>
								// 					</div>
								// 					<div className="account_status">
								// 						<div className="bold font-12">
								// 							Collected
								// 						</div>
								// 						<div className="font-45 d-bold">
								// 							81%
								// 						</div>
								// 					</div>
								// 				</div>
								// 			</div>
								// 		</div>
								// 	</div>
								// )
							) : isFetchResidentsPending ? (
								<HalfWidthRowsLoader />
							) : (
								<div className="col-md-6 col-sm-12">
									<div className="account__jist dashboard-div">
										<div className="dashboard-title d-flex justify-content-between mb-10">
											<div className="font-24 d-bold dblue">
												Recent residents
											</div>
											{/* <div className="font-13 light">
														This month{" "}
														<img src={toggleArrowIcon} />
													</div> */}
										</div>
										<div className="recent-residents">
											{residents &&
												residents.length > 0 &&
												residents.map((resident) => (
													<div className="resident d-flex justify-content-between align-items-center">
														<div className="resident_image">
															<div className="resident__image">
																<img
																	src={
																		resident.image ||
																		avatar
																	}
																	alt="profile_picture"
																/>
															</div>
														</div>
														<div className="resident_name">
															<div className="font-20">
																<Link
																	to={`/admin/residents/${resident.id}`}
																	className="text-underline purple bold"
																>
																	{
																		utils.clipTextWithEllipsis(resident.fullname,19)
																	}
																</Link>
															</div>
														</div>
														<div className="resident_phone-email">
															<a
																href={`tel:+${resident.phone}`}
																className="black-color font-18"
															>
																{resident.phone &&
																	"+" + utils.formatPhoneNumber(
																		resident.phone
																	)}
															</a>
															<a
																href={`mailto:${resident.email}`}
																className="black-color font-18"
															>
																{resident.email}
															</a>
														</div>
														<div className="resident_room">
															<div className="bold text-uppercase">
																ROOM
															</div>
															<div className="regular">
																{resident.room}
															</div>
														</div>
														<div className="resident_start">
															<div className="bold text-uppercase">
																START
															</div>
															<div className="regular">
																{
																	resident.startdate
																}
															</div>
														</div>
														<div className="resident_end">
															<div className="bold text-uppercase">
																END
															</div>
															<div className="regular">
																{
																	resident.enddate
																}
															</div>
														</div>
													</div>
												))}
										</div>
									</div>
								</div>
							)}

							{/* {!isFetchIssuesGraphDataPending && (
								<div className="col-sm-12 occupency pt-40">
									<div className="account__jist dashboard-div">
										<div className="dashboard-title d-flex justify-content-between">
											<div className="font-24 d-bold dblue">
												OCCUPANCY METRICS
											</div>
											{/* <div className="font-11 light">
											This week{" "}
                                 			<img src={toggleArrow} alt="toggle_arrow" />
										</div> *}
										</div>
										<div className="chart-tickets occupency-data  d-flex align-items-center justify-content-between">
											<div className="chart">
												<img src={graph2} alt="graph" />
											</div>
											<div className="occupency__data">
												<div className="occupency__status d-flex">
													<div className="occupency_status">
														<div className="bold font-12">
															Vacant
														</div>
														<div className="font-45 d-bold">
															89
														</div>
													</div>
													<div className="occupency_status">
														<div className="bold font-12">
															Occupied
														</div>
														<div className="font-45 d-bold">
															45
														</div>
													</div>
												</div>
												<div className="occupency-legend d-flex">
													<div className="occupency_legend">
														<div className="font-11">
															<img
																src={legend1}
																alt="legend"
															/>
															Available
														</div>
													</div>
													<div className="account_legend">
														<div className="font-11">
															<img
																src={legend2}
																alt="legend"
															/>{" "}
															Occupied
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							)} */}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};
 
export default AdminDashboardPage;
