import React, { Fragment, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";

import leftBlueArrow from "../../../assets/images/left-blue-arrow.svg";
import avatarBg from "../../../assets/images/avatar-bg.svg";
import avatar from "../../../assets/images/no_image_user.jpg";
import phone from "../../../assets/images/phone.svg";
import email from "../../../assets/images/email.svg";
import address from "../../../assets/images/address.svg";
import date from "../../../assets/images/date.svg";
import institute from "../../../assets/images/institute.svg";
import room from "../../../assets/images/room.svg";
import documentIcon from "../../../assets/images/document.svg";
import { utils } from "../../../utils";

const AdminResidentDetailPage = ({
	residentsData: { selectedResidentDetails, isFetchResidentDetailsPending },
	isAdmin,
	...rest
}) => {
	const { id } = useParams();
	const history = useHistory();

	useEffect(() => {
		if (!utils.isNumeric(id)) {
			history.push("/admin/residents");
		} else {
			rest.fetchResidentDetails(id);
		}
	}, []);

	const goTo = (path) => {
		history.push(path);
	};

	const openDocument = (url) => {
		window.open(url, "_blank");
	};

	return (
		<Fragment>
			<div className="container-fluid">
				<div className="admin-resident-detail title-bar d-flex justify-content-between align-items-center">
					<div className="font-45 dblue d-bold">
						<Link to="/admin/residents">
							<img src={leftBlueArrow} alt="go back" />
						</Link>
						{!isFetchResidentDetailsPending && (
							<span>
								{selectedResidentDetails.first_name}{" "}
								{selectedResidentDetails.last_name}
							</span>
						)}
					</div>
					<Link
						to="/admin/residents/update"
						className="red-bg white bold curved-btn"
						disabled
					>
						Edit
					</Link>
				</div>
			</div>

			<div className="resident_list pt-40">
				<div className="container-fluid">
					<div className="resident-information d-flex justify-content-between align-content-center">
						<div className="resident-photo">
							<div className="resident_photo position-relative">
								<div className="avatar__bg position-absolute">
									<img src={avatarBg} alt="avatar_bg" />
								</div>
								<div className="avatar_image position-relative">
									<img
										src={selectedResidentDetails.image || avatar}
										alt="resident_image"
									/>
								</div>
							</div>
						</div>
						<div className="resident__details">
							<div className="resident-details d-flex justify-content-between align-items-center">
								<div className="detail-div d-flex align-items-center">
									<div className="detail-icon">
										<img src={phone} alt="phone" />
									</div>
									<div className="detail-detail font-20">
										{selectedResidentDetails.phone_number && (
											<a
												href={`tel:+${selectedResidentDetails.phone_country_code}${selectedResidentDetails.phone_area_code}${selectedResidentDetails.phone_number}`}
											>
												{selectedResidentDetails.phone_number &&
													// utils.formatPhoneNumber(
													// 	selectedResidentDetails.phone
													// )}
													"+" +
														selectedResidentDetails.phone_country_code +
														" " +
														selectedResidentDetails.phone_area_code +
														" " +
														selectedResidentDetails.phone_number}
											</a>
										)}
									</div>
								</div>
								<div className="detail-div d-flex align-items-center">
									<div className="detail-icon">
										<img src={email} alt="email" />
									</div>
									<div className="detail-detail font-20">
										<a href={`mailto:${selectedResidentDetails.email}`}>
											{selectedResidentDetails.email}
										</a>
									</div>
								</div>
								<div className="detail-div d-flex align-items-center">
									<div className="detail-icon">
										<img src={address} alt="address" />
									</div>
									<div className="detail-detail font-20">
										{/* <div>
											<b>Postal Address: </b>
											{selectedResidentDetails.postal_address_string &&
												selectedResidentDetails.postal_address_string}
										</div> */}
										<div>
											{/* <b>Current Address: </b> */}
											{selectedResidentDetails.current_address_line1 &&
												selectedResidentDetails.current_address_line1}
											{selectedResidentDetails.current_address_line2 &&
												", " + selectedResidentDetails.current_address_line2}
											{selectedResidentDetails.current_address_line3 &&
												", " + selectedResidentDetails.current_address_line3}
											{selectedResidentDetails.current_address_line4 &&
												", " + selectedResidentDetails.current_address_line4}
											{selectedResidentDetails.current_address_city &&
												", " + selectedResidentDetails.current_address_city}
											{selectedResidentDetails.current_address_postal_code &&
												" - " +
													selectedResidentDetails.current_address_postal_code}
											{selectedResidentDetails.current_address_country &&
												", " + selectedResidentDetails.current_address_country}
										</div>
									</div>
								</div>
								<div className="detail-div d-flex align-items-center">
									<div className="detail-icon">
										<img src={date} alt="date" />
									</div>
									<div className="detail-detail font-20">
										{selectedResidentDetails.dob}
									</div>
								</div>
								<div className="detail-div d-flex align-items-center">
									<div className="detail-icon">
										<img src={institute} alt="institute" />
									</div>
									<div className="detail-detail font-20">
										{selectedResidentDetails.education ||
											selectedResidentDetails.organizationname}
									</div>
								</div>
								<div className="detail-div d-flex align-items-center">
									<div className="detail-icon">
										<img src={room} alt="room" />
									</div>
									<div className="detail-detail font-20">
										{selectedResidentDetails.room}
									</div>
								</div>
							</div>
						</div>
					</div>

					{isAdmin && (
						<div className="collection-lease-data pt-40 d-flex justify-content-start align-items-stretch">
							{/* <div className="collection-data collection-data-disabled">
								<div className="collection-lease-title dblue d-bold font-24">
									Collection
								</div>
								<div className="collection__data d-flex justify-content-between">
									<div className="invoices">
										<div className="font-18">
											<div className="bold">Current</div>
											<div>Invoices4566</div>
										</div>
										<div className="font-18">
											<div className="bold">Current</div>
											<div>Invoices4566</div>
										</div>
									</div>
									<div className="all-invoices">
										<div className="font-18">
											<div className="bold">Total Rent Collected</div>
											<div>$4,500.00</div>
										</div>

										<a href="" className="curved-btn white bold font-18">
											View All Invoices
										</a>
									</div>
								</div>
							</div> */}
							<div className="lease-data">
								<div className="collection-lease-title dblue d-bold font-24">
									Lease
								</div>
								<div className="lease__data d-flex">
									<div className="font-18 mr-30">
										<div className="bold">Start</div>
										<div>{selectedResidentDetails.startdate}</div>
									</div>
									<div className="font-18 mr-30">
										<div className="bold">End</div>
										<div>{selectedResidentDetails.enddate}</div>
									</div>

									<div className="font-18 mr-30">
										<div className="bold">Fortnightly rent</div>
										<div>{selectedResidentDetails.fortnightlyrent}</div>
									</div>
								</div>

								<button
									// to={`/admin/residents/${id}/lease`}
									onClick={() => goTo(`/admin/residents/${id}/lease`)}
									className="curved-btn white bold font-18"
								>
									View Lease Details
								</button>
							</div>
							<div className="lease-data emergency-contact-data">
								<div className="collection-lease-title dblue d-bold font-24">
									Emergency Contact Details
								</div>
								<div className="lease__data d-flex">
									<div className="font-18 mr-30">
										<div className="bold">Name</div>
										<div>{selectedResidentDetails.emergency_contact_name}</div>
									</div>
									<div className="font-18 mr-30">
										<div className="bold">Mobile / Phone</div>
										<div>
											<a
												href={`tel:+${selectedResidentDetails.emergency_contact_phone_country_code}${selectedResidentDetails.emergency_contact_phone_area_code}${selectedResidentDetails.emergency_contact_phone_number}`}
											>
												{selectedResidentDetails.emergency_contact_phone_number &&
													"+" +
														selectedResidentDetails.emergency_contact_phone_country_code +
														" " +
														selectedResidentDetails.emergency_contact_phone_area_code +
														" " +
														selectedResidentDetails.emergency_contact_phone_number}
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

					<div className="tenant-documents pt-40">
						<div className="dblue d-bold font-24">Documents</div>
						<div className="documents d-flex">
							{Array.isArray(selectedResidentDetails.documents) &&
							selectedResidentDetails.documents.length > 0 ? (
								selectedResidentDetails.documents.map((document) => (
									<div
										className="document-div d-flex align-items-center"
										onClick={() => openDocument(document.file_url)}
									>
										<div className="document-icon mr-2">
											<img src={documentIcon} alt="document" />
										</div>
										<div className="document-title font-20 light">
											{/* {utils.getFileNameFromUrl(
													document.file_url
												)} */}
											{document.file_name}
										</div>
									</div>
								))
							) : (
								<div>No documents found.</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default AdminResidentDetailPage;
