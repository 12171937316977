import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import ValidationMessage from "../../Ui/ValidationMessage";
import "./adminProfile.scss";
import {
	ALLOWED_IMAGE_FILE_EXTENSIONS,
	MAX_ALLOWED_PROFILE_IMAGE_SIZE,
	EMAIL_PATTERN,
	PHONE_NUMBER_PATTERN,
} from "../../../constants";
import avatar from "../../../assets/images/no_image_user.png";
import addMedia from "../../../assets/images/add-media.svg";

const AdminProfilePage = ({
	auth: { user, isEditAdminProfileSuccessful },
	logout,
	showErrorMessage,
	setEditAdminProfileStatus,
	saveProfile,
	deleteProfilePicture,
}) => {
	const initialFormData = {
		name: user.fullname,
		email: user.email,
		phone: user.phone,
		telephone: user.telephone,
	};
	const [formData, setFormData] = useState(initialFormData);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	const initialFormErrors = {
		name: "",
		email: "",
		phone: "",
		telephone: "",
	};
	const [formErrors, setFormErrors] = useState(initialFormErrors);

	const initialFileUploadData = {
		fileName: "",
		file: null,
		fileUrl: user.profile_picture,
	};
	const [fileUploadData, setFileUploadData] = useState(initialFileUploadData);

	const history = useHistory();
	const goToHome = () => {
		history.push("/admin/dashboard");
	};
	useEffect(() => {
		if (isEditAdminProfileSuccessful) {
			setEditAdminProfileStatus(false);
			goToHome();
		}
	}, [isEditAdminProfileSuccessful]);

	const [isUpdated, setIsUpdated] = useState(false);

	const isObjectIdentical = (object1, object2) => {
		return JSON.stringify(object1) === JSON.stringify(object2);
	};

	const checkIfUpdated = () => {
		if (
			!isObjectIdentical(formData, initialFormData) ||
			!isObjectIdentical(fileUploadData, initialFileUploadData)
		) {
			setIsUpdated(true);
		} else {
			setIsUpdated(false);
		}
	};

	useEffect(() => {
		checkIfUpdated();
	}, [formData, fileUploadData]);

	const handleInputChange = (e) => {
		if (e.target.name == "phone" || e.target.name == "telephone") {
			if (/^\d*$/.test(e.target.value)) {
				setFormData({
					...formData,
					[e.target.name]: e.target.value,
				});
			}
		} else {
			setFormData({
				...formData,
				[e.target.name]: e.target.value,
			});
		}
	};

	const onCloseImagePreview = () => {
		setFileUploadData({
			...initialFileUploadData,
			fileUrl: "",
		});
	};

	const handleFileInputChange = (e) => {
		const extension = e.target.files[0].name.split(".").pop();
		if (ALLOWED_IMAGE_FILE_EXTENSIONS.includes(extension)) {
			if (e.target.files[0].size > MAX_ALLOWED_PROFILE_IMAGE_SIZE) {
				showErrorMessage("Maximum file size allowed is 12 MB.");
				onCloseImagePreview();
			} else {
				setFileUploadData({
					...fileUploadData,
					file: e.target.files[0],
					fileName: e.target.files[0].name,
				});
			}
		} else {
			showErrorMessage("Please upload a valid file format.");
			onCloseImagePreview();
		}
	};

	useEffect(() => {
		if (isFormSubmitted) {
			isFormValid();
		}
	}, [formData]);

	const isFormValid = () => {
		let updatedFormErrors = formErrors;
		let isFormValid = true;
		Object.keys(formErrors).forEach((field) => {
			switch (field) {
				case "name":
					if (!formData.name) {
						updatedFormErrors[field] = "Please enter your name";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "email":
					if (!formData.email) {
						updatedFormErrors[field] = "Please enter your email";
						isFormValid = false;
					} else if (formData.email.length > 100) {
						updatedFormErrors[field] =
							"Please enter a valid email address";
						isFormValid = false;
					} else if (!formData.email.match(EMAIL_PATTERN)) {
						updatedFormErrors[field] =
							"Please enter a valid email address";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "phone":
					if (!formData.phone) {
						updatedFormErrors[field] =
							"Please enter your mobile number";
						isFormValid = false;
					} else if (!formData.phone.match(PHONE_NUMBER_PATTERN)) {
						updatedFormErrors[field] =
							"Please enter a valid mobile number";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "telephone":
					if (!formData.telephone) {
						updatedFormErrors[field] =
							"Please enter your telephone number";
						isFormValid = false;
					} else if (!formData.telephone.match(PHONE_NUMBER_PATTERN)) {
						updatedFormErrors[field] =
							"Please enter a valid telephone number";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				default:
					break;
			}
		});
		if (isFormValid) {
			updatedFormErrors = initialFormErrors;
		}
		setFormErrors({ ...formErrors, ...updatedFormErrors });
		return isFormValid;
	};

	const createProfileData = () => {
		let data = new FormData();
		data.append("fullname", formData.name);
		data.append("email", formData.email);
		data.append("phone", formData.phone);
		data.append("telephone", formData.telephone);
		if (fileUploadData.file) {
			data.append(
				"profile_picture",
				fileUploadData.file,
				fileUploadData.fileName
			);
		}
		return data;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setIsFormSubmitted(true);
		if (isFormValid()) {
			if (
				initialFileUploadData.fileUrl &&
				!fileUploadData.fileUrl &&
				!fileUploadData.file
			) {
				deleteProfilePicture();
			}
			saveProfile(createProfileData());
		}
	};

	return (
		<Fragment>
			<div className="container-fluid profile">
				<div className="title-bar d-flex justify-content-between">
					<div className="font-45 dblue d-bold">
						Hello {user.fullname}
					</div>
					<div className="row align-items-center">
						<Link
							to="/admin/edit-password"
							className="curved-btn blue-bg bold white"
						>
							Edit Password
						</Link>
						<button
							className="curved-btn logout-btn red bold"
							onClick={logout}
						>
							Logout
						</button>
					</div>
				</div>
			</div>

			<div className="profile-details">
				<div className="container-fluid">
					<form onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-md-8">
								<div className="row">
									<div className="col-md-12">
										<div className="lp-fields position-relative d-flex align-items-center">
											<img
												id="ImgPreview"
												src={
													(fileUploadData.file &&
														URL.createObjectURL(
															fileUploadData.file
														)) ||
													fileUploadData.fileUrl ||
													avatar
												}
												alt="profile_image"
												className="preview1 it"
											/>
											<span className="btn_upload">
												<input
													type="file"
													id="imag"
													title=""
													className="input-img"
													onChange={
														handleFileInputChange
													}
												/>
												<img
													src={addMedia}
													alt="file_upload_icon"
												/>
												Change image
											</span>
											{(fileUploadData.file ||
												fileUploadData.fileUrl) && (
												<input
													type="button"
													id="removeImage1"
													value="x"
													className="btn-rmv1 rmv"
													onClick={
														onCloseImagePreview
													}
												/>
											)}
										</div>
									</div>
									<div className="col-md-4 col-sm-12">
										<div className="font-18">Name</div>
										<div className="lp-fields">
											<input
												type="text"
												name="name"
												value={formData.name}
												onChange={handleInputChange}
											/>
											{isFormSubmitted &&
												formErrors.name && (
													<ValidationMessage id="nameHelp">
														{formErrors.name}
													</ValidationMessage>
												)}
										</div>
									</div>
									<div className="col-md-4 col-sm-12">
										<div className="font-18">Email</div>
										<div className="lp-fields">
											<input
												type="email"
												name="email"
												value={formData.email}
												onChange={handleInputChange}
												// disabled
											/>
											{isFormSubmitted &&
												formErrors.email && (
													<ValidationMessage id="emailHelp">
														{formErrors.email}
													</ValidationMessage>
												)}
										</div>
									</div>
									<div className="col-md-4 col-sm-12">
										<div className="font-18">Mobile</div>
										<div className="lp-fields">
											<input
												type="tel"
												name="phone"
												value={formData.phone}
												onChange={handleInputChange}
											/>
											{isFormSubmitted &&
												formErrors.phone && (
													<ValidationMessage id="phoneHelp">
														{formErrors.phone}
													</ValidationMessage>
												)}
										</div>
									</div>
									<div className="col-md-4 col-sm-12">
										<div className="font-18">Telephone</div>
										<div className="lp-fields">
											<input
												type="tel"
												name="telephone"
												value={formData.telephone}
												onChange={handleInputChange}
											/>
											{isFormSubmitted &&
												formErrors.telephone && (
													<ValidationMessage id="telephoneHelp">
														{formErrors.telephone}
													</ValidationMessage>
												)}
										</div>
									</div>
									<div className="col-md-6 col-sm-12">
										{/* <div className="font-18">Edit password</div> */}
										{/* <div className="lp-fields position-relative"> */}
										{/* <input
												type="password"
												value="123456"
											/> */}
										{/* <span className="edit-password blue-bg bold white">
												Edit Password
											</span> */}
										{/* </div> */}
										{/* <Link
											to="/admin/edit-password"
											className="curved-btn blue-bg bold white"
										>
											Edit Password
										</Link> */}
									</div>
									<div className="col-md-12 d-flex pt-80">
										<button
											type="submit"
											className="curved-btn red-bg white bold"
											disabled={!isUpdated}
										>
											Save
										</button>
										<button
											className="curved-btn cancel red bold"
											onClick={goToHome}
										>
											Cancel
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</Fragment>
	);
};

export default AdminProfilePage;
