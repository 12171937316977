import React, { useState, useEffect } from "react";

const Dropdown = ({
	options,
	defaultSelectedOption,
	handleChange,
	parentCssClass,
	id,
	shouldHideFirstOption,
}) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [selectedDropdownOption, setSelectedDropdownOption] = useState(
		defaultSelectedOption
	);

	const handleDropdownChange = (e, option) => {
		setSelectedDropdownOption(option);
		handleChange(e, option, id);
	};

	useEffect(() => {
		setSelectedDropdownOption(defaultSelectedOption);
	}, [defaultSelectedOption]);

	return (
		<ul
			className={
				isDropdownOpen ? `${parentCssClass} show` : `${parentCssClass}`
			}
			onClick={() => setIsDropdownOpen(!isDropdownOpen)}
		>
			<li className="init">{selectedDropdownOption.label}</li>
			{shouldHideFirstOption
				? options.map(
						(option, index) =>
							index > 0 && (
								<li
									onClick={(e) =>
										handleDropdownChange(e, option)
									}
								>
									{option.label}
								</li>
							)
				  )
				: options.map((option) => (
						<li onClick={(e) => handleDropdownChange(e, option)}>
							{option.label}
						</li>
				  ))}
		</ul>
	);
};

export default Dropdown;