import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import ValidationMessage from "../../Ui/ValidationMessage";
import LuxpodSuccessMessage from "../../Ui/LuxpodSuccessMessage";
import {
	ALLOWED_IMAGE_FILE_EXTENSIONS,
	MAX_ALLOWED_POST_IMAGE_SIZE,
	DEFAULT_NOTIFICATION_TIMEOUT,
} from "../../../constants";
import historyIcon from "../../../assets/images/history.svg";
import man from "../../../assets/images/man.svg";
import girl from "../../../assets/images/girl.svg";
import uploadIcon from "../../../assets/images/upload.svg";
import leftBlueArrow from "../../../assets/images/left-blue-arrow.svg";

const ReportIssuePage = ({
	issuesData: {
		selectedIssueDetails,
		isSaveIssuePending,
		saveIssueStatus,
		// unreadIssuesCount,
	},
	residentData: { resident_id },
	authData: { total_notifications },
	isInEditIssue,
	...rest
}) => {
	const history = useHistory();
	const goToIssueHistory = () => {
		history.push("/resident/issues/history");
	};
	const goBack = (e) => {
		if (e) {
			e.preventDefault();
		}
		window.history.go(-1);
	};

	if (isInEditIssue && !(selectedIssueDetails && selectedIssueDetails.id)) {
		goBack();
	}

	const [isEditing, setIsEditing] = useState(
		isInEditIssue && (selectedIssueDetails && selectedIssueDetails.id)
			? true
			: false
	);

	const goBackToReportIssue = () => {
		rest.setSaveIssueStatus(false);
		history.push("/resident/issues");
	};
	const goBackToIssueDetails = () => {
		rest.setSaveIssueStatus(false);
		history.push(`/resident/issues/${selectedIssueDetails.id}`);
	};
	useEffect(() => {
		if (!isEditing && saveIssueStatus) {
			setTimeout(() => {
				goBackToReportIssue();
			}, DEFAULT_NOTIFICATION_TIMEOUT);
		}
	}, [saveIssueStatus]);

	const initialFormData = {
		issueTitle: isEditing ? selectedIssueDetails.title : "",
		issueDescription: isEditing ? selectedIssueDetails.description : "",
	};
	const [formData, setFormData] = useState(initialFormData);
	const initialFormErrors = {
		issueTitle: "",
		issueDescription: "",
	};
	const handleInputChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const [formErrors, setFormErrors] = useState(initialFormErrors);

	// const initialFileData = {
	// 	fileName: "",
	// 	file: null,
	// };

	const [counterForDocument, setCounterForDocument] = useState(0);
	const getCurrentDocuments = () => {
		const currentDocuments = [];
		selectedIssueDetails.images.forEach((image) => {
			currentDocuments.push({
				id: image.id,
				name: image.image,
				fileUrl: image.image,
			});
		});
		return currentDocuments;
	};
	// TODO: Rename documents to images
	const [documents, setDocuments] = useState(
		isEditing ? getCurrentDocuments() : []
	);
	const [documentsToBeDeleted, setDocumentsToBeDeleted] = useState([]);

	const handleFileUpload = (e) => {
		let validFiles = [];
		const files = [...e.target.files];
		files.forEach((file) => {
			const extension = file.name.split(".").pop();
			if (ALLOWED_IMAGE_FILE_EXTENSIONS.includes(extension)) {
				if (file.size > MAX_ALLOWED_POST_IMAGE_SIZE) {
					// showErrorMessage("Maximum file size allowed is 12 MB.");
				} else {
					let validFile = {
						index: counterForDocument,
						name: file.name,
						file: file,
						fileUrl: "",
					};
					setCounterForDocument(counterForDocument + 1);
					validFiles.push(validFile);
				}
			} else {
				// showErrorMessage("Please upload a valid image format.");
			}
		});
		setDocuments((documents) => [...documents, ...validFiles]);
	};
	// useEffect(() => {
	// 	console.log("documents ", documents);
	// }, [documents]);

	const removeDocument = ({ id, index }) => {
		if (id) {
			setDocuments(documents.filter((document) => document.id != id));
			setDocumentsToBeDeleted([
				...documentsToBeDeleted,
				documents.find((document) => document.id == id),
			]);
		}
		if (index || index === 0) {
			setDocuments(
				documents.filter((document) => document.index !== index)
			);
		}
	};

	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	useEffect(() => {
		if (isFormSubmitted) {
			isFormValid();
		}
	}, [formData]);

	const isFormValid = () => {
		let updatedFormErrors = formErrors;
		let isFormValid = true;
		Object.keys(formErrors).forEach((field) => {
			switch (field) {
				case "issueTitle":
					if (!formData.issueTitle) {
						updatedFormErrors[field] = "Please enter issue title";
						isFormValid = false;
					} else if (formData.issueTitle.length > 100) {
						updatedFormErrors[field] =
							"Issue title should have a maximum of 100 characters";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "issueDescription":
					if (!formData.issueDescription) {
						updatedFormErrors[field] =
							"Please enter issue description";
						isFormValid = false;
					} else if (formData.issueDescription.length > 1000) {
						updatedFormErrors[field] =
							"Issue description should have a maximum of 1000 characters";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				default:
					break;
			}
		});
		if (isFormValid) {
			updatedFormErrors = initialFormErrors;
		}
		setFormErrors({ ...formErrors, ...updatedFormErrors });
		return isFormValid;
	};

	const createIssueData = () => {
		let data = new FormData();
		data.append("tenant_id", resident_id);
		if (isEditing) {
			data.append("issue_id", selectedIssueDetails.id);
		}
		data.append("title", formData.issueTitle);
		data.append("description", formData.issueDescription);
		// if (fileData.file) {
		// 	data.append("images[]", fileData.file, fileData.fileName);
		// }
		documents.forEach((document) => {
			if (document.file) {
				data.append("images[]", document.file, document.name);
			}
		});
		// for (let pair of data.entries()) {
		// 	console.log(pair[0] + ", " + pair[1]);
		// }
		return data;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setIsFormSubmitted(true);
		if (isFormValid()) {
			rest.saveIssue(
				createIssueData(),
				isEditing ? "editissue" : "createissue"
			);
			if (isEditing) {
				if (documentsToBeDeleted.length > 0) {
					documentsToBeDeleted.forEach((document) => {
						let data = new FormData();
						data.append("id", document.id);
						rest.deleteResidentIssueImage(data);
					});
				}
			}
		}
	};

	return (
		<Fragment>
			<div className="container-fluid">
				<div className="title-bar d-flex justify-content-between">
					<div className="font-45 dblue d-bold">
						{isEditing ? (
							<Fragment>
								<Link onClick={goBack}>
									<img src={leftBlueArrow} alt="go_back" />
								</Link>
								Edit Issue
							</Fragment>
						) : (
							"Report Issue"
						)}
					</div>
					{!isEditing && (
						<div className="my-history">
							<Link
								to="/resident/issues/history"
								className="red-bg white bold font-18 curved-btn"
							>
								<img src={historyIcon} alt="history_icon" />
								Issues Lodged
								{total_notifications > 0 && (
									<span className="badge badge-light">
										{total_notifications}
									</span>
								)}
							</Link>
						</div>
					)}
				</div>
			</div>

			<div className="report-issue pt-40">
				<div className="container-fluid">
					<div className="absolute-images">
						<img className="man" src={man} alt="artwork_man" />
						<img className="girl" src={girl} alt="artwork_girl" />
					</div>

					<div className="row">
						{saveIssueStatus ? (
							<LuxpodSuccessMessage
								message={`Thank you, your issue has been successfully ${
									isEditing ? "updated" : "submitted"
								} and our building manager will be in touch.`}
								goBackToDefaultScreen={
									isEditing ? goBackToIssueDetails : null
								}
								returnTo={isEditing ? "Issue Details" : null}
							/>
						) : (
							<div className="col-md-10">
								<div className="add-post-form">
									<form onSubmit={handleSubmit}>
										<div className="lp-fields">
											<label>Issue title</label>
											<br />
											<input
												type="text"
												placeholder="Issue title"
												name="issueTitle"
												value={formData.issueTitle}
												onChange={handleInputChange}
											/>
											{isFormSubmitted &&
												formErrors.issueTitle && (
													<ValidationMessage id="issueTitleHelp">
														{formErrors.issueTitle}
													</ValidationMessage>
												)}
										</div>
										<div className="lp-fields">
											<label>Issue description</label>
											<br />
											<textarea
												placeholder="Issue description"
												name="issueDescription"
												value={
													formData.issueDescription
												}
												onChange={handleInputChange}
											></textarea>
											{isFormSubmitted &&
												formErrors.issueDescription && (
													<ValidationMessage id="issueDescriptionHelp">
														{
															formErrors.issueDescription
														}
													</ValidationMessage>
												)}
										</div>
										<div className="lp-fields position-relative">
											<label>Upload pictures</label>
											<br />
											<span className="btn_upload">
												{documents.length > 0 &&
													documents.map(
														(document) => (
															<Fragment>
																<img
																	id="ImgPreview"
																	src={
																		document.fileUrl ||
																		(document.file &&
																			URL.createObjectURL(
																				document.file
																			))
																	}
																	alt="uploaded_image_preview"
																	className="preview1"
																/>
																<button
																	type="button"
																	id="removeImage1"
																	value="x"
																	className="btn-rmv1"
																	onClick={() =>
																		removeDocument(
																			{
																				id:
																					document.id,
																				index:
																					document.index,
																			}
																		)
																	}
																/>
															</Fragment>
														)
													)}
												<img
													className="upload-btn"
													src={uploadIcon}
													alt="upload_icon"
												/>
												<input
													type="file"
													id="imag"
													title=""
													className="input-img"
													onChange={handleFileUpload}
													multiple
												/>
											</span>
										</div>
										<div className="row">
											<div className="lp-fields">
												<input
													type="submit"
													value="Save"
													disabled={
														isSaveIssuePending
													}
												/>
											</div>
											{isEditing && (
												<div className="lp-fields">
													<button
														type="button"
														className="curved-btn cancel white-bg red bold"
														onClick={goBack}
													>
														Cancel
													</button>
												</div>
											)}
										</div>
									</form>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ReportIssuePage;
