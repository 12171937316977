import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import AdminResidentsPage from "../../../components/Admin/Residents/AdminResidentsPage";
import {
	fetchResidents,
	fetchResidentDetails,
	fetchResidentLeaseDetails,
	fetchCourseTypes,
	saveResident,
	deleteDocument,
	setSaveResidentStatus,
	fetchCSVFileLink,
	resetCSVLink,
} from "../../../actions/residents";
import { fetchDefaultPostalAddress } from "../../../actions/auth";

const AdminResidents = ({
	residentsData,
	auth,
	fetchResidents,
	fetchResidentDetails,
	fetchResidentLeaseDetails,
	fetchCourseTypes,
	saveResident,
	deleteDocument,
	setSaveResidentStatus,
	fetchDefaultPostalAddress,
	fetchCSVFileLink,
	resetCSVLink,
	...rest
}) => {
	return (
		<AdminResidentsPage
			residentsData={residentsData}
			auth={auth}
			fetchResidents={fetchResidents}
			fetchResidentDetails={fetchResidentDetails}
			fetchResidentLeaseDetails={fetchResidentLeaseDetails}
			fetchCourseTypes={fetchCourseTypes}
			saveResident={saveResident}
			deleteDocument={deleteDocument}
			setSaveResidentStatus={setSaveResidentStatus}
			fetchDefaultPostalAddress={fetchDefaultPostalAddress}
			fetchCSVFileLink={fetchCSVFileLink}
			resetCSVLink={resetCSVLink}
		/>
	);
};

AdminResidents.propTypes = {
	residentsData: PropTypes.object.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	residentsData: state.residents,
	auth: state.auth,
});

export default connect(mapStateToProps, {
	fetchResidents,
	fetchResidentDetails,
	fetchResidentLeaseDetails,
	fetchCourseTypes,
	saveResident,
	deleteDocument,
	setSaveResidentStatus,
	fetchDefaultPostalAddress,
	fetchCSVFileLink,
	resetCSVLink,
})(AdminResidents);
