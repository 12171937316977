import React, { Fragment } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ReportIssuePage from "./ReportIssuePage";
import IssueHistoryPage from "./IssueHistoryPage";
// import ResidentIssueDetailsPage from "./ResidentIssueDetailsPage";
// import { RESIDENT_POSTS_DEFAULT_PAGE_SIZE } from "../../../constants";
// import PostLoader from "../../Ui/PostLoader";
import "./residentIssues.scss";
import AdminIssueDetailPage from "../../Admin/Issues/AdminIssueDetailPage";

const ResidentIssuesPage = ({
	issuesData,
	residentData,
	authData,
	...rest
}) => {
	return (
		<Fragment>
			<Switch>
				<Route
					path="/resident/issues/report"
					render={(props) => (
						<ReportIssuePage
							{...props}
							authData={authData}
							issuesData={issuesData}
							residentData={residentData}
							saveIssue={rest.saveIssue}
							setSaveIssueStatus={rest.setSaveIssueStatus}
						/>
					)}
				/>
				<Route
					exact
					path="/resident/issues/history"
					render={(props) => (
						<IssueHistoryPage
							{...props}
							authData={authData}
							issuesData={issuesData}
							fetchResidentIssues={rest.fetchResidentIssues}
							setForceUpdate={rest.setForceUpdate}
						/>
					)}
				/>
				<Route
					exact
					path="/resident/issues/edit"
					render={(props) => (
						<ReportIssuePage
							{...props}
							authData={authData}
							issuesData={issuesData}
							residentData={residentData}
							isInEditIssue={true}
							saveIssue={rest.saveIssue}
							setSaveIssueStatus={rest.setSaveIssueStatus}
							deleteResidentIssueImage={
								rest.deleteResidentIssueImage
							}
						/>
					)}
				/>
				<Route
					exact
					path="/resident/issues/:id"
					render={(props) => (
						<AdminIssueDetailPage
							{...props}
							issuesData={issuesData}
							fetchIssueDetails={rest.fetchIssueDetails}
							isUserResident={true}
						/>
					)}
				/>
				<Route
					path="/"
					render={() => <Redirect to="/resident/issues/report" />}
				/>
			</Switch>
		</Fragment>
	);
};

export default ResidentIssuesPage;
