import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import EditPasswordPage from "../../components/Auth/EditPasswordPage";
import {
	editAdminPassword,
	setEditAdminPasswordStatus,
} from "../../actions/auth";

const EditPassword = ({
	auth,
	editAdminPassword,
	setEditAdminPasswordStatus,
}) => (
	<EditPasswordPage
		auth={auth}
		editAdminPassword={editAdminPassword}
		setEditAdminPasswordStatus={setEditAdminPasswordStatus}
	/>
);

EditPassword.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps, {
	editAdminPassword,
	setEditAdminPasswordStatus,
})(EditPassword);
