import {
	REGISTER_SUCCESS,
	// REGISTER_FAIL,
	USER_LOADED,
	// AUTH_FAILED,
	LOGIN_SUCCESS,
	SET_RESET_PASSWORD_STATUS,
	SET_NOTIFICATIONS_COUNT,
	SET_EDIT_ADMIN_PROFILE_STATUS,
	SET_EDIT_ADMIN_PASSWORD_STATUS,
	SET_FETCH_RESIDENT_PROFILE_DETAILS_PENDING,
	SET_FETCH_RESIDENT_PROFILE_DETAILS_SUCCESSFUL,
	SET_SAVE_RESIDENT_PROFILE_DETAILS_STATUS,
	SET_FORCE_UPDATE,
	SET_LOGIN_PATH,
	SET_LOGGING_OUT,
	LOGOUT,
	SET_FETCH_DEFAULT_POSTAL_ADDRESS_PENDING,
	SET_FETCH_DEFAULT_POSTAL_ADDRESS_SUCCESSFUL,
} from "../actions/types";

const initialState = {
	token: "",
	isAuth: null,
	// loading: true,
	user: null,
	userType: "guest",
	isResetPasswordSuccessful: false,
	isEditAdminProfileSuccessful: false,
	isEditAdminPasswordSuccessful: false,
	isFetchResidentProfileDetailsPending: false,
	residentProfileDetails: {},
	total_notifications: 0,
	shouldUpdateForcefully: false,
	loginPath: "",
	isLoggingOut: false,
	defaultPostalAddress: "",
	isFetchDefaultPostalAddressPending: false,
};

export default function(state = initialState,action){
    const{ type, payload } = action;

    switch (type) {
			case USER_LOADED:
				return {
					...state,
					user: payload,
				};
			case REGISTER_SUCCESS:
			case LOGIN_SUCCESS:
				localStorage.setItem("token", payload.user.access_token);
				return {
					...state,
					token: payload.user.access_token,
					isAuth: true,
					// loading: false,
					user: payload.user,
					userType: payload.role,
					total_notifications: payload.total_notifications,
				};
			case SET_LOGIN_PATH:
				return {
					...state,
					loginPath: payload,
				};
			case SET_NOTIFICATIONS_COUNT:
				return {
					...state,
					total_notifications: payload,
				};
			case SET_FORCE_UPDATE:
				return {
					...state,
					shouldUpdateForcefully: payload,
				};
			case SET_RESET_PASSWORD_STATUS:
				return { ...state, isResetPasswordSuccessful: payload };
			// case REGISTER_FAIL:
			// case AUTH_FAILED:
			case SET_EDIT_ADMIN_PROFILE_STATUS:
				return {
					...state,
					isEditAdminProfileSuccessful: payload,
				};
			case SET_EDIT_ADMIN_PASSWORD_STATUS:
				return {
					...state,
					isEditAdminPasswordSuccessful: payload,
				};
			case SET_FETCH_RESIDENT_PROFILE_DETAILS_PENDING:
				return {
					...state,
					isFetchResidentProfileDetailsPending: payload,
				};
			case SET_FETCH_RESIDENT_PROFILE_DETAILS_SUCCESSFUL:
				return {
					...state,
					residentProfileDetails: payload,
				};
			case SET_FETCH_DEFAULT_POSTAL_ADDRESS_PENDING:
				return {
					...state,
					isFetchDefaultPostalAddressPending: payload,
				};
			case SET_FETCH_DEFAULT_POSTAL_ADDRESS_SUCCESSFUL:
				return {
					...state,
					defaultPostalAddress: payload,
				};
			case SET_SAVE_RESIDENT_PROFILE_DETAILS_STATUS:
				return {
					...state,
					isSaveResidentProfileDetailsSuccessful: payload,
				};
			case SET_LOGGING_OUT:
				return {
					...state,
					isLoggingOut: payload,
				};
			case LOGOUT:
				localStorage.removeItem("token");
				localStorage.removeItem("luxpod-state");
				return {
					...state,
					...initialState,
					isLoggingOut: true,
				};
			default:
				return state;
		}
}