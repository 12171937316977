import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import Post from "./Post";
import PostLoader from "../Ui/PostLoader";

const AdminNoticeBoardHomePage = ({
	openAddUpdatePostModal,
	isFetchCurrentMonthPostsPending,
	currentMonthPosts,
	isFetchPreviousPostsPending,
	previousPosts,
	slicedCurrentMonthPosts,
	slicedPreviousPosts,
}) => (
	<Fragment>
		<div className="container-fluid">
			<div className="title-bar d-flex justify-content-between align-items-center">
				<div className="font-45 dblue d-bold">Noticeboard</div>
				<div className="create-post">
					<Link
						className="red-bg white bold font-18 curved-btn"
						onClick={openAddUpdatePostModal}
					>
						<span className="d-bold">+</span>Create post
					</Link>
				</div>
			</div>
		</div>

		<div className="post_lists pt-40">
			<div className="container-fluid">
				<div className="this-months">
					<div className="title-cta d-flex justify-content-between align-items-center">
						<div className="d-bold dblue font-28">
							This Month Posts
						</div>
						{!isFetchCurrentMonthPostsPending &&
							currentMonthPosts.length > 3 && (
								<Link
									to="/admin/noticeboard/current"
									className="bold dblue text-underline"
								>
									See all
								</Link>
							)}
					</div>

					{isFetchCurrentMonthPostsPending ? (
						<PostLoader />
					) : (
						<div className="this-months-list mt-4">
							<div
								className={
									slicedCurrentMonthPosts.length > 0
										? "row"
										: "row justify-content-center"
								}
							>
								{slicedCurrentMonthPosts.length > 0 ? (
									slicedCurrentMonthPosts.map(
										(currentMonthPost) => (
											<Post
												handleClick={
													openAddUpdatePostModal
												}
												post={currentMonthPost}
												parentCSSClass="col-md-4 col-sm-12"
												titleCSSClass="post_title font-15 bold mb-3"
											/>
										)
									)
								) : (
									<p className="align-self-center">
										No posts available.
									</p>
								)}
							</div>
						</div>
					)}
				</div>
			</div>

			<div className="container-fluid pt-40">
				<div className="prev-months">
					<div className="title-cta d-flex justify-content-between align-items-center">
						<div className="d-bold dblue font-28">
							Previous Posts
						</div>
						{!isFetchPreviousPostsPending &&
							previousPosts.length > 9 && (
								<Link
									to="/admin/noticeboard/previous"
									className="bold dblue text-underline"
								>
									See all
								</Link>
							)}
					</div>

					{isFetchPreviousPostsPending ? (
						<PostLoader />
					) : (
						<div className="prev-months-list mt-4">
							<div
								className={
									slicedPreviousPosts.length > 0
										? "row"
										: "row justify-content-center"
								}
							>
								{slicedPreviousPosts.length > 0 ? (
									slicedPreviousPosts.map((previousPost) => (
										<Post
											handleClick={openAddUpdatePostModal}
											post={previousPost}
											parentCSSClass="col-md-4 col-sm-12"
											titleCSSClass="post_title font-15 bold mb-3"
										/>
									))
								) : (
									<p className="center">
										No posts available.
									</p>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	</Fragment>
);

export default AdminNoticeBoardHomePage;