import React, { useEffect, useState } from 'react';
import { Route, Switch } from "react-router-dom";

import { ADMIN_POSTS_MONTHS } from "../../constants";
import AdminNoticeboardSeeAllPage from "./AdminNoticeboardSeeAllPage";
import AddUpdatePostModal from './AddUpdatePostModal';
import DeletePostModal from './DeletePostModal';
import AdminNoticeBoardHomePage from './AdminNoticeboardHomePage';
import "./adminNoticeboard.scss";

const AdminNoticeboardPage = ({
	noticeboard: {
		currentMonthPosts,
		previousPosts,
		isFetchCurrentMonthPostsPending,
		isFetchPreviousPostsPending,
	},
	...rest
}) => {

	const [slicedCurrentMonthPosts, setSlicedCurrentMonthPosts] = useState([]);
	const [slicedPreviousPosts, setSlicedPreviousPosts] = useState([]);

	useEffect(() => {
		rest.fetchAllPosts();
	}, []);

	useEffect(() => {
		if (currentMonthPosts.length > 3) {
			let tempArray = [...currentMonthPosts];
			setSlicedCurrentMonthPosts(tempArray.splice(0, 3));
		} else {
			setSlicedCurrentMonthPosts(currentMonthPosts);
		}
	}, [currentMonthPosts]);

	useEffect(() => {
		if (previousPosts.length > 9) {
			let tempArray = Object.assign([], previousPosts);
			setSlicedPreviousPosts(tempArray.splice(0, 9));
		} else {
			setSlicedPreviousPosts(previousPosts);
		}
	}, [previousPosts]);

	const closeModal = () => {
		rest.unmountModal();
	};

	const savePost = (path, post) => {
		let fromDashboard = false;
		rest.savePost(path, post, fromDashboard);
		rest.unmountModal();
		// rest.fetchAllPosts();
	};

	const openAddUpdatePostModal = (e, post) => {
		e.preventDefault();
		rest.mountModal(
			<AddUpdatePostModal
				post={post}
				closeModal={closeModal}
				savePost={savePost}
				deletePostImage={rest.deletePostImage}
				openDeletePostModal={openDeletePostModal}
				showErrorMessage={rest.showErrorMessage}
			/>
		);
	};

	const deletePost = (id) => {
		let fromDashboard = false;
		rest.deletePost(id, fromDashboard);
		rest.unmountModal();
		// rest.fetchAllPosts();
	};

	const openDeletePostModal = (post) => {
		rest.unmountModal();
		rest.mountModal(
			<DeletePostModal
				post={post}
				deletePost={deletePost}
				closeModal={closeModal}
			/>
		);
	};

	return (
		<Switch>
			<Route
				path="/admin/noticeboard/current"
				render={(props) => (
					<AdminNoticeboardSeeAllPage
						{...props}
						postsMonth={ADMIN_POSTS_MONTHS.current}
						currentMonthPosts={currentMonthPosts}
						openAddUpdatePostModal={openAddUpdatePostModal}
					/>
				)}
			/>
			<Route
				path="/admin/noticeboard/previous"
				render={(props) => (
					<AdminNoticeboardSeeAllPage
						{...props}
						postsMonth={ADMIN_POSTS_MONTHS.previous}
						previousPosts={previousPosts}
						openAddUpdatePostModal={openAddUpdatePostModal}
					/>
				)}
			/>
			<Route
				path="/"
				render={(props) => (
					<AdminNoticeBoardHomePage
						openAddUpdatePostModal={openAddUpdatePostModal}
						isFetchCurrentMonthPostsPending={isFetchCurrentMonthPostsPending}
						isFetchPreviousPostsPending={isFetchPreviousPostsPending}
						currentMonthPosts={currentMonthPosts}
						previousPosts={previousPosts}
						slicedCurrentMonthPosts={slicedCurrentMonthPosts}
						slicedPreviousPosts={slicedPreviousPosts}
					/>
				)}
			/>
			<Route render={() => <h2>Page not found :(</h2>} />
		</Switch>
	);
};

export default AdminNoticeboardPage;