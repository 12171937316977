import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ResidentIssuesPage from "../../../components/Resident/Issues/ResidentIssuesPage";
import {
	saveIssue,
	setSaveIssueStatus,
	fetchResidentIssues,
	fetchIssueDetails,
	deleteResidentIssueImage,
} from "../../../actions/issues";
import { setForceUpdate } from "../../../actions/auth";

const ResidentIssues = ({
	authData,
	issuesData,
	saveIssue,
	setSaveIssueStatus,
	fetchResidentIssues,
	fetchIssueDetails,
	deleteResidentIssueImage,
	setForceUpdate,
}) => (
	<ResidentIssuesPage
		authData={authData}
		issuesData={issuesData}
		residentData={authData.user}
		saveIssue={saveIssue}
		setSaveIssueStatus={setSaveIssueStatus}
		fetchResidentIssues={fetchResidentIssues}
		fetchIssueDetails={fetchIssueDetails}
		deleteResidentIssueImage={deleteResidentIssueImage}
		setForceUpdate={setForceUpdate}
	/>
);

ResidentIssues.propTypes = {
	authData: PropTypes.object.isRequired,
	issuesData: PropTypes.object.isRequired,
	saveIssue: PropTypes.func.isRequired,
	setSaveIssueStatus: PropTypes.func.isRequired,
	fetchResidentIssues: PropTypes.func.isRequired,
	fetchIssueDetails: PropTypes.func.isRequired,
	deleteResidentIssueImage: PropTypes.func.isRequired,
	setForceUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	issuesData: state.issues,
	authData: state.auth
});

export default connect(mapStateToProps, {
	saveIssue,
	setSaveIssueStatus,
	fetchResidentIssues,
	fetchIssueDetails,
	deleteResidentIssueImage,
	setForceUpdate,
})(ResidentIssues);
