import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import ValidationMessage from "../Ui/ValidationMessage";
import { EMAIL_PATTERN } from "../../constants";
import squiggly from "../../assets/images/squiggly.svg";
import man from "../../assets/images/man.svg";
import girl from "../../assets/images/girl.svg";
import logo from "../../assets/images/logo.svg";
import back from "../../assets/images/back.svg";

const ForgotPasswordPage = ({ forgotPassword, ...rest }) => {
	// useEffect(() => {
	// 	document.body.classList.add("login");
		
	// 	// return () => {
	// 	// 	document.body.classList.remove("login");
	// 	// };
	// }, []);

	const [formData, setFormData] = useState({
		email: "",
		formErrors: {
			email: "",
		},
	});
	const { email, formErrors } = formData;
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	const handleInputChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	useEffect(() => {
		if (isFormSubmitted) {
			isFormValid();
		}
	}, [formData.email]);

    const isFormValid = () => {
        // TODO: Refactor to extract custom hook for forms.
		let updatedFormErrors = formErrors;
        let isFormValid = true;
        const field = "email";
		// Object.keys(formErrors).forEach((field) => {
		// 	switch (field) {
		// 		case "email":
		if (!email) {
			updatedFormErrors[field] = "Please enter your email address";
			isFormValid = false;
		} else if (email.length > 100) {
			updatedFormErrors[field] = "Please enter a valid email address";
			isFormValid = false;
		} else if (!email.match(EMAIL_PATTERN)) {
			updatedFormErrors[field] = "Please enter a valid email address";
			isFormValid = false;
		} else {
			updatedFormErrors[field] = "";
		}
		// 			break;
		// 		default:
		// 			break;
		// 	}
		// });
		if (isFormValid) {
			updatedFormErrors = {
				email: "",
			};
		}
		setFormData({ ...formData, formErrors: updatedFormErrors });
		return isFormValid;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setIsFormSubmitted(true);
		if (isFormValid()) {
			forgotPassword(email);
		}
    };
    
    return (
		<Fragment>
			<div className="wrapper h-100">
				<div className="absolute-images">
					<img className="squiggly" src={squiggly} alt="squiggly" />
					<img className="man" src={man} alt="man" />
					<img className="girl" src={girl} alt="girl" />
				</div>
				<div className="big-container h-100">
					<div className="row h-100">
						<div className="col-md-8 h-100">
							<div className="login__container d-flex align-items-center h-100 white position-relative">
								<Link to="/login" className="backto">
									<img src={back} alt="back" />
								</Link>
								<div className="left-login-image">
									<img width="100%" src={logo} alt="logo" />
								</div>
								<div className="right-login-container">
									<div className="font-50 d-bold">
										Forgot your password?
									</div>
									<div className="">
										Enter your email address to send you a
										reset link
									</div>
									<div className="login-form mt-4">
										<form onSubmit={handleSubmit}>
											<div className="fields">
												<input
													type="text"
													name="email"
													placeholder="Your email address"
													onChange={handleInputChange}
												/>
												{isFormSubmitted &&
													formErrors.email && (
														<ValidationMessage id="emailHelp">
															{formErrors.email}
														</ValidationMessage>
													)}
											</div>
											<div className="fields">
												<input
													type="submit"
													value="Send"
												/>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ForgotPasswordPage;
