import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import FacebookLoader from "../Ui/FacebookLoader";
import ValidationMessage from "../Ui/ValidationMessage";
import { EMAIL_PATTERN } from "../../constants";
import squiggly from "../../assets/images/squiggly.svg";
import man from "../../assets/images/man.svg";
import girl from "../../assets/images/girl.svg";
import logo from "../../assets/images/logo.svg";

const LoginPage = ({ login, ...rest }) => {
	const [formData, setFormData] = useState({
		email: "",
		password: "",
		formErrors: {
			email: "",
			password: ""
		}
	});
	// const [isLoading, setLoading] = useState(false);
	const { email, password, formErrors } = formData;

	

	// useEffect(() => {
	// 	document.body.classList.add("login");

	// 	return () => {
	// 		document.body.classList.remove("login");
	// 	};
	// },[]);
	

	const handleInputChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	}
	
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	useEffect(() => {
		if (isFormSubmitted) {
			isFormValid();
		}
	}, [formData.email, formData.password]);

	const isFormValid = () => {
		let updatedFormErrors = formErrors;
		let isFormValid = true;
		Object.keys(formErrors).forEach((field) => {
			switch (field) {
				case "email":
					if (!email) {
						updatedFormErrors[field] =
							"Please enter your email address";
						isFormValid = false;
					}
					else if (email.length > 100) {
						updatedFormErrors[field] =
							"Please enter a valid email address";
						isFormValid = false;
					}
					else if (!email.match(EMAIL_PATTERN)) {
						updatedFormErrors[field] =
							"Please enter a valid email address";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "password":
					if (!password) {
						updatedFormErrors[field] = "Please enter your password";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				default:
					break;
			}
		});
		if (isFormValid) {
			updatedFormErrors = {
				email: "",
				password: "",
			};
		}
		setFormData({ ...formData, formErrors: updatedFormErrors });
		return isFormValid;
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		setIsFormSubmitted(true);
		if (isFormValid()) {
			login({ email, password });
		}
	};

	// if (isAuth) {
	// 	switch (userType) {
	// 		case USER_TYPE_ADMIN:
	// 			return <Redirect to="/admin/dashboard" />;
	// 		case USER_TYPE_BUILDING_MANAGER:
	// 			return <Redirect to="/building-manager/dashboard" />;
	// 		case USER_TYPE_RESIDENT:
	// 			return <Redirect to="/tenant/dashboard" />;
	// 		default:
	// 			return <Redirect to="/" />;
	// 	}
	// }

	return (
		<Fragment>
			<div className="wrapper h-100">
				<div className="absolute-images">
					<img className="squiggly" src={squiggly} alt="squiggly" />
					<img className="man" src={man} alt="man" />
					<img className="girl" src={girl} alt="girl" />
				</div>
				<div className="big-container h-100">
					<div className="row h-100">
						<div className="col-md-8 h-100">
							<div className="login__container d-flex align-items-center h-100 white">
								<div className="left-login-image">
									<img width="100%" src={logo} alt="logo" />
								</div>
								<div className="right-login-container">
									<div className="font-50 d-bold text-center">
										Welcome back
									</div>
									<div className="login-form mt-4">
										<form onSubmit={handleSubmit}>
											<div className="fields">
												<input
													type="text"
													name="email"
													placeholder="Your email address"
													onChange={handleInputChange}
												/>
												{isFormSubmitted &&
													formErrors.email && (
														<ValidationMessage id="emailHelp">
															{formErrors.email}
														</ValidationMessage>
													)}
											</div>
											<div className="fields">
												<input
													type="password"
													name="password"
													placeholder="Your password"
													onChange={handleInputChange}
												/>
												{isFormSubmitted &&
													formErrors.password && (
														<ValidationMessage id="passwordHelp">
															{formErrors.password}
														</ValidationMessage>
													)}
											</div>
											<Link
												to="/forgot-password"
												className="text-center d-block mt-4 mb-4"
											>
												Forgot your password
											</Link>
											<div className="fields">
												<input
													type="submit"
													value="Login"
												/>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default LoginPage;
