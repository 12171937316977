import React from "react";
import PropTypes from "prop-types";

const ValidationMessage = (props) => (
	<div id={props.id} className="text-danger validation-message">
		{props.children}
	</div>
);

export default ValidationMessage;

ValidationMessage.propTypes = {
	children: PropTypes.oneOfType([PropTypes.element,PropTypes.string]).isRequired,
	id: PropTypes.string
};