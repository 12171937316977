import React, { Fragment, useState, useEffect } from "react";
import {
	Link,
	useHistory
} from "react-router-dom";

// TODO: Bring Edit Password styles from adminProfile.scss to login.scss
import "../Admin/Profile/adminProfile.scss";
import { PASSWORD_PATTERN, USER_TYPE_RESIDENT } from "../../constants";
import ValidationMessage from "../Ui/ValidationMessage";
import leftBlueArrow from "../../assets/images/left-blue-arrow.svg";
import LuxpodSuccessMessage from "../Ui/LuxpodSuccessMessage";

const EditPasswordPage = ({
	auth: { isEditAdminPasswordSuccessful, userType },
	editAdminPassword,
	setEditAdminPasswordStatus,
}) => {
	const initialFormData = {
		password: "",
		confirmPassword: "",
	};
	const [formData, setFormData] = useState(initialFormData);

	const initialFormErrors = {
		password: "",
		confirmPassword: "",
	};
	const [formErrors, setFormErrors] = useState(initialFormErrors);

	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	const history = useHistory();
	const goBack = (e) => {
		if (e) {
			e.preventDefault();
		}
		// history.push("/admin/profile");
		window.history.go(-1);
	};
	
	const goToDashboard = () => {
		setEditAdminPasswordStatus(false);
		history.push("/admin/dashboard");
	};
	const goBackToProfileDetails = () => {
		setEditAdminPasswordStatus(false);
		history.push("/resident/profile");
	};
	// useEffect(() => {
	// 	if (isEditAdminPasswordSuccessful) {
	// 		setEditAdminPasswordStatus(false);
	// 		goBack();
	// 	}
	// }, [isEditAdminPasswordSuccessful]);
	useEffect(() => {
		return () => {
			setEditAdminPasswordStatus(false);
		};
	},[]);

	const handleInputChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	useEffect(() => {
		if (isFormSubmitted) {
			isFormValid();
		}
	}, [formData]);

	const isFormValid = () => {
		let updatedFormErrors = formErrors;
		let isFormValid = true;
		Object.keys(formErrors).forEach((field) => {
			switch (field) {
				case "password":
					if (!formData.password) {
						updatedFormErrors[field] = "Please enter a password";
						isFormValid = false;
					} else if (formData.password.length < 8) {
						updatedFormErrors[field] =
							"Password should have a minimum of 8 characters";
						isFormValid = false;
					} else if (formData.password.length > 20) {
						updatedFormErrors[field] =
							"Password should have a maximum of 20 characters";
						isFormValid = false;
					} else if (!formData.password.match(PASSWORD_PATTERN)) {
						updatedFormErrors[field] =
							"Password should have at least one lower case alphabet, one upper case alphabet, one number, and one special character";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "confirmPassword":
					if (!formData.confirmPassword) {
						updatedFormErrors[field] =
							"Please enter confirm password";
						isFormValid = false;
					} else if (formData.password !== formData.confirmPassword) {
						updatedFormErrors[field] =
							"Password and Confirm Password must be same";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				default:
					break;
			}
		});
		if (isFormValid) {
			updatedFormErrors = initialFormErrors;
		}
		setFormErrors({ ...formErrors, ...updatedFormErrors });
		return isFormValid;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setIsFormSubmitted(true);
		if (isFormValid()) {
			editAdminPassword(formData.password);
		}
	};

	return (
		<Fragment>
			<div className="container-fluid">
				<div className="title-bar d-flex justify-content-between align-items-center">
					<div className="font-45 dblue d-bold">
						{!isEditAdminPasswordSuccessful && (
							<Link onClick={goBack}>
								<img src={leftBlueArrow} alt="go back" />
							</Link>
						)}
						<span>Edit Password</span>
					</div>
				</div>
			</div>
			{isEditAdminPasswordSuccessful ? (
				<LuxpodSuccessMessage
					message="Password updated successfully!"
					goBackToDefaultScreen={
						userType === USER_TYPE_RESIDENT
							? goBackToProfileDetails
							: goToDashboard
					}
					returnTo={
						userType === USER_TYPE_RESIDENT
							? "My Profile"
							: "Dashboard"
					}
				/>
			) : (
				<div className="edit-password">
					<form onSubmit={handleSubmit}>
						<div className="col-md-4 col-sm-12">
							<label className="font-18">
								Create new password
							</label>
							<div className="lp-fields">
								<input
									type="password"
									name="password"
									value={formData.password}
									onChange={handleInputChange}
								/>
								{isFormSubmitted && formErrors.password && (
									<ValidationMessage id="passwordHelp">
										{formErrors.password}
									</ValidationMessage>
								)}
							</div>
						</div>
						<div className="col-md-4 col-sm-12">
							<label className="font-18">
								Confirm new password
							</label>
							<div className="lp-fields">
								<input
									type="password"
									name="confirmPassword"
									value={formData.confirmPassword}
									onChange={handleInputChange}
								/>
								{isFormSubmitted &&
									formErrors.confirmPassword && (
										<ValidationMessage id="confirmPasswordHelp">
											{formErrors.confirmPassword}
										</ValidationMessage>
									)}
							</div>
						</div>
						<div className="col-md-12 d-flex pt-120">
							<button
								type="submit"
								className="curved-btn red-bg white bold"
							>
								Save
							</button>
							<button
								type="button"
								className="curved-btn cancel red-border red bold"
								onClick={goBack}
							>
								Cancel
							</button>
						</div>
					</form>
				</div>
			)}
		</Fragment>
	);
};

export default EditPasswordPage;