import React, { Fragment, useState, useEffect } from "react";

import {
	ALLOWED_IMAGE_FILE_EXTENSIONS,
	MAX_ALLOWED_POST_IMAGE_SIZE,
} from "../../constants";
import ValidationMessage from "../Ui/ValidationMessage";
import popupMan from "../../assets/images/popup-man.svg";
import popupClose from "../../assets/images/popup-close.svg";
import addMedia from "../../assets/images/add-media.svg";

const AddUpdatePostModal = ({
	post,
	closeModal,
	savePost,
	deletePostImage,
	openDeletePostModal,
	showErrorMessage,
	...rest
}) => {
	// const isUpdate = post && Object.keys(post).length > 0 ? true : false;
	const [isUpdate, setIsUpdate] = useState(post && post.id ? true : false);

	const initialFormData = {
		postTitle: post && post.title ? post.title : "",
		postDetails: (post && post.details) || "",
		formErrors: {
			postTitle: "",
			postDetails: "",
		},
	};
	const [formData, setFormData] = useState(initialFormData);
	const { postTitle, postDetails, formErrors } = formData;

	const initialFileUploadData = {
		file: null,
		fileExtension: "",
		fileDataBase64String: "",
		fileURL: (post && post.images && post.images.length > 0) ? post.images[0].image : "",
		fileId: (post && post.images && post.images.length > 0) ? post.images[0].id : null,
	};
	const [fileUploadData, setFileUploadData] = useState(
		initialFileUploadData
	);
	const {
		fileExtension,
		fileDataBase64String,
		fileURL,
	} = fileUploadData;

	const [isPostUpdated, setIsPostUpdated] = useState(false);

	const isObjectIdentical = (object1, object2) => {
		return JSON.stringify(object1) === JSON.stringify(object2);
	};

	const checkIfPostUpdated = () => {
		if (
			!isObjectIdentical(formData, initialFormData) ||
			!isObjectIdentical(fileUploadData, initialFileUploadData)
		) {
			setIsPostUpdated(true);
		} else {
			setIsPostUpdated(false);
		}
	};
	// useEffect(() => {
	// 	console.log("isPostUpdated", isPostUpdated);
	// }, [isPostUpdated]);

	useEffect(() => {
		if (isUpdate) {
			checkIfPostUpdated();
		}
	}, [formData, fileUploadData]);

	const handleInputChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const generateBase64 = (file) => {
		let fileReader = new FileReader();
		fileReader.readAsDataURL(file);
		fileReader.onload = function () {
			// setFileUploadData({
			// 	...fileUploadData,
			// 	fileDataBase64String: fileReader.result,
			// });
			setFileUploadData((fileUploadData) => ({
				...fileUploadData,
				fileDataBase64String: fileReader.result,
			}));
		};
		fileReader.onerror = function (error) {
			console.log("Error: ", error);
			showErrorMessage("File seems corrupted. Please try again with another file.");
			onCloseImagePreview();
		};
	};

	const handleFileInputChange = (e) => {
		const extension = e.target.files[0].name.split(".").pop();
		if (ALLOWED_IMAGE_FILE_EXTENSIONS.includes(extension)) {
			if (e.target.files[0].size > MAX_ALLOWED_POST_IMAGE_SIZE) {
				showErrorMessage("Maximum file size allowed is 12 MB.");
				onCloseImagePreview();
			} else {
				generateBase64(e.target.files[0]);
				setFileUploadData({
					...fileUploadData,
					file: e.target.files[0],
					fileExtension: extension,
				});
			}
		} else {
			showErrorMessage("Please upload a valid file format.");
			onCloseImagePreview();
		}
	};

	const onCloseImagePreview = () => {
		setFileUploadData({
			...initialFileUploadData,
			fileURL: "",
		});
	};

	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	useEffect(() => {
		if (isFormSubmitted) {
			isFormValid();
		}
	}, [formData.postTitle, formData.postDetails]);

	const isFormValid = () => {
		let updatedFormErrors = formErrors;
		let isFormValid = true;
		Object.keys(formErrors).forEach((field) => {
			switch (field) {
				case "postTitle":
					if (!postTitle) {
						updatedFormErrors[field] = "Please enter post title";
						isFormValid = false;
					} else if (postTitle.length > 100) {
						updatedFormErrors[field] =
							"Post title shoule have a maximum of 100 characters";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				case "postDetails":
					if (!postDetails) {
						updatedFormErrors[field] = "Please enter post details";
						isFormValid = false;
					} else if (postDetails.length > 1000) {
						updatedFormErrors[field] =
							"Post title shoule have a maximum of 1000 characters";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
					break;
				default:
					break;
			}
		});
		if (isFormValid) {
			updatedFormErrors = {
				postTitle: "",
				postDetails: "",
			};
		}
		setFormData({ ...formData, formErrors: updatedFormErrors });
		return isFormValid;
	};

	const getFileData = () => {
		return fileDataBase64String.split(";base64,").pop();
	}

	const createPostData = () => {
		let postData = {
			title: postTitle,
			details: postDetails,
		};
		if (isUpdate) {
			postData.id = post.id;
		}
		if (fileDataBase64String) {
			postData.images = [
				{
					file_extension: fileExtension,
					file_data: getFileData(),
				},
			];
		}
		return postData;
	};

	const handleSubmit = (e, path) => {
		e.preventDefault();
		setIsFormSubmitted(true);
		if (isFormValid()) {
			savePost(path, createPostData());
			if (initialFileUploadData.fileURL && !fileURL) {
				deletePostImage(initialFileUploadData.fileId);
			}
		}
	};

	return (
		<Fragment>
			<div className="popup-man position-absolute">
				<img src={popupMan} alt="popupMan" />
			</div>
			<span
				className="modal-close"
				data-dismiss="modal"
				onClick={closeModal}
			>
				<img src={popupClose} alt="popupClose" />
			</span>
			<div className="modal-body">
				{!isUpdate && <div className="font-45 dblue d-bold mb-4">Add New Post</div>}
				<div className="add-post-form">
					<form
						onSubmit={(e) =>
							handleSubmit(
								e,
								isUpdate ? "/editpost" : "/createpost"
							)
						}
					>
						<div className="noticeboard fields">
							<input
								type="text"
								name="postTitle"
								placeholder="Post title"
								value={postTitle}
								onChange={handleInputChange}
							/>
							{isFormSubmitted && formErrors.postTitle && (
								<ValidationMessage id="postTitleHelp">
									{formErrors.postTitle}
								</ValidationMessage>
							)}
						</div>
						<div className="noticeboard fields">
							<textarea
								name="postDetails"
								placeholder="Post details"
								value={postDetails}
								onChange={handleInputChange}
							></textarea>
							{isFormSubmitted && formErrors.postDetails && (
								<ValidationMessage id="postDetailsHelp">
									{formErrors.postDetails}
								</ValidationMessage>
							)}
						</div>
						<div className="noticeboard fields position-relative">
							{(fileURL || fileDataBase64String) && (
								<img
									id="ImgPreview"
									src={fileURL || fileDataBase64String}
									alt="ImgPreview"
									className="preview1"
								/>
							)}
							{(fileURL || fileDataBase64String) && (
								<input
									type="button"
									id="removeImage1"
									value="x"
									className="btn-rmv1"
									onClick={onCloseImagePreview}
								/>
							)}
							{!(fileURL || fileDataBase64String) && (
								<span className="btn_upload">
									<input
										type="file"
										id="imag"
										title=""
										className="input-img"
										accept="image/*"
										onChange={handleFileInputChange}
									/>
									<img src={addMedia} alt="addMedia" />
									Add media
								</span>
							)}
						</div>
						{isUpdate ? (
							<div className="noticeboard fields d-flex">
								<input
									className="save-changes"
									type="submit"
									value="Save changes"
									disabled={!isPostUpdated}
								/>
								<input
									type="button"
									className="delete-post"
									value="Delete"
									onClick={() => openDeletePostModal(post)}
								/>
							</div>
						) : (
							<div className="noticeboard fields">
								<input
									className="save-changes"
									type="submit"
									value="Create post"
								/>
							</div>
						)}
					</form>
				</div>
			</div>
		</Fragment>
	);
};

export default AddUpdatePostModal;
