import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AdminLayout from "../../containers/Layout/Admin/AdminLayout";
import { USER_TYPE_ADMIN, USER_TYPE_BUILDING_MANAGER } from "../../constants";
import { setLoginPath, logout } from "../../actions/auth";

const AdminPrivateRoute = ({
	component: Component,
	setLoginPath,
	logout,
	auth: { token, userType, loginPath, isLoggingOut },
	...rest
}) => {
	const isValidUser =
		userType === USER_TYPE_ADMIN || userType === USER_TYPE_BUILDING_MANAGER;

	if (!token && !isValidUser) {
		// console.log(isLoggingOut);
		if (isLoggingOut) {
			// console.log("setting empty");
			setLoginPath("");
		} else {
			// console.log("setting path");
			setLoginPath(rest.location.pathname ? rest.location.pathname : "");
		}
		return <Redirect to="/login" />;
	} else if (token && !isValidUser) {
		logout();
		return <Redirect to="/login" />;
	} else {
		// console.log("is valid");
		if (loginPath) {
			setLoginPath("");
		}
	}

	return (
		<Route
			{...rest}
			render={
				(props) => (
					// token && isValidUser ? (
					<div>
						{/* {console.log(userType)} */}

						<AdminLayout {...props}>
							<Component {...props} />
						</AdminLayout>
					</div>
				)
				// ) : (
				// 	<Redirect to="/login" />
				// )
			}
		/>
	);
};

AdminPrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { setLoginPath, logout })(
	AdminPrivateRoute
);
