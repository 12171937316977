import React from "react";
import { connect } from "react-redux";
// import PropTypes from "prop-types";
import ResidentRentPage from "../../../components/Resident/Rent/ResidentRentPage";
// import { login } from "../../actions/auth";

const ResidentRent = () => <ResidentRentPage />;

ResidentRent.propTypes = {
	// login: PropTypes.func.isRequired,
	// isAuth: PropTypes.bool,
	// userType: PropTypes.string,
};

const mapStateToProps = (state) => ({
	// isAuth: state.auth.isAuth,
});

// export default connect(mapStateToProps, { login })(ResidentRent);
export default connect(mapStateToProps)(ResidentRent);
