import { SHOW_LOADER, HIDE_LOADER } from "../actions/types";

const initialState = {
    showLoader: false,
};

export default function loaderReducer(state = initialState, action) {
    const { type } = action;

    switch (type) {
        case SHOW_LOADER:
            return Object.assign({}, state, {
                showLoader: true,
            });
        case HIDE_LOADER:
            return Object.assign({}, state, {
                showLoader: false,
            });
        default:
            return state;
    }
}
