import React from "react";
import { Link } from "react-router-dom";

import successIcon from "../../assets/images/issue-reported.svg";

export const LuxpodSuccessMessage = ({ message, goBackToDefaultScreen, returnTo }) => {

    return (
		<div class="col-md-10">
			<div class="luxpod-success-message">
				<div class="reported-image">
					<img src={successIcon} alt="green_bg_tick" />
				</div>
				<div class="regular font-36 blue">
					{message}
					<br />
					{goBackToDefaultScreen && (
						<div>
							<Link
								onClick={goBackToDefaultScreen}
								className="text-underline"
							>
								Return
							</Link>{" "}
							to {returnTo} page.
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default LuxpodSuccessMessage;
