import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ValidationMessage from "../Ui/ValidationMessage";
import { PASSWORD_PATTERN } from "../../constants";
import squiggly from "../../assets/images/squiggly.svg";
import logo from "../../assets/images/logo.svg";

const ResetPasswordPage = ({ resetPassword, isResetPasswordSuccessful, setResetPasswordStatus, rest }) => {
    const [resetToken, setResetToken] = useState("");

    useEffect(() => {
		let queryParamsString = rest.location.search;
		let splitStrings = queryParamsString.split("?token=");
        setResetToken(splitStrings[1]);
        
        setResetPasswordStatus(false);
    }, []);

    const history = useHistory();
    useEffect(() => {
        if (isResetPasswordSuccessful) {
			setResetPasswordStatus(false);
			history.push("login");
		}
    }, [isResetPasswordSuccessful]);
    
	const [formData, setFormData] = useState({
		password: "",
		confirmPassword: "",
		formErrors: {
			password: "",
			confirmPassword: ""
		},
	});
	const { password, confirmPassword, formErrors } = formData;
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	const handleInputChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	useEffect(() => {
		if (isFormSubmitted) {
			isFormValid();
		}
	}, [formData.password, formData.confirmPassword]);

	const isFormValid = () => {
		// TODO: Refactor to extract custom hook for forms.
		let updatedFormErrors = formErrors;
		let isFormValid = true;
		Object.keys(formErrors).forEach((field) => {
			switch (field) {
				case "password":
                    if (!password) {
                        updatedFormErrors[field] = "Please enter a password";
                        isFormValid = false;
                    } else if (password.length < 8) {
                        updatedFormErrors[field] =
							"Password should have a minimum of 8 characters";
                        isFormValid = false;
                    } else if (password.length > 20) {
                        updatedFormErrors[field] =
                            "Password should have a maximum of 20 characters";
                        isFormValid = false;
                    } else if (!password.match(PASSWORD_PATTERN)) {
                        updatedFormErrors[field] =
                            "Password should have at least one lower case alphabet, one upper case alphabet, one number, and one special character";
                        isFormValid = false;
                    } else {
                        updatedFormErrors[field] = "";
                    }
                    break;
                case "confirmPassword":
                    if (!confirmPassword) {
						updatedFormErrors[field] = "Please enter confirm password";
						isFormValid = false;
					} else if (password !== confirmPassword) {
						updatedFormErrors[field] =
							"Password and Confirm Password must be same";
						isFormValid = false;
					} else {
						updatedFormErrors[field] = "";
					}
                    break;
				default:
					break;
			}
		});
		if (isFormValid) {
			updatedFormErrors = {
                password: "",
                confirmPassword: ""
			};
		}
		setFormData({ ...formData, formErrors: updatedFormErrors });
		return isFormValid;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setIsFormSubmitted(true);
		if (isFormValid()) {
			resetPassword(password, resetToken);
		}
	};

	return (
		<Fragment>
			<div className="wrapper h-100 forgot-pw">
				<div className="absolute-images">
					<img className="squiggly" src={squiggly} alt="squiggly" />
				</div>
				<div className="big-container h-100">
					<div className="top-logo">
						<img width="100%" src={logo} alt="logo" />
					</div>
					<div className="row h-100">
						<div className="forgot__container d-flex align-items-center white">
							<div className="bottom-form pt-120 pr-5">
								<div className="font-50 d-bold">
									Create a new password
								</div>
								<div className="new-password mt-4">
									<form onSubmit={handleSubmit}>
										<div className="fields">
											<input
												type="password"
												name="password"
												placeholder="New password"
												onChange={handleInputChange}
											/>
											{isFormSubmitted &&
												formErrors.password && (
													<ValidationMessage id="passwordHelp">
														{formErrors.password}
													</ValidationMessage>
												)}
										</div>
										<div className="fields">
											<input
												type="password"
												name="confirmPassword"
												placeholder="Confirm new password"
												onChange={handleInputChange}
											/>
											{isFormSubmitted &&
												formErrors.confirmPassword && (
													<ValidationMessage id="confirmPasswordHelp">
														{formErrors.confirmPassword}
													</ValidationMessage>
												)}
										</div>
										<div className="fields">
											<input
												type="submit"
												value="Save password"
											/>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ResetPasswordPage;
