import React, { Fragment } from "react";

import Sidebar from "../Sidebar";
import Header from "../Header";

const AdminLayout = ({ children, ...rest }) => (
	<Fragment>
		<div className="wrapper">
			<Header />
			<Sidebar rest={rest} />
			<div className="main-content">{children}</div>
		</div>
	</Fragment>
);

export default AdminLayout;
