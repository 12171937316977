import React, { Fragment } from "react";
import Interweave from "interweave";

import popupClose from "../../../assets/images/popup-close.svg";

const ViewPost = ({ post, closePopup }) => {
	return (
		<Fragment>
			<div id="postpop">
				<button
					type="button"
					class="close"
					data-dismiss="modal"
					onClick={closePopup}
				>
					<img src={popupClose} alt="close_icon" />
				</button>
				<div class="modal-body">
					<div class="font-45 dblue d-bold mb-1">{post.title}</div>
					<p>{post.time_ago}</p>
					<div class="post-content d-flex mt-5">
						{post.images && post.images[0] && (
							<div class="post-content-image">
								<div class="postcontentimage">
									<img
										width="100%"
										src={post.images[0].image}
										alt="post_image"
									/>
								</div>
							</div>
						)}
						<div
							class={`post-content-content ${
								(post.images &&
								post.images[0] &&
								post.images[0].image)
									? "post-content-content-half-width"
									: ""
							} light`}
						>
							<Interweave content={post.details} />
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ViewPost;