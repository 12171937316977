import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ResidentNoticeboardPage from "../../../components/Resident/Noticeboard/ResidentNoticeboardPage";
import { fetchResidentPosts } from "../../../actions/noticeboard";
import { mountModal, unmountModal } from "../../../actions/modal";

const ResidentNoticeboard = ({
	noticboardData,
	fetchResidentPosts,
	mountModal,
	unmountModal,
}) => (
	<ResidentNoticeboardPage
		noticboardData={noticboardData}
		fetchResidentPosts={fetchResidentPosts}
		mountModal={mountModal}
		unmountModal={unmountModal}
	/>
);

ResidentNoticeboard.propTypes = {
	fetchResidentPosts: PropTypes.func.isRequired,
	noticboardData: PropTypes.object,
};

const mapStateToProps = (state) => ({
	noticboardData: state.noticeboard,
});

export default connect(mapStateToProps, {
	fetchResidentPosts,
	mountModal,
	unmountModal,
})(ResidentNoticeboard);
