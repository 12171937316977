import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ResetPasswordPage from "../../components/Auth/ResetPasswordPage";
import { resetPassword, setResetPasswordStatus } from "../../actions/auth";

const ResetPassword = ({
	resetPassword,
	isResetPasswordSuccessful,
	setResetPasswordStatus,
	...rest
}) => (
	<ResetPasswordPage
		resetPassword={resetPassword}
		isResetPasswordSuccessful={isResetPasswordSuccessful}
		setResetPasswordStatus={setResetPasswordStatus}
		rest={rest}
	/>
);

ResetPassword.propTypes = {
	resetPassword: PropTypes.func.isRequired,
	// isAuth: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	isResetPasswordSuccessful: state.auth.isResetPasswordSuccessful,
});

export default connect(mapStateToProps, {
	resetPassword,
	setResetPasswordStatus
})(ResetPassword);
