import { MOUNT_MODAL, UNMOUNT_MODAL } from "./types";

export function mountModal(children) {
	return {
		type: MOUNT_MODAL,
		children: children,
	};
}
export function unmountModal() {
	return {
		type: UNMOUNT_MODAL,
	};
}
