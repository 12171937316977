import React from 'react';

import Sidebar from "../Sidebar";
import Header from "../Header";

const ResidentLayout = ({ children, ...rest }) => {
  return (
		<div className="wrapper">
			<Header />
			<Sidebar rest={rest} />
			<div className="main-content">{children}</div>
		</div>
  );
}

export default ResidentLayout;