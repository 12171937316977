import React from "react";
import ContentLoader from "react-content-loader";

const RowLoader = (props) => (
	<div className="row">
		<SingleRowLoader />
		<SingleRowLoader />
		<SingleRowLoader />
	</div>
);

export default RowLoader;

export const SingleRowLoader = (props) => (
	<ContentLoader
		speed={2}
		width={1000}
		height={160}
		viewBox="0 0 1000 160"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
		{...props}
	>
		<rect x="120" y="24" rx="3" ry="3" width="850" height="14" />
		<rect x="120" y="56" rx="3" ry="3" width="850" height="24" />
		<rect x="5" y="5" rx="10" ry="10" width="100" height="100" />
	</ContentLoader>
);