import React, { useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import FacebookLoader from '../../Ui/FacebookLoader';
import leftBlueArrow from "../../../assets/images/left-blue-arrow.svg";
import man from "../../../assets/images/tenant-man.svg";
import girl from "../../../assets/images/tenant-girl.svg";

const AdminResidentLeaseDetailsPage = ({
	residentsData: {
		selectedResidentLeaseDetails,
		isFetchResidentLeaseDetailsPending,
	},
	isAdmin,
	...rest
}) => {
	const { id } = useParams();

	const history = useHistory();
	if (!isAdmin) {
		history.push('/admin/residents/'+id);
	}
	
	useEffect(() => {
        rest.fetchResidentLeaseDetails(id);
    }, []);

	return (
		<div class="container-fluid h-100">
			<div class="title-bar d-flex justify-content-between align-items-center">
				<div class="font-45 dblue d-bold">
					<Link to={`/admin/residents/${id}`}>
						<img src={leftBlueArrow} alt="go_back" />
					</Link>
					Lease Details
				</div>
			</div>

			{isFetchResidentLeaseDetailsPending ? (
				<FacebookLoader />
			) : (
				<div class="pt-40 lease-details-page new-tenant-form position-relative">
					<div class="absolute-images">
						<img class="man" src={man} alt="artwork_man" />
						<img class="girl" src={girl} alt="artwork_girl" />
					</div>

					<div class="row">
						<div class="col-md-9">
							{/* <div class="row">
								<div class="col-md-4 col-sm-12">
									<div class="font-18 tenantlease">
										<div>Preferred start date</div>
										<div class="bold">
											{
												selectedResidentLeaseDetails.preferedstartdate
											}
										</div>
									</div>
								</div>
								<div class="col-md-4 col-sm-12">
									<div class="font-18 tenantlease">
										<div>Preferred end date</div>
										<div class="bold">
											{
												selectedResidentLeaseDetails.preferedenddate
											}
										</div>
									</div>
								</div>
							</div> */}

							<div class="row">
								<div class="col-md-4 col-sm-12">
									<div class="font-18 tenantlease">
										<div>Start date</div>
										<div class="bold">
											{
												selectedResidentLeaseDetails.startdate
											}
										</div>
									</div>
								</div>
								<div class="col-md-4 col-sm-12">
									<div class="font-18 tenantlease">
										<div>End date</div>
										<div class="bold">
											{
												selectedResidentLeaseDetails.enddate
											}
										</div>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-md-4 col-sm-12">
									<div class="font-18 tenantlease">
										<div>Deposit</div>
										<div class="bold">
											{
												selectedResidentLeaseDetails.deposit
											}
										</div>
									</div>
								</div>
								<div class="col-md-4 col-sm-12">
									<div class="font-18 tenantlease">
										<div>Bond</div>
										<div class="bold">
											{selectedResidentLeaseDetails.bond}
										</div>
									</div>
								</div>
								<div class="col-md-4 col-sm-12">
									<div class="font-18 tenantlease">
										<div>Fortnightly rent amount</div>
										<div class="bold">
											{
												selectedResidentLeaseDetails.fortnightlyrent
											}
										</div>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-md-4 col-sm-12">
									<div class="font-18 tenantlease">
										<div>Room level </div>
										<div class="bold">
											{
												selectedResidentLeaseDetails.room_level
											}
										</div>
									</div>
								</div>
								<div class="col-md-4 col-sm-12">
									<div class="font-18 tenantlease">
										<div>Room Number </div>
										<div class="bold">
											{selectedResidentLeaseDetails.room}
										</div>
									</div>
								</div>
								<div class="col-md-4 col-sm-12">
									<div class="font-18 tenantlease">
										<div>Number of residents per room</div>
										<div class="bold">
											{
												selectedResidentLeaseDetails.room_resident_number
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default AdminResidentLeaseDetailsPage;