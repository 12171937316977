import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ResidentDashboardPage from "../../../components/Resident/Dashboard/ResidentDashboardPage";
import {
	fetchLeaseDetails,
	fetchWifiDetails,
	fetchBuildingManagerDetails,
	fetchLuxpodBankDetails,
} from "../../../actions/dashboard";
import { fetchResidentPosts } from "../../../actions/noticeboard";
import { fetchResidentIssues } from "../../../actions/issues";
import { mountModal, unmountModal } from "../../../actions/modal";
import { setForceUpdate } from "../../../actions/auth";

const ResidentDashboard = ({
	authData,
	dashboardData,
	noticeboardData,
	issuesData,
	fetchLeaseDetails,
	fetchWifiDetails,
	fetchBuildingManagerDetails,
	fetchLuxpodBankDetails,
	fetchResidentPosts,
	fetchResidentIssues,
	mountModal,
	unmountModal,
	setForceUpdate,
}) => (
	<ResidentDashboardPage
		authData={authData}
		dashboardData={dashboardData}
		noticeboardData={noticeboardData}
		issuesData={issuesData}
		fetchLeaseDetails={fetchLeaseDetails}
		fetchWifiDetails={fetchWifiDetails}
		fetchBuildingManagerDetails={fetchBuildingManagerDetails}
		fetchLuxpodBankDetails={fetchLuxpodBankDetails}
		fetchResidentPosts={fetchResidentPosts}
		fetchResidentIssues={fetchResidentIssues}
		mountModal={mountModal}
		unmountModal={unmountModal}
		setForceUpdate={setForceUpdate}
	/>
);

ResidentDashboard.propTypes = {
	authData: PropTypes.object.isRequired,
	dashboardData: PropTypes.object.isRequired,
	fetchLeaseDetails: PropTypes.func.isRequired,
	fetchWifiDetails: PropTypes.func.isRequired,
	fetchBuildingManagerDetails: PropTypes.func.isRequired,
	fetchLuxpodBankDetails: PropTypes.func.isRequired,
	fetchResidentPosts: PropTypes.func.isRequired,
	fetchResidentIssues: PropTypes.func.isRequired,
	mountModal: PropTypes.func.isRequired,
	unmountModal: PropTypes.func.isRequired,
	setForceUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	authData: state.auth,
	dashboardData: state.dashboard,
	noticeboardData: state.noticeboard,
	issuesData: state.issues
});

export default connect(mapStateToProps, {
	fetchLeaseDetails,
	fetchWifiDetails,
	fetchBuildingManagerDetails,
	fetchResidentPosts,
	fetchResidentIssues,
	mountModal,
	unmountModal,
	setForceUpdate,
	fetchLuxpodBankDetails,
})(ResidentDashboard);
